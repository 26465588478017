import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Label, Form } from "reactstrap";
import { Field, reduxForm, SubmissionError } from "redux-form";
import html2plaintext from "html2plaintext";
import {
  Btn,
  Check,
  Input,
  InputDate,
  InputDateRange,
  InputTime,
} from "../../../components";
import ErrorStore from "../../../utils/ErrorStore";
import {
  EVENTS_DURATIONS_OPTIONS,
  // NOTIFICATION_OPTIONS,
} from "../../../utils/service";
import { required } from "../../../utils/Validator";
import moment from "moment";

let EventForm = ({
  navigation,
  eventDuration,
  setEventDuration,
  ActionName,
  details,
  handleSubmit,
  onSubmit,
}) => {
  const [eventName, setEventName] = useState(
    details ? details.data.title.en : ""
  );
  const [eventDescription, setEventDescription] = useState(
    details?.data.description ? details.data.description.en : ""
  );
  const [eventStartDate, setEventStartDate] = useState(
    details ? details.data.startDate : moment(new Date()).format("MM/DD/YYYY")
  );
  const [eventEndDate, setEventEndDate] = useState(
    details ? details.data.endDate : new Date()
  );

  const [startTime, setStartTime] = useState(
    details ? details.data.startDate : ""
  );
  const [eventEndTime, setEventEndTime] = useState(
    details ? details.data.endDate : ""
  );
  const descriptionPlainText = html2plaintext(eventDescription);

  const [error, setError] = useState(null);
  function _validate(details) {
    const errorInstance = ErrorStore();
    errorInstance.add("eventName", eventName, [required]);
    return errorInstance.get();
  }

  function _handleSubmit(details) {
    let errors = null;
    let bannerFormError = _validate(details);
    if (bannerFormError) {
      errors = { ...(errors || {}), ...bannerFormError };
    }
    if (errors) {
      throw new SubmissionError({ ...errors });
    } else {
      postData();
    }
  }

  const formatData = () => {
    if ((eventStartDate) || (eventStartDate && eventEndTime)) {
      let formatedsrtDate = moment(eventStartDate).format("YYYY-MM-DD");
      let formatedEndDate = moment(eventEndDate).format("YYYY-MM-DD");
      let getSrtTime = startTime ? new Date(startTime).toTimeString().split(" ")[0] : null;
      let getEndTime = eventEndTime ? new Date(eventEndTime).toTimeString().split(" ")[0] : null;
      return { formatedsrtDate, formatedEndDate, getSrtTime, getEndTime };
    }
  };

  const postData = () => {
    const { formatedsrtDate, formatedEndDate, getSrtTime, getEndTime } =
      formatData();
    if (
      (formatedsrtDate) ||
      (formatedsrtDate  && formatedEndDate)
    ) {
      if(getSrtTime === null) {
        setError("Start time is required")
      }
      else if(getEndTime === null) {
        setError("End time is required")
      }
      else if ((getSrtTime !=="" && getEndTime !== "") && (getSrtTime > getEndTime || getSrtTime === getEndTime)) {
        setError("End time should be greater than start time");
      } else {
        const _details = {
          title: eventName,
          startDate:
            eventDuration === "Single Day"
              ? formatedsrtDate + " " + getSrtTime
              : formatedsrtDate + " " + getSrtTime,
          endDate:
            eventDuration === "Single Day"
              ? formatedsrtDate + " " + getEndTime
              : formatedEndDate + " " + getEndTime,
          // description: descriptionPlainText ? descriptionPlainText : null,
        };
        if (descriptionPlainText) {
          _details.description = descriptionPlainText;
        }
        onSubmit(_details);
        setError(null);
      }
    }
  };

  // const handleSelected = (data) => {
  //   data.some((elems) => {
  //     if (elems.value === eventDuration) {
  //       return (elems.isSelected = true);
  //     } else {
  //       return (elems.isSelected = false);
  //     }
  //   });
  // };

  const [isDisable, setIsDisable] = useState(false);

  // console.log(isDisable);

  useEffect(() => {
    if (
      eventName ||
      eventDescription ||
      eventStartDate ||
      eventEndDate ||
      startTime ||
      eventEndTime
    ) {
      setIsDisable(false);
    }
  }, [
    eventDescription,
    eventEndDate,
    eventEndTime,
    eventName,
    eventStartDate,
    startTime,
  ]);

  const clickDisable = () => {
    setTimeout(() => {
      setIsDisable(true);
    }, 10);
  };

  return (
    <>
      <div className="screen-view-row">
        <Row>
          <Col lg={6} sm={12} className="d-flex">
            <div className="screen-title-wrap d-flex align-self-center">
              <div className="text-wrap">
                <p className="subtitle1"> {ActionName} </p>
              </div>
            </div>
          </Col>
          <Col lg={6} sm={12}>
            <div className="btn-wrap d-flex justify-content-end">
              <Btn
                variant="secondary"
                size={"lg"}
                label="Go Back"
                onClick={() => navigation("/events/list", "")}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="screen-view-row">
        <Form
          name="eventForm"
          id="event-form"
          className="form-wrap"
          onSubmit={handleSubmit(_handleSubmit)}
        >
          <Row>
            <Col lg={6} sm={12}>
              {/* <Label className="label-name" for="banner_name">
                Event Name
                <span className="error-msg"> * </span>
              </Label> */}
              <Row>
                <Col lg="8" md="9" sm="9">
                  <Label className="label-name" for="banner_name">
                    Event Title
                    <span className="error-msg"> * </span>
                  </Label>
                </Col>
                <Col lg="4" md="3" sm="3">
                  <p className="title">
                    {100 - eventName.length} out of 100 characters left
                  </p>
                </Col>
              </Row>
              <Field
                name="eventName"
                component={Input}
                defaultValue={eventName}
                type="text"
                maxLength={100}
                id="event_name"
                placeholder="Enter Event Name"
                showLength={true}
                onchange={(e) => setEventName(e.target.value)}
              />

              <Field
                name="eventDescription"
                component={Input}
                defaultValue={descriptionPlainText}
                id="event_description"
                type="text"
                label="Events Description (Optional)"
                placeholder="Events Description"
                as="textarea"
                rows={8}
                maxLength={500}
                showLength={true}
                onchange={(e) => setEventDescription(e.target.value)}
              />
            </Col>

            <Col lg={6} sm={12}>
              <Check
                label="Event Duration"
                id="events_durations"
                data={EVENTS_DURATIONS_OPTIONS}
                defaultValue={eventDuration}
                type="radio"
                inline={true}
                onChange={(val) => {
                  //console.log(val, "--- checkValue");
                  setEventDuration(val);
                }}
              />
              {eventDuration === "Single Day" ? (
                <InputDate
                  label="Event Date"
                  id="events_date"
                  placeholder="Select Date"
                  dateFormat="MMM/DD/YYYY"
                  disableDay={(date) => date < new Date()}
                  includeTime={false}
                  initialDate={eventStartDate}
                  onChange={(date) => setEventStartDate(date.$d)}
                  // disableDay={(date) => date.toDate() < new Date()}
                />
              ) : (
                <InputDateRange
                  label="Event Date Range"
                  id="events_range_date"
                  placeholder="Select Date"
                  dateFormat="MMM/DD/YYYY"
                  disableDay={(date) => date < new Date()}
                  includeTime={false}
                  initialStartDate={eventStartDate}
                  initialEndDate={eventEndDate}
                  onChange={(start, end) => {
                    setEventStartDate(start);
                    setEventEndDate(end);
                  }}
                  // disableDay={(date) => date.toDate() < new Date()}
                />
              )}
              <Row>
                <Col lg={6} sm={12}>
                  <InputTime
                    label="Start Time"
                    id="events_start_time"
                    placeholder="Start Time"
                    initialDate={startTime}
                    onChange={
                      (startTime) => setStartTime(startTime.$d)
                      //  handleStartTime(startTime)
                    }
                  />
                </Col>
                <Col lg={6} sm={12}>
                  <InputTime
                    label="End Time"
                    id="events_end_time"
                    placeholder="End Time"
                    initialDate={eventEndTime}
                    onChange={(endTime) => setEventEndTime(endTime.$d)}
                  />
                </Col>
                <div className="text-danger">{error}</div>
              </Row>
            </Col>
            <Col lg={0} sm={0}>
              {/* <Check
                label="Event Options"
                id="events_options"
                data={NOTIFICATION_OPTIONS}
                // defaultValue={"All"}
                type="radio"
                onChange={(val) => {
                  console.log(val, "--- checkValue");
                }}
              /> */}
              {/* {showRestInputFeatures && (
                <>
                  <UploadCoverPicture
                    label="Cover Photo (Optional)"
                    src={""}
                    caption="Accepted Formats: PNG, JPG. Max 10 mb"
                    id="upload_cover_pic"
                    onChange={(val) => {
                      console.log(val, "--- upload value");
                    }}
                  />
                  <InputFile
                    label="Attachment (Optional)"
                    placeholder="File Name"
                    id="attachment"
                    caption="Accepted formats: .pdf"
                    accept="application/pdf"
                    isAvtar={false}
                    onChange={(data) => {
                      console.log(data.target.value, "--- input");
                    }}
                  />

                  <Row>
                    <Col sm={12}>
                      <Form.Group
                        className={`form-group mb-0 d-flex justify-content-between`}
                      >
                        <Form.Label>Address</Form.Label>
                        <div className="btn-wrap">
                          <Btn
                            variant="link"
                            label="View / Locate on Google Maps"
                            style={"text-capitalize"}
                            onClick={() => {
                              setModalLocateMap(true);
                            }}
                          />
                        </div>
                      </Form.Group>
                      <Input
                        placeholder="Address"
                        type="input"
                        defaultValue={""}
                        onChange={(data) => {
                          console.log(data.target.value, "--- input");
                        }}
                      />
                      <Input
                        defaultValue="San Manteo"
                        type="input"
                        disabled={true}
                      />
                      <Input
                        placeholder="Add optional details like room number, floor, landmark"
                        type="input"
                        onChange={(data) => {
                          console.log(data.target.value, "--- input");
                        }}
                      />
                      <Input
                        defaultValue="California"
                        type="input"
                        disabled={true}
                      />
                      <Row>
                        <Col lg={6} sm={12}>
                          <Input
                            placeholder="Sanmanteo Country"
                            type="input"
                            disabled={true}
                          />
                        </Col>
                        <Col lg={6} sm={12}>
                          <Input
                            placeholder="Zip Code"
                            type="input"
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )} */}
            </Col>
          </Row>
          <div className="screen-view-row">
            <Row>
              <Col lg={12} sm={12}>
                <div className="btn-wrap d-flex justify-content-end">
                  <Btn
                    variant="secondary"
                    size={"lg"}
                    label="Cancel"
                    onClick={() => {
                      navigation("/events/list", "");
                    }}
                  />
                  <Btn
                    variant="primary"
                    size={"lg"}
                    onClick={clickDisable}
                    label="Submit"
                    type="submit"
                    disabled={isDisable ? true : false}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </>
  );
};

EventForm = reduxForm({
  form: "eventForm",
  enableReinitialize: true,
})(EventForm);

export default EventForm;
