const APP_FEATURE = [
  {
    icon: "icon-slider",
    title: "Home",
    link: "/home",
    nav: "home",
    isActive: true,
  },
  {
    icon: "icon-slider",
    title: "Banners",
    link: "/banners/list",
    nav: "banners",
    isActive: true,
  },
  {
    icon: "icon-Student-1",
    title: "Services",
    link: "/services/list",
    nav: "services",
    isActive: true,
  },
  {
    icon: "icon-Event",
    title: "Events",
    link: "/events/list",
    nav: "events",
    isActive: true,
  },
  {
    icon: "icon-push_notification",
    title: "Push Notifications",
    link: "/push-notification/list",
    nav: "push-notification",
    isActive: true,
  },
];

const NOTIFICATION_OPTIONS = [
  {
    label: "All",
    value: "All",
    disabled: false,
  },
  {
    label: "Homelessness",
    value: "Homelessness",
    disabled: false,
  },
  {
    label: "Financial Assistance",
    value: "Financial Assistance",
    disabled: false,
  },
  {
    label: "Partners",
    value: "Partners",
    disabled: false,
  },
];

const SORTBY_OPTIONS = [
  {
    label: "A-Z",
    value: "A-Z",
    disabled: false,
    isSelected: false,
  },
  {
    label: "Z-A",
    value: "Z-A",
    disabled: false,
    isSelected: false,
  },
  {
    label: "Oldest",
    value: "Oldest",
    disabled: false,
    isSelected: false,
  },
  {
    label: "Newest",
    value: "Newest",
    disabled: false,
    isSelected: false,
  },
];

const QUESTION_TYPE = [
  {
    label: "Select Type",
    value: "",
    disabled: false,
    isSelected: false,
  },
  {
    label: "Radio Button",
    value: "radio",
    disabled: false,
    isSelected: false,
  },
  {
    label: "Checkbox",
    value: "checkbox",
    disabled: false,
    isSelected: false,
  },
  {
    label: "Input",
    value: "input",
    disabled: false,
    isSelected: false,
  },
  {
    label: "Multiline Input Box",
    value: "textarea",
    disabled: false,
    isSelected: false,
  },
];

const MANDATORY_TYPE = [
  {
    label: "Yes",
    value: "yes",
    disabled: false,
    isSelected: false,
  },
  {
    label: "No",
    value: "no",
    disabled: false,
    isSelected: false,
  },
];

const UPLOAD_TYPE = [
  {
    label: "Upload This And Show This In Mobile App",
    value: "Upload This And Show This In Mobile App",
    disabled: false,
    isSelected: true,
  },
  {
    label: "Upload This, But Do Not Show This In Mobile App",
    value: "Upload This, But Do Not Show This In Mobile App",
    disabled: false,
    isSelected: false,
  },
];

const CATEGORY_DETAILS_OPTIONS = [
  {
    label: "Web Link",
    value: "Web Link",
    disabled: false,
    isSelected: false,
  },
  {
    label: "Attachment",
    value: "Attachment",
    disabled: false,
    isSelected: false,
  },
];

const EVENTS_DURATIONS_OPTIONS = [
  {
    label: "Single Day",
    value: "Single Day",
    disabled: false,
    isSelected: true,
  },
  {
    label: "Multiple Day",
    value: "Multiple Day",
    disabled: false,
    isSelected: false,
  },
];

const PROGRAM_LIST = [
  {
    icon: "icon-HomeLess-Icon",
    title: "Homelessness",
    count: 5,
  },
  {
    icon: "icon-Finanacial-Icon",
    title: "Financial Assistance",
    count: 6,
  },
  {
    icon: "icon-Emergency-Icon",
    title: "Emergency Services",
    count: 5,
  },
  {
    icon: "icon-Community-Icon",
    title: "Community Resources",
    count: 1,
  },
  {
    icon: "icon-Partners-Icon",
    title: "Partners",
    count: 4,
  },
];

const HOMELESSNESS_CATEGORY = [
  {
    type: "Shelters",
    title: "Homelessness",
    description: "lorem ipsum",
    icon: "icon-Shelter-Icon",
    assign: "Items",
  },
  {
    type: "Outreach Locations",
    title: "Homelessness",
    description: "lorem ipsum",
    icon: "icon-Location-Icon",
    assign: "Items",
  },
  {
    type: "Severe Weather Outreach",
    title: "Homelessness",
    description: "lorem ipsum",
    icon: "icon-Weather-Icon",
    assign: "Items",
  },
  {
    type: "Food Pantries",
    title: "Homelessness",
    description: "lorem ipsum",
    icon: "icon-food",
    assign: "Items",
  },
  {
    type: "Mobile Pantries",
    title: "Homelessness",
    description:
      "Li Europan lingues es membres del sam familie. Lor separat existentie es un myth. Por scientie, musica, sport etc, litot Europa usa li sam vocabular. Li lingues differe solmen in li grammatica, li pronunciation e li plu commun vocabules. Omnicos directe al desirabilite de un nov lingua franca: On refusa continuar payar custosi traductores.",
    icon: "icon-MobilePantries_Icon",
    assign: "Contents",
  },
];

const FINANCIAL_ASSISTANCE_CATEGORY = [
  {
    type: "Rental",
    title: "Financial Assistance",
    description: "lorem ipsum",
    icon: "icon-Rental-Icon",
    assign: "Items",
  },
  {
    type: "Home Repair Assistance",
    title: "Financial Assistance",
    description: "lorem ipsum",
    icon: "icon-HomeRepair-Icon",
    assign: "Items",
  },
  {
    type: "Bills",
    title: "Financial Assistance",
    description: "lorem ipsum",
    icon: "icon-finance",
    assign: "Items",
  },
  {
    type: "Down Payment Assistance",
    title: "Financial Assistance",
    description: "lorem ipsum",
    icon: "icon-DownPayment-Icon",
    assign: "Items",
  },
  {
    type: "Veteran Services",
    title: "Financial Assistance",
    description: "lorem ipsum",
    icon: "icon-wheelchair-alt",
    assign: "Items",
  },
  {
    type: "Cremation Services",
    title: "Financial Assistance",
    description: "lorem ipsum",
    icon: "icon-Cremation-Icon",
    assign: "Items",
  },
];

const EMERGENCY_SERVICES_CATEGORY = [
  {
    type: "Ambulance",
    title: "Emergency Services",
    description: "lorem ipsum",
    icon: "icon-Ambulance-Icon",
    assign: "Items",
  },
  {
    type: "Sheriff / Police",
    title: "Emergency Services",
    description: "lorem ipsum",
    icon: "icon-Police-Icon",
    assign: "Items",
  },
  {
    type: "Fire Department",
    title: "Emergency Services",
    description: "lorem ipsum",
    icon: "icon-FireDep-Icon",
    assign: "Items",
  },
  {
    type: "Public Safety",
    title: "Emergency Services",
    description: "lorem ipsum",
    icon: "icon-PublicSafety-Icon",
    assign: "Items",
  },
  {
    type: "Suicide Prevention",
    title: "Emergency Services",
    description: "lorem ipsum",
    icon: "icon-pills",
    assign: "Items",
  },
];

const COMMUNITY_RESOURCES_CATEGORY = [
  {
    type: "Food Resources",
    title: "Community Resources",
    description: "lorem ipsum",
    icon: "icon-FoodResources-Icon",
    assign: "Items",
  },
];

const PARTNERS_CATEGORY = [
  {
    type: "Funding Opportunities",
    title: "Partners",
    description: "lorem ipsum",
    icon: "icon-Funding-Icon",
    assign: "Items",
  },
  {
    type: "Meetings",
    title: "Partners",
    description: "lorem ipsum",
    icon: "icon-Meeting-Icon",
    assign: "Items",
  },
  {
    type: "Training",
    title: "Partners",
    description: "lorem ipsum",
    icon: "icon-Training-Icon",
    assign: "Items",
  },
  {
    type: "Learn More",
    title: "Partners",
    description: "lorem ipsum",
    icon: "icon-Reading-Icon",
    assign: "Items",
  },
];

const SERVICE_GUIDELINE_LIST = [
  {
    icon: "icon-home",
    title: "Housing",
    count: 5,
  },
  {
    icon: "icon-hand-holding-heart",
    title: "Health",
    count: 5,
  },
  {
    icon: "icon-brain",
    title: "Mental Health",
    count: 0,
  },
  {
    icon: "icon-mortar-board",
    title: "Education",
    count: 5,
  },
  {
    icon: "icon-suitcase",
    title: "Employment",
    count: 5,
  },
  {
    icon: "icon-bus",
    title: "Transportation",
    count: 5,
  },
  {
    icon: "icon-finance",
    title: "Finances",
    count: 5,
  },
  {
    icon: "icon-food",
    title: "Food & Nutrition",
    count: 5,
  },
  {
    icon: "icon-file-medical",
    title: "Benefits",
    count: 5,
  },
  {
    icon: "icon-mother-nurse",
    title: "Parenting",
    count: 5,
  },
  {
    icon: "icon-intersex",
    title: "LGBTQ",
    count: 5,
  },
  {
    icon: "icon-handshake",
    title: "Mentoring",
    count: 5,
  },
  {
    icon: "icon-file-document",
    title: "Documents",
    count: 5,
  },
  {
    icon: "icon-legal",
    title: "Legal",
    count: 5,
  },
  {
    icon: "icon-hands",
    title: "Advocacy",
    count: 5,
  },
];

const SERVICE_GUIDELINE_CATEGORY = [
  {
    title: "binod panda",
    type: "All",
    description: "lorem ipsum",
    urlText: "",
    url: "",
    urlType: "",
  },
  {
    title: "Lorem Ipsum is simply dummy text oof Lorem Ipsum i ",
    type: "All",
    description:
      "Li Europan lingues es membres del sam familie. Lor separat existentie es un myth. Por scientie, musica, sport etc, litot Europa usa li sam vocabular. Li lingues differe solmen in li grammatica, li pronunciation e li plu commun vocabules. Omnicos directe al desirabilite de un nov lingua franca: On refusa continuar payar custosi traductores.",
    urlText: "https://sanmateo.com/",
    url: "https://sanmateo.com/",
    urlType: "Link",
  },
  {
    title: "Test 123456",
    type: "All",
    description: "lorem ipsum",
    urlText: "",
    url: "",
  },
  {
    title: "Category Demo 123 ",
    type: "All",
    description: "lorem ipsum",
    urlText: "",
    url: "",
  },
  {
    title: "Testing null description ",
    type: "Resources Families",
    description: "lorem ipsum",
    urlText:
      "Dummy copy xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx (1).pdf ",
    url: "http://sanmateo-test.infojiniconsulting.com/api/v1/files/e8b022ea-9f39-47b4-9d3d-9abfcfa63b12?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3Nfa2V5IjoiX2tlZGo0NWFtZiIsInZlbmRvcl9pZCI6MSwiaWF0IjoxNjQ0NDgxNzIzLCJleHAiOjE2NDUwODY1MjMsImF1ZCI6InItdGVjaCIsImlzcyI6InItdGVjaCJ9.I-Oe0PoIEsEMHRUuiBwDuXLlliLnxzf9d8xKCFpgLnE",
    urlType: "Attachment",
  },
  {
    title: "Lorem Ipsum is simply dummy text oof Lorem Ipsum i ",
    type: "All",
    description:
      "Li Europan lingues es membres del sam familie. Lor separat existentie es un myth. Por scientie, musica, sport etc, litot Europa usa li sam vocabular. Li lingues differe solmen in li grammatica, li pronunciation e li plu commun vocabules. Omnicos directe al desirabilite de un nov lingua franca: On refusa continuar payar custosi traductores.",
    urlText: "Google Analytics for Firebase - Migration Guide.pdf",
    url: "http://sanmateo-test.infojiniconsulting.com/api/v1/files/c4af00ee-2f3b-4150-bdef-5f9f6afbb1cc?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3Nfa2V5IjoiX2tlZGo0NWFtZiIsInZlbmRvcl9pZCI6MSwiaWF0IjoxNjQ0NDgxNzIzLCJleHAiOjE2NDUwODY1MjMsImF1ZCI6InItdGVjaCIsImlzcyI6InItdGVjaCJ9.I-Oe0PoIEsEMHRUuiBwDuXLlliLnxzf9d8xKCFpgLnE",
    urlType: "Attachment",
  },
];

const PUSHNOTIFICATION_FILTER_OPTIONS = [
  {
    type: "checkbox",
    title: "Status",
    defaultValue: "",
    data: [
      {
        label: "Sent",
        value: "Sent",
        disabled: false,
        isSelected: true,
      },
      {
        label: "Not Sent",
        value: "Not Sent",
        disabled: false,
        isSelected: true,
      },
    ],
  },
  {
    type: "date-range",
    title: "Duration",
    defaultValue: "",
    data: [],
  },
  {
    type: "radio",
    title: "Survey For",
    defaultValue: "",
    data: [
      {
        label: "All",
        value: "All",
        disabled: false,
        isSelected: false,
      },
      {
        label: "Homelessness",
        value: "Homelessness",
        disabled: false,
        isSelected: true,
      },
      {
        label: "Financial Assistance",
        value: "Financial Assistance",
        disabled: false,
        isSelected: false,
      },
      {
        label: "Partners",
        value: "Partners",
        disabled: false,
        isSelected: false,
      },
    ],
  },
];

const SURVEY_FILTER_OPTIONS = [
  {
    type: "radio",
    title: "Survey For",
    defaultValue: "",
    data: [
      {
        label: "All",
        value: "All",
        disabled: false,
      },
      {
        label: "Foster Youth",
        value: "Foster Youth",
        disabled: false,
      },
      {
        label: "Resource Families",
        value: "Resource Families",
        disabled: false,
      },
    ],
  },
];

const SURVEY_LIST = [
  {
    title: "App Fedback",
    code: "123",
    createdOn: "Mar 17, 2020, 06:14 PM",
    type: "All",
    audience: "6",
    shareUrl:
      "http://sanmateo-test.infojiniconsulting.com/api/v1/surveys/9/export?JWT=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3Nfa2V5IjoiX2tlZGo0NWFtZiIsInZlbmRvcl9pZCI6MSwiaWF0IjoxNjQ0NjUyMzQwLCJleHAiOjE2NDUyNTcxNDAsImF1ZCI6InItdGVjaCIsImlzcyI6InItdGVjaCJ9.7MEnthIMKnIUtLWrP9ty-VoAxcC5_Kd4ycHITz-xolo",
    description:
      "Li Europan lingues es membres del sam familie. Lor separat existentie es un myth. Por scientie, musica, sport etc, litot Europa usa li sam vocabular. Li lingues differe solmen in li grammatica, li pronunciation e li plu commun vocabules. Omnicos directe al desirabilite de un nov lingua franca: On refusa continuar payar custosi traductores.",
    message: "",
    questions: [
      {
        title: "Age",
        type: "radio",
        isMandatory: "Yes",
        options: [
          {
            label: "0-18",
            value: "0-18",
            disabled: false,
          },
          {
            label: "19-59",
            value: "19-59",
            disabled: false,
          },
          {
            label: "60 Above",
            value: "60 Above",
            disabled: false,
          },
        ],
      },
      {
        title: "Location",
        type: "checkbox",
        isMandatory: "No",
        options: [
          {
            label: "Baltimore, MD",
            value: "Baltimore, MD",
            disabled: false,
          },
          {
            label: "New York, NY",
            value: "New York, NY",
            disabled: false,
          },
          {
            label: "Annapolis, MD",
            value: "Annapolis, MD",
            disabled: false,
          },
        ],
      },
      {
        title: "Location",
        type: "checkbox",
        isMandatory: "No",
        options: [
          {
            label: "Baltimore, MD",
            value: "Baltimore, MD",
            disabled: false,
          },
          {
            label: "New York, NY",
            value: "New York, NY",
            disabled: false,
          },
          {
            label: "Annapolis, MD",
            value: "Annapolis, MD",
            disabled: false,
          },
        ],
      },
    ],
  },
  {
    title: "Application Feedback",
    code: "111",
    createdOn: "Mar 04, 2020, 03:06 PM",
    type: "All",
    audience: "10",
    shareUrl:
      "http://sanmateo-test.infojiniconsulting.com/api/v1/surveys/9/export?JWT=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3Nfa2V5IjoiX2tlZGo0NWFtZiIsInZlbmRvcl9pZCI6MSwiaWF0IjoxNjQ0NjUyMzQwLCJleHAiOjE2NDUyNTcxNDAsImF1ZCI6InItdGVjaCIsImlzcyI6InItdGVjaCJ9.7MEnthIMKnIUtLWrP9ty-VoAxcC5_Kd4ycHITz-xolo",
    description:
      "Li Europan lingues es membres del sam familie. Lor separat existentie es un myth. Por scientie, musica, sport etc, litot Europa usa li sam vocabular. Li lingues differe solmen in li grammatica, li pronunciation e li plu commun vocabules. Omnicos directe al desirabilite de un nov lingua franca: On refusa continuar payar custosi traductores.",
    message: "",
    questions: [
      {
        title: "Comments",
        type: "MultilineTextbox",
        isMandatory: "Yes",
        options: [],
      },
      {
        title: "Email",
        type: "InputBox",
        isMandatory: "No",
        options: [],
      },
    ],
  },
  {
    title:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the",
    code: "1",
    createdOn: "Mar 02, 2020, 07:59 PM",
    type: "All",
    audience: "2",
    shareUrl:
      "http://sanmateo-test.infojiniconsulting.com/api/v1/surveys/9/export?JWT=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3Nfa2V5IjoiX2tlZGo0NWFtZiIsInZlbmRvcl9pZCI6MSwiaWF0IjoxNjQ0NjUyMzQwLCJleHAiOjE2NDUyNTcxNDAsImF1ZCI6InItdGVjaCIsImlzcyI6InItdGVjaCJ9.7MEnthIMKnIUtLWrP9ty-VoAxcC5_Kd4ycHITz-xolo",
    description: "",
    message: "",
    questions: [],
  },
];

const NEWSLETTER_LIST = [
  {
    title: "Volunteer & Community Involvement",
    type: "All",
    enabled: true,
    archive: false,
    url: "http://sanmateo-test.infojiniconsulting.com/api/v1/surveys/9/export?JWT=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3Nfa2V5IjoiX2tlZGo0NWFtZiIsInZlbmRvcl9pZCI6MSwiaWF0IjoxNjQ0NjUyMzQwLCJleHAiOjE2NDUyNTcxNDAsImF1ZCI6InItdGVjaCIsImlzcyI6InItdGVjaCJ9.7MEnthIMKnIUtLWrP9ty-VoAxcC5_Kd4ycHITz-xolo",
    urlText:
      "Dummy copy xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx (1).pdf",
    uploadType: "Upload This And Show This In Mobile App",
  },
  {
    title: "Lorem Ipsum is simply dummy te",
    type: "Resource Families",
    enabled: true,
    archive: true,
    url: "http://sanmateo-test.infojiniconsulting.com/api/v1/surveys/9/export?JWT=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3Nfa2V5IjoiX2tlZGo0NWFtZiIsInZlbmRvcl9pZCI6MSwiaWF0IjoxNjQ0NjUyMzQwLCJleHAiOjE2NDUyNTcxNDAsImF1ZCI6InItdGVjaCIsImlzcyI6InItdGVjaCJ9.7MEnthIMKnIUtLWrP9ty-VoAxcC5_Kd4ycHITz-xolo",
    urlText:
      "Dummy copy xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx (1).pdf",
    uploadType: "Upload This And Show This In Mobile App",
  },
  {
    title: "Lorem Ipsum is simply dummy te",
    type: "All",
    enabled: false,
    archive: true,
    url: "http://sanmateo-test.infojiniconsulting.com/api/v1/surveys/9/export?JWT=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3Nfa2V5IjoiX2tlZGo0NWFtZiIsInZlbmRvcl9pZCI6MSwiaWF0IjoxNjQ0NjUyMzQwLCJleHAiOjE2NDUyNTcxNDAsImF1ZCI6InItdGVjaCIsImlzcyI6InItdGVjaCJ9.7MEnthIMKnIUtLWrP9ty-VoAxcC5_Kd4ycHITz-xolo",
    urlText:
      "Dummy copy xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx (1).pdf",
    uploadType: "Upload This And Show This In Mobile App",
  },
  {
    title: "Annual Notice Of Benefits",
    type: "All",
    enabled: false,
    archive: true,
    url: "http://sanmateo-test.infojiniconsulting.com/api/v1/surveys/9/export?JWT=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3Nfa2V5IjoiX2tlZGo0NWFtZiIsInZlbmRvcl9pZCI6MSwiaWF0IjoxNjQ0NjUyMzQwLCJleHAiOjE2NDUyNTcxNDAsImF1ZCI6InItdGVjaCIsImlzcyI6InItdGVjaCJ9.7MEnthIMKnIUtLWrP9ty-VoAxcC5_Kd4ycHITz-xolo",
    urlText:
      "Dummy copy xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx (1).pdf",
    uploadType: "Upload This And Show This In Mobile App",
  },
  {
    title: "Test New Newsletter",
    type: "Resource Families",
    enabled: true,
    archive: false,
    url: "http://sanmateo-test.infojiniconsulting.com/api/v1/surveys/9/export?JWT=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3Nfa2V5IjoiX2tlZGo0NWFtZiIsInZlbmRvcl9pZCI6MSwiaWF0IjoxNjQ0NjUyMzQwLCJleHAiOjE2NDUyNTcxNDAsImF1ZCI6InItdGVjaCIsImlzcyI6InItdGVjaCJ9.7MEnthIMKnIUtLWrP9ty-VoAxcC5_Kd4ycHITz-xolo",
    urlText:
      "Dummy copy xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx (1).pdf",
    uploadType: "Upload This, But Do Not Show This In Mobile App",
  },
  {
    title: "Annual Notice Of Benifits",
    type: "Foster Youth",
    enabled: true,
    archive: false,
    url: "http://sanmateo-test.infojiniconsulting.com/api/v1/surveys/9/export?JWT=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3Nfa2V5IjoiX2tlZGo0NWFtZiIsInZlbmRvcl9pZCI6MSwiaWF0IjoxNjQ0NjUyMzQwLCJleHAiOjE2NDUyNTcxNDAsImF1ZCI6InItdGVjaCIsImlzcyI6InItdGVjaCJ9.7MEnthIMKnIUtLWrP9ty-VoAxcC5_Kd4ycHITz-xolo",
    urlText:
      "Dummy copy xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx (1).pdf",
    uploadType: "Upload This, But Do Not Show This In Mobile App",
  },
  {
    title: "Volunteer & Community Involvement",
    type: "Foster Youth",
    enabled: true,
    archive: false,
    url: "http://sanmateo-test.infojiniconsulting.com/api/v1/surveys/9/export?JWT=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3Nfa2V5IjoiX2tlZGo0NWFtZiIsInZlbmRvcl9pZCI6MSwiaWF0IjoxNjQ0NjUyMzQwLCJleHAiOjE2NDUyNTcxNDAsImF1ZCI6InItdGVjaCIsImlzcyI6InItdGVjaCJ9.7MEnthIMKnIUtLWrP9ty-VoAxcC5_Kd4ycHITz-xolo",
    urlText:
      "Dummy copy xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx (1).pdf",
    uploadType: "Upload This, But Do Not Show This In Mobile App",
  },
];

const CONTACT_DETAILS = {
  address: "San Mateo, San Mateo County, California",
  email: "smcfosterconnect@smcgov.org",
  description: "Office hours are M-F (Except Holidays) 08:00 AM - 05:00 PM",
  phone: "( 123 ) 456 - 7890",
  extension: "2828",
  facebook: "https://www.facebook.com",
  twitter: "https://www.twitter.com",
  instagram: "https://www.instagram.com",
  linkdein: "https://www.linkdein.com",
};

const now = new Date();
const EVENTS_DETAILS_DATE = [
  {
    id: 0,
    title: "All Day Event very long title",
    allDay: true,
    start: new Date(2022, 3, 0),
    end: new Date(2022, 3, 1),
  },
  {
    id: 1,
    title: "Long Event",
    start: new Date(2022, 3, 7),
    end: new Date(2022, 3, 10),
  },
  {
    id: 2,
    title: "DTS STARTS",
    start: new Date(2022, 3, 13, 0, 0, 0),
    end: new Date(2022, 3, 20, 0, 0, 0),
  },
  {
    id: 3,
    title: "DTS ENDS",
    start: new Date(2022, 3, 6, 0, 0, 0),
    end: new Date(2022, 3, 13, 0, 0, 0),
  },
  {
    id: 4,
    title: "Some Event",
    start: new Date(2022, 3, 9, 0, 0, 0),
    end: new Date(2022, 3, 10, 0, 0, 0),
  },
  {
    id: 5,
    title: "Today",
    start: new Date(new Date().setHours(new Date().getHours() - 3)),
    end: new Date(new Date().setHours(new Date().getHours() + 3)),
  },
  {
    id: 6,
    title: "1 Point in Time Event",
    start: now,
    end: now,
  },
  {
    id: 7,
    title: "2 Point in Time Event",
    start: new Date(new Date().setHours(new Date().getHours() - 4)),
    end: new Date(new Date().setHours(new Date().getHours() + 1)),
  },
  {
    id: 8,
    title: "3 Point in Time Event",
    start: new Date(new Date().setHours(new Date().getHours() - 5)),
    end: new Date(new Date().setHours(new Date().getHours() + 2)),
  },
  {
    id: 9,
    title: "4 Point in Time Event",
    start: new Date(new Date().setHours(new Date().getHours() - 6)),
    end: new Date(new Date().setHours(new Date().getHours() + 3)),
  },
];

const USER_LIST = [
  {
    firstName: "Salvarado",
    lastName: "Sanchez",
    assigned: [
      {
        category: "About Us",
        isView: true,
        isAddEdit: false,
        isDelete: true,
      },
      {
        category: "Banner",
        isView: true,
        isAddEdit: false,
        isDelete: true,
      },
      {
        category: "About Us",
        isView: true,
        isAddEdit: false,
        isDelete: true,
      },
      {
        category: "Contact Us",
        isView: true,
        isAddEdit: false,
        isDelete: true,
      },
      {
        category: "Directory",
        isView: true,
        isAddEdit: false,
        isDelete: true,
      },
      {
        category: "Events",
        isView: true,
        isAddEdit: false,
        isDelete: true,
      },
      {
        category: "How To's",
        isView: true,
        isAddEdit: false,
        isDelete: true,
      },
      {
        category: "Newsletter",
        isView: true,
        isAddEdit: false,
        isDelete: true,
      },
      {
        category: "Program",
        isView: true,
        isAddEdit: false,
        isDelete: true,
      },
      {
        category: "Push Notifications",
        isView: true,
        isAddEdit: false,
        isDelete: true,
      },
      {
        category: "Settings",
        isView: true,
        isAddEdit: false,
        isDelete: true,
      },
      {
        category: "Survey",
        isView: true,
        isAddEdit: false,
        isDelete: true,
      },
    ],
    email: "test@tst.com",
  },
  {
    firstName: "Teat",
    lastName: "Email",
    assigned: [
      {
        category: "About Us",
        isView: true,
        isAddEdit: true,
        isDelete: false,
      },
      {
        category: "Banner",
        isView: true,
        isAddEdit: true,
        isDelete: false,
      },
      {
        category: "About Us",
        isView: true,
        isAddEdit: true,
        isDelete: false,
      },
      {
        category: "Contact Us",
        isView: true,
        isAddEdit: true,
        isDelete: false,
      },
      {
        category: "Directory",
        isView: true,
        isAddEdit: true,
        isDelete: false,
      },
      {
        category: "Events",
        isView: true,
        isAddEdit: true,
        isDelete: false,
      },
      {
        category: "How To's",
        isView: true,
        isAddEdit: true,
        isDelete: false,
      },
      {
        category: "Newsletter",
        isView: true,
        isAddEdit: true,
        isDelete: false,
      },
      {
        category: "Program",
        isView: true,
        isAddEdit: true,
        isDelete: false,
      },
      {
        category: "Push Notifications",
        isView: true,
        isAddEdit: true,
        isDelete: false,
      },
      {
        category: "Settings",
        isView: true,
        isAddEdit: true,
        isDelete: false,
      },
      {
        category: "Survey",
        isView: true,
        isAddEdit: true,
        isDelete: false,
      },
    ],
    email: "test@tst.com",
  },
];

const SETTING_LIST = [
  {
    feature: "Programs",
    label: "Programs",
    enabled: true,
  },
  {
    feature: "Services and Guides",
    label: "How To's",
    enabled: true,
  },
  {
    feature: "Events",
    label: "Events",
    enabled: true,
  },
  {
    feature: "Surveys",
    label: "Surveys",
    enabled: true,
  },
  {
    feature: "Newsletters",
    label: "Newsletters",
    enabled: true,
  },
  {
    feature: "Directory",
    label: "Directory",
    enabled: true,
  },
  {
    feature: "Contact Us",
    label: "Contact Us",
    enabled: true,
  },
];

export {
  APP_FEATURE,
  NOTIFICATION_OPTIONS,
  QUESTION_TYPE,
  SORTBY_OPTIONS,
  MANDATORY_TYPE,
  UPLOAD_TYPE,
  CATEGORY_DETAILS_OPTIONS,
  EVENTS_DURATIONS_OPTIONS,
  PROGRAM_LIST,
  HOMELESSNESS_CATEGORY,
  FINANCIAL_ASSISTANCE_CATEGORY,
  EMERGENCY_SERVICES_CATEGORY,
  COMMUNITY_RESOURCES_CATEGORY,
  PARTNERS_CATEGORY,
  SERVICE_GUIDELINE_LIST,
  SERVICE_GUIDELINE_CATEGORY,
  PUSHNOTIFICATION_FILTER_OPTIONS,
  SURVEY_FILTER_OPTIONS,
  SURVEY_LIST,
  NEWSLETTER_LIST,
  CONTACT_DETAILS,
  EVENTS_DETAILS_DATE,
  USER_LIST,
  SETTING_LIST,
};
