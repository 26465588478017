/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// framework
import { Container, Row, Col } from "react-bootstrap";

// styles

// components
import { Btn, BreadCrumb, Legend } from "../../../components";

const SurveyDetails = () => {
  const location = useLocation();

  const paramVar = location.param;
  const [param, setParam] = useState(paramVar);

  const breadcrumb = [
    {
      label: "Survey",
      href: "/survey/list",
      active: false,
    },
    {
      label: "Details",
      href: "/survey/details",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };
  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Survey Details</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/survey/list", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row no-border">
              <Legend
                label="Overview"
                actions={
                  <Btn
                    label="Edit"
                    variant="outline-primary"
                    size={"sm"}
                    onClick={() => navigation("/survey/edit", param)}
                  />
                }
              />
            </div>
            <div className="screen-view-row no-border mb-0 pb-0">
              <div className="screen-details-row">
                <Row>
                  <Col sm={12}>
                    <div className="text-wrap title-wrap title-underline">
                      <h5>{param.title}</h5>
                      <div className="text-wrap subtitle-wrap mb-0">
                        <p>{param.createdOn}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="screen-details-row">
                <Row>
                  <Col sm={12}>
                    <div className="text-wrap subtitle-wrap">
                      <p className="subtitle1">DESCRIPTION</p>
                      <p>{param.description}</p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="screen-details-row">
                <Row>
                  <Col sm={12}>
                    <div className="text-wrap subtitle-wrap">
                      <p className="subtitle1">SUCCESS MESSAGE</p>
                      <p>{param.message}</p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="screen-details-row">
                <Row>
                  <Col sm={6}>
                    <div className="text-wrap subtitle-wrap">
                      <p className="subtitle1">SURVEY CODE</p>
                      <p>{param.code}</p>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="text-wrap subtitle-wrap">
                      <p className="subtitle1">SERVICE TYPE</p>
                      <p>{param.type}</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="screen-view-row">
              {param.questions.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="screen-view-row no-border">
                      <Legend
                        label={"Question " + (index + 1)}
                        actions={
                          <Btn
                            label="Edit"
                            variant="outline-primary"
                            size={"sm"}
                            onClick={() =>
                              navigation("/survey/questions/edit", {
                                param: param,
                                questionIndex: index,
                              })
                            }
                          />
                        }
                      />
                    </div>
                    <div className="screen-details-row">
                      <Row>
                        <Col sm={12}>
                          <div className="text-wrap subtitle-wrap mb-3">
                            <p className="subtitle1">QUESTION</p>
                            <p>{item.title}</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="screen-details-row">
                      <Row>
                        <Col sm={12}>
                          <div className="text-wrap subtitle-wrap mb-3">
                            <p className="subtitle1">QUESTION TYPE</p>
                            <p className="text-capitalize">{item.type}</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="screen-details-row">
                      <Row>
                        <Col sm={12}>
                          <div className="text-wrap subtitle-wrap mb-3">
                            <p className="subtitle1">IS MANDATORY</p>
                            <p>{item.isMandatory}</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="screen-details-row">
                      <Row>
                        <Col sm={12}>
                          <div className="text-wrap subtitle-wrap">
                            <p className="subtitle1">Options</p>
                            {item.options.map((item, index) => {
                              return (
                                <p key={index}>
                                  {index}. {item.label}
                                </p>
                              );
                            })}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            <div className="screen-view-row">
              <Row>
                <Col lg={12} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="danger"
                      size={"lg"}
                      label="Delete"
                      onClick={() => {}}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SurveyDetails;
