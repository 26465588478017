/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// framework
import { Container, Row, Col } from "react-bootstrap";

// styles

// components
import { Btn, BreadCrumb, Legend } from "../../../components";

const ServiceAndGuidelineDetails = () => {
  const location = useLocation();

  const paramVar = location.param;
  const [param, setParam] = useState(paramVar);

  const breadcrumb = [
    {
      label: "How To's",
      href: "/service/list",
      active: false,
    },
    {
      label: "Subcategory Details",
      href: "/service/details",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Details</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/service/list", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row no-border">
              <Legend
                // label="Overview"
                actions={
                  <Btn
                    label="Edit"
                    variant="outline-primary"
                    size={"sm"}
                    onClick={() =>
                      navigation("/service/subcategory/edit", param)
                    }
                  />
                }
              />
            </div>
            <div className="screen-view-row">
              <div className="screen-details-row">
                <Row>
                  <Col sm={12}>
                    <div className="text-wrap title-wrap title-underline">
                      <h5>{param.title}</h5>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="screen-details-row">
                <Row>
                  {param.url !== "" && (
                    <Col sm={6}>
                      <div className="text-wrap subtitle-wrap">
                        <p className="subtitle1 text-uppercase">
                          {param.urlType}
                        </p>
                        <p className="text-truncate">
                          <a href={param.url} target="_blank" rel="noreferrer">
                            {param.urlText}
                          </a>
                        </p>
                      </div>
                    </Col>
                  )}
                  <Col sm={6}>
                    <div className="text-wrap subtitle-wrap">
                      <p className="subtitle1">SERVICE TYPE</p>
                      <p>{param.type}</p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="screen-details-row">
                <Row>
                  <Col sm={12}>
                    <div className="text-wrap subtitle-wrap">
                      <p className="subtitle1">DESCRIPTION</p>
                      <p>
                        <i>{param.description}</i>
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="screen-view-row">
              <Row>
                <Col lg={12} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="danger"
                      size={"lg"}
                      label="Delete"
                      onClick={() => {}}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ServiceAndGuidelineDetails;
