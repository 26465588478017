/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// framework
import { Container, Row, Col, Form } from "react-bootstrap";

// styles

// components
import { Btn, BreadCrumb, Input, Dialog } from "../../../components";

// utils
// import { CATEGORY_DETAILS_OPTIONS } from '../../../utils/service';

const CategoryEdit = () => {
  const [modalLocateMap, setModalLocateMap] = useState(false);

  const location = useLocation();
  // const [categoryDefaultValue, setCategoryDefaultValue] = useState("Web Link");

  const paramVar = location.param;
  const [param] = useState(paramVar);

  const breadcrumb = [
    {
      label: "Contact Us",
      href: "/contact/details",
      active: false,
    },
    {
      label: "Edit",
      href: "/contact/edit",
      active: true,
    },
  ];

  useEffect(() => {}, []);

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Edit Contact Us</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/contact/details", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row">
              <Form className="form-wrap">
                <Row>
                  <Col lg={6} sm={12}>
                    <Form.Group
                      className={`form-group mb-0 d-flex justify-content-between`}
                    >
                      <Form.Label>Address</Form.Label>
                      <div className="btn-wrap">
                        <Btn
                          variant="link"
                          label="View / Locate on Google Maps"
                          style={"text-capitalize"}
                          onClick={() => {
                            setModalLocateMap(true);
                          }}
                        />
                      </div>
                    </Form.Group>
                    <Input
                      placeholder="Address"
                      type="input"
                      defaultValue={param.address}
                      onChange={(data) => {
                        //console.log(data.target.value, "--- input");
                      }}
                    />
                    <Input
                      defaultValue="San Manteo"
                      type="input"
                      disabled={true}
                    />
                    <Input
                      placeholder="Add optional details like room number, floor, landmark"
                      type="input"
                      onChange={(data) => {
                        //console.log(data.target.value, "--- input");
                      }}
                    />
                    <Input
                      defaultValue="California"
                      type="input"
                      disabled={true}
                    />
                    <Row>
                      <Col lg={6} sm={12}>
                        <Input
                          placeholder="Sanmanteo Country"
                          type="input"
                          disabled={true}
                        />
                      </Col>
                      <Col lg={6} sm={12}>
                        <Input
                          placeholder="Zip Code"
                          type="input"
                          disabled={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={3}>
                        <Input
                          label="Extension."
                          placeholder="Extn."
                          type="input"
                          defaultValue={param.extension}
                        />
                      </Col>
                      <Col sm={6}>
                        <Input
                          label="Phone Number(Optional)"
                          placeholder="Phone Number"
                          type="input"
                          defaultValue={param.phone}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6} sm={12}>
                    <Input
                      label="Email Address (Optional)"
                      placeholder="Email Address (Optional)"
                      type="input"
                      defaultValue={param.email}
                      onChange={(data) => {
                        //console.log(data.target.value, "--- input");
                      }}
                    />
                    <Input
                      label="Description (Optional)"
                      placeholder="Description (Optional)"
                      type="input"
                      showLength={true}
                      maxLength={100}
                      as="textarea"
                      rows={4}
                      defaultValue={param.description}
                      onChange={(data) => {
                        //console.log(data.target.value, "--- input");
                      }}
                    />
                    <div className=" form-group">
                      <Form.Label>Social Networks (Optional)</Form.Label>
                      <Input
                        label="Facebook :"
                        placeholder="Url"
                        id="fb_url"
                        type="input"
                        containerStyle="form-group-input-inline"
                        defaultValue={param.facebook}
                        onChange={(data) => {
                          //console.log(data.target.value, "--- input");
                        }}
                      />
                      <Input
                        label="Instagram :"
                        placeholder="Url"
                        id="instagram_url"
                        type="input"
                        containerStyle="form-group-input-inline"
                        defaultValue={param.instagram}
                        onChange={(data) => {
                          //console.log(data.target.value, "--- input");
                        }}
                      />
                      <Input
                        label="Linkedin :"
                        placeholder="Url"
                        id="linkedin_url"
                        type="input"
                        containerStyle="form-group-input-inline"
                        defaultValue={param.linkdein}
                        onChange={(data) => {
                          //console.log(data.target.value, "--- input");
                        }}
                      />
                      <Input
                        label="Twitter :"
                        placeholder="Url"
                        id="twitter_url"
                        type="input"
                        containerStyle="form-group-input-inline"
                        defaultValue={param.twitter}
                        onChange={(data) => {
                          //console.log(data.target.value, "--- input");
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
            <div className="screen-view-row">
              <Row>
                <Col lg={12} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Cancel"
                      onClick={() => {
                        navigation("/contact/details", "");
                      }}
                    />
                    <Btn
                      variant="primary"
                      size={"lg"}
                      label="Submit"
                      onClick={() => {}}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
        <Dialog
          title={"Map"}
          description={
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.618692267389!2d72.8351312153137!3d19.12437675541878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9d8e30e817f%3A0xa53c0adc9279315d!2sDamacloid%20technologies%20private%20limited!5e0!3m2!1sen!2sin!4v1645602980322!5m2!1sen!2sin"
              allowfullscreen=""
              loading="lazy"
              className="w-100"
              style={{ height: "350px" }}
            ></iframe>
          }
          centered={true}
          show={modalLocateMap}
          size={"lg"}
          close={(val) => {
            setModalLocateMap(val);
          }}
          actions={
            <div className="btn-wrap d-flex justify-content-end">
              <Btn
                variant="primary"
                label="Ok"
                onClick={() => {
                  setModalLocateMap(false);
                }}
              />
            </div>
          }
        />
      </div>
    </React.Fragment>
  );
};

export default CategoryEdit;
