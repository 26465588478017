import React from "react";
import PropTypes from "prop-types";

// framework
import { Card, Row, Col } from "react-bootstrap";

// styles
import "../feature-card.scss";

const FeatureProgramsCard = (props) => {
  return (
    <div
      className={`feature-program-card-wrap ${
        props.count != null ? "feature-program-count" : ""
      } ${props.style}`}
    >
      <Card onClick={props.onClick}>
        <Card.Body>
          <Row className="FeatureCardCentreAlign">
            <Col sm={3}>
              <div className="feature-icon-wrap">
                <img className="logo-img" src={props.icon} alt="Icon" />
              </div>
            </Col>
            <Col sm={props.icon != null ? 7 : 10}>
              {props.count != null && (
                <div className="program-count">{props.count + 1}</div>
              )}
              <div className="feature-content-title-wrap">
                <div className="text-wrap subtitle-wrap mb-0">
                  <p className="subtitle1 text-truncate">{props.title}</p>
                  {props.subTitle !== "" && (
                    <h6 className="text-truncate">{props.subTitle}</h6>
                  )}
                </div>
              </div>
              {props.description && (
                <div className="feature-content-details-wrap">
                  {props.description}
                </div>
              )}
            </Col>
            {props.actions && (
              <Col sm={2}>
                <div className="btn-wrap d-flex justify-content-end">
                  {props.actions}
                </div>
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

/**
	title: Set title of card
	subTitle: Set subTitle of card
	description: Set description as render HTML element
	style: Set description as render HTML element
	count: show counter of each card
	icon: Provide icon src as a image
	actions: handlers from firing regardless of the rendered element.
	onClick: handlers from firing regardless of the rendered element.
*/

FeatureProgramsCard.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  style: PropTypes.string,
  icon: PropTypes.string,
  count: PropTypes.number,
  description: PropTypes.object,
  actions: PropTypes.object,
  onClick: PropTypes.func,
};

FeatureProgramsCard.defaultProps = {
  title: "",
  subTitle: "",
  style: "",
  count: null,
  icon: "",
  description: null,
  actions: null,
  onClick: () => {},
};

export default FeatureProgramsCard;
