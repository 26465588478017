import PropTypes from "prop-types";

// framework
import { Container } from "react-bootstrap";

// style
import "./nav-footer.scss";

const NavFooter = (props) => {
  return (
    <footer>
      <Container>
        <div className="text-wrap">
          <p className="subtitle2">{props.label}</p>
        </div>
      </Container>
    </footer>
  );
};

/**
 * label: set label text
 */

NavFooter.propTypes = {
  label: PropTypes.string,
};

NavFooter.defaultProps = {
  label: "© All Rights Reserved",
};

export default NavFooter;
