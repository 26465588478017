import React from "react";
import "./index.scss";

const LoadingSpinner = ({ imageStatus }) => {
  return (
    <>
      {imageStatus ? (
        <div className="spinner-Imagecontainer">
          <div className="loading-spinner"></div>
        </div>
      )
        :
        (
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        )
      }
    </>
  );
};

export default LoadingSpinner;
