import Actions from "./actions";

export const getBannerData = () => {
  return {
    type: Actions.GET_BANNER_DATA,
  };
};

export const resetState = () => {
  return{
    type: Actions.RESET_STATE
  }
}

export const getBannerDetails = (data) => {
  return {
    type: Actions.GET_BANNER_DETAILS,
    data,
  };
};
export const postBannerData = (data) => {
  return {
    type: Actions.POST_BANNER_DATA,
    data,
  };
};

export const updateBannerData = (id, data) => {
  return {
    type: Actions.UPDATE_BANNER_DATA,
    id,
    data,
  };
};

export const deleteBannerById = (id) => {
  return {
    type: Actions.DELETE_BANNER_BY_ID,
    id,
  };
};

export const postCoverPicture = (data) => {
  return {
    type: Actions.POST_COVERPICTURE_DATA,
    data,
  };
};

export const getCoverPictureData = (data) => {
  return {
    type: Actions.GET_COVERPICTURE_DATA,
    data,
  };
};

export const removeBannerImage = (data) => {
  return {
    type: Actions.REMOVE_BANNER_IMAGE,
    data,
  };
};

export const postAttchmentFileData = (data) => {
  return {
    type: Actions.POST_ATTACHMENT_DATA,
    data,
  };
};
