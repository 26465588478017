import React from "react";
import { useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import { BreadCrumb } from "../../../components";
import NotificationForm from "../notificationsForm";
import { connect } from "react-redux";
import { useEffect } from "react";
import { getSettingsData } from "../../home/store/dispatchers";

const PushNotificationAdd = ({ settingsData, getSettingsData }) => {
  const breadcrumb = [
    {
      label: "Push Notification",
      href: "/notifications/list",
      active: false,
    },
    {
      label: "Add Notification",
      href: "/push-notification/add",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  useEffect(() => {
    if (!settingsData) {
      getSettingsData();
    }
  }, [getSettingsData, settingsData]);

  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <NotificationForm
              formTitle="Add"
              navigation={navigation}
              getSettingsData={settingsData}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

const MapStateToProps = ({ home }) => {
  return {
    settingsData: home.settingDetails,
  };
};

const MapDispatchToProps = (dispatcher) => {
  return {
    getSettingsData: () => dispatcher(getSettingsData()),
  };
};

export default connect(
  MapStateToProps,
  MapDispatchToProps
)(PushNotificationAdd);
