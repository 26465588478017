import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
// import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from "moment";
// import { GLOBAL_IMAGES } from "../../../assets/images/global-images";
import {
  Btn,
  BreadCrumb,
  Legend,
  // DisplayCoverPicture,
  Dialog,
} from "../../../components";
import { connect } from "react-redux";
import { deleteEventsData, getEventsDataById } from "../store/disptahcers";
import { useEffect } from "react";

// utils
// import * as dates from "../../../utils/dates";
// import { EVENTS_DETAILS_DATE } from '../../../utils/service';

const EventsDetails = ({
  eventData,
  deleteEventsData,
  deleteEventsDataSuccess,
  getEventsDataById,
  eventDataId,
}) => {
  const location = useLocation();

  const paramVar = location.param;
  const [param] = useState(paramVar);
  const [EventsId, setEventsId] = useState("");
  const [modalDelete, setModalDelete] = useState(false);

  const breadcrumb = [
    {
      label: "Event Calendar",
      href: "/events/list",
      active: false,
    },
    {
      label: "Details",
      href: "/events/details",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  useEffect(() => {
    let pathname = location?.pathname.split("/");
    const pathID = pathname[3];
    setEventsId(pathID);
    getEventsDataById(parseInt(pathID));
  }, [location.pathname]);

  useEffect(() => {
    if (deleteEventsDataSuccess) {
      navigation("/events/list");
    }
  }, [deleteEventsDataSuccess]);

  const onDelete = () => {
    deleteEventsData(EventsId);
  };

  return (
    <React.Fragment>
      {/* <NavHeader /> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Event Calendar</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/events/list", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row no-border">
              <Legend
                label="Details"
                actions={
                  <Btn
                    label="Edit"
                    variant="outline-primary"
                    size={"sm"}
                    onClick={() =>
                      navigation(`/events/edit/${eventDataId?.data.id}`, param)
                    }
                  />
                }
              />
            </div>
            <div className="screen-view-row">
              <Row>
                {/* <Col sm={3}>
                  <DisplayCoverPicture src={GLOBAL_IMAGES.DUMMY_COVER_IMAGE} />
                </Col> */}
                <Col sm={9} lg={12}>
                  <div className="screen-details-row">
                    <Row>
                      <Col sm={12}>
                        <div className="text-wrap title-wrap title-underline">
                          <h5>{eventDataId?.data.title.en}</h5>
                          <div className="text-wrap subtitle-wrap mb-0">
                            <p>
                              {/* {moment(eventDataId?.data.startDate).format(
                                "YYYY/MMM/DD"
                              )}{" "}
                              -{" "}
                              {moment(eventDataId?.data.endDate).format(
                                "YYYY/MMM/DD"
                              )} */}
                              {moment(eventDataId?.data.startDate).format(
                                "MMM/DD/YYYY"
                              ) !==
                              moment(eventDataId?.data.endDate).format(
                                "MMM/DD/YYYY"
                              )
                                ? moment(eventDataId?.data.startDate).format(
                                    "MMM/DD/YYYY"
                                  ) +
                                  " - " +
                                  moment(eventDataId?.data.endDate).format(
                                    "MMM/DD/YYYY"
                                  )
                                : moment(eventDataId?.data.startDate).format(
                                    "MMM/DD/YYYY"
                                  )}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="screen-details-row">
                    <Row>
                      {/* <Col lg={6} sm={12}>
                        <div className="text-wrap subtitle-wrap">
                          <p className="subtitle1">VENUE</p>
                          <p>
                            12300 Bermuda Road Henderson, Clark County, Nevada
                            89044
                          </p>
                        </div>
                      </Col> */}
                      <Col lg={6} sm={12}>
                        <div className="text-wrap subtitle-wrap">
                          <p className="subtitle1">DESCRIPTION</p>
                          <p
                            className="subtitle3"
                            dangerouslySetInnerHTML={{
                              __html: eventDataId?.data.description?.en,
                            }}
                          ></p>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className="text-wrap subtitle-wrap">
                          <p className="subtitle1">TIMING</p>
                          <p className="subtitle3">
                            {moment(eventDataId?.data.startDate).format(
                              "h:mm A"
                            )}{" "}
                            -{" "}
                            {moment(eventDataId?.data.endDate).format("h:mm A")}
                          </p>
                        </div>
                      </Col>
                      {/* <Col sm={12}>
                        <div className="text-wrap subtitle-wrap">
                          <p className="subtitle1">EVENT FOR</p>
                          <p className="subtitle3">All</p>
                        </div>
                      </Col> */}
                    </Row>
                  </div>
                </Col>
              </Row>

              <div className="screen-view-row">
                <Row>
                  <Col lg={12} sm={12}>
                    <div className="btn-wrap d-flex justify-content-end">
                      <Btn
                        variant="danger"
                        size={"lg"}
                        label="Delete"
                        onClick={() => {
                          setModalDelete(true);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Container>

        <Dialog
          title={"Delete Item"}
          description={<p>Are you sure you want to delete this Event?</p>}
          centered={true}
          show={modalDelete}
          close={(val) => {
            setModalDelete(val);
          }}
          actions={
            <div className="btn-wrap d-flex justify-content-end">
              <Btn
                variant="secondary"
                label="No"
                onClick={() => {
                  setModalDelete(false);
                }}
              />
              <Btn
                variant="primary"
                label="Yes"
                onClick={() => {
                  onDelete();
                }}
              />
            </div>
          }
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ event }) => {
  return {
    eventData: event.eventData,
    deleteEventsDataSuccess: event.deleteEventsDataSuccess,
    eventDataId: event.eventDataId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteEventsData: (id) => dispatch(deleteEventsData(id)),
    getEventsDataById: (id) => dispatch(getEventsDataById(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsDetails);
