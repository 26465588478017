import React from "react";
import PropTypes from "prop-types";

// framework
import { Form } from "react-bootstrap";

// plugin
import { RangeDatePicker } from "@y0c/react-datepicker";

// style
import "../input-date-time.scss";

const InputDateRange = (props) => {
  const handleChange = (start, end) => {
    // Day.js object
    props.onChange(start, end);
  };

  return (
    <Form.Group
      controlId={props.id}
      className={`${props.containerStyle} form-group`}
    >
      {props.label && (
        <Form.Label className={`${props.labelStyle}`}>{props.label}</Form.Label>
      )}
      <div
        className={`form-date-wrap ${props.isInvalid ? "is-invalid" : ""} ${
          props.isValid ? "is-valid" : ""
        }`}
      >
        <RangeDatePicker
          {...props}
          onChange={handleChange}
          showDefaultIcon={true}
          readOnly={props.readOnly}
          disabled={props.disabled}
          showToday={props.showToday}
          dateFormat={props.dateFormat}
          includeTime={props.includeTime}
          onClick={() => console.log("clicke close")}
          disableDay={props.disableDay}
          startPlaceholder={props.startPlaceholder}
          endPlaceholder={props.endPlaceholder}
          icon={true}
        />
      </div>
      {props.helperText && (
        <Form.Control.Feedback
          type={`${props.isValid && "valid"} ${props.isInvalid && "invalid"}`}
        >
          {props.helperText}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

/**
 * label: set label text
 * id: set element id
 * startPlaceholder: start input placeholder
 * endPlaceholder: end input placeholder
 * initialStartDate: Initial Start Date
 * initialEndDate: Initial End Date
 * dateFormat: set date format
 * style: Manually style the control
 * labelStyle: Manually style the label
 * containerStyle: Manually style the wrapper
 * helperText:  providing helper text
 * disabled: Make the control disabled
 * isValid : Manually style the input as valid
 * isInvalid : Manually style the input as invalid
 * readOnly : Readlonly element
 * includeTime : include TimePicker true/false
 * showToday : TodayPanel show or hide
 * onChange: handlers from firing regardless of the rendered element. (start?: Dayjs, end?: Dayjs) => void
 * disableDay: Calendar day disable
 */

InputDateRange.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  startPlaceholder: PropTypes.string,
  endPlaceholder: PropTypes.string,
  style: PropTypes.string,
  labelStyle: PropTypes.string,
  // initialStartDate: PropTypes.string,
  // initialEndDate: PropTypes.string,
  dateFormat: PropTypes.string,
  containerStyle: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  isValid: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.array,
  ]),
  isInvalid: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.array,
  ]),
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  includeTime: PropTypes.bool,
  showToday: PropTypes.bool,
  disableDay: PropTypes.func,
};

InputDateRange.defaultProps = {
  label: "",
  id: "",
  startPlaceholder: "Start Date",
  endPlaceholder: "End Date",
  style: "",
  labelStyle: "",
  // initialStartDate: null,
  // initialEndDate: null,
  dateFormat: "YYYY/MM/DD", // YYYY/MM/DD HH:MM A
  containerStyle: "",
  helperText: "",
  disabled: false,
  isValid: false,
  isInvalid: false,
  readOnly: false,
  required: false,
  includeTime: false,
  showToday: false,
  onChange: () => {},
  disableDay: () => {},
};

export default InputDateRange;
