import Actions from "./actions";

export const getServiceData = () => {
  return {
    type: Actions.GET_SERVICE_DATA,
  };
};

export const resetState = () => {
  return {
    type: Actions.RESET_STATE,
  };
};

export const uploadCoverPhoto = (data) => {
  return {
    type: Actions.UPLOAD_COVER_PHOTO,
    data,
  };
};

export const removeCoverPhoto = (data) => {
  return {
    type: Actions.REMOVE_COVER_PHOTO,
    data,
  };
};

export const getContentData = (modifiedCategoryType, ServiceType) => {
  return {
    type: Actions.GET_CONTENT_DATA,
    modifiedCategoryType,
    ServiceType,
  };
};

export const getItemDetailsById = (data) => {
  return {
    type: Actions.GET_ITEM_DETAILS_BY_ID,
    data,
  };
};

export const getContentsDetailsById = (data) => {
  return {
    type: Actions.GET_CONTENTS_DETAILS_BY_ID,
    data,
  };
};

export const fetchItemsList = (modifiedCategoryType, ServiceType) => {
  return {
    type: Actions.FETCH_ITEMS_LIST,
    modifiedCategoryType,
    ServiceType,
  };
};

export const createItemsDispatch = (data, serviceType) => {
  return {
    type: Actions.GET_POST_DATA,
    data,
    serviceType,
  };
};

export const createContentsDispatch = (data, serviceType) => {
  return {
    type: Actions.GET_POST_CONTENT_DATA,
    data,
    serviceType,
  };
};

export const updateItemsDispatch = (data, serviceType, editId) => {
  return {
    type: Actions.GET_UPDATE_DATA,
    data,
    serviceType,
    editId,
  };
};

export const updateContentsDispatch = (data, serviceType, editId) => {
  return {
    type: Actions.GET_UPDATE_CONTENT_DATA,
    data,
    serviceType,
    editId,
  };
};

export const deleteServiceDispatch = (serviceType, id) => {
  return {
    type: Actions.DELETE_DATA,
    serviceType,
    id,
  };
};

export const deleteContentDispatch = (serviceType, id) => {
  return {
    type: Actions.DELETE_CONTENT_DATA,
    serviceType,
    id,
  };
};

// export const postCoverPicture = (data) => {
//   return {
//     type: Actions.POST_COVERPICTURE_DATA,
//     data,
//   };
// };

export const uploadFile = (data) => {
  return {
    type: Actions.FILE_UPLOAD,
    data,
  };
};
