import React from "react";
import { useHistory } from "react-router-dom";
// framework
import { Container, Row, Col, Form } from "react-bootstrap";
import { Btn, BreadCrumb, Input, Editor } from "../../../components";
// utils

const AboutUsEdit = () => {
  const breadcrumb = [
    {
      label: "About Us",
      href: "/aboutUs/details",
      active: false,
    },
    {
      label: "Edit",
      href: "/aboutUs/edit",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  return (
    <React.Fragment>
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Edit</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/aboutUs/details", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row">
              <Form className="form-wrap">
                <Row>
                  <Col lg={6} sm={12}>
                    <Input
                      label="Title"
                      placeholder="Enter Title"
                      type="input"
                      id="title"
                      defaultValue="There are many variations of passages of Lorem Ips"
                      maxLength={50}
                      showLength={true}
                      onChange={(data) => {
                        //console.log(data.target.value, "--- input");
                      }}
                    />
                    <Editor
                      label="Description"
                      defaultValue={`There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.`}
                      placeholder="Start Writing..."
                      onBlur={(data) => {
                        //console.log(data, "--- editor");
                      }}
                    />
                  </Col>
                </Row>
              </Form>
            </div>
            <div className="screen-view-row">
              <Row>
                <Col lg={12} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Cancel"
                      onClick={() => {
                        navigation("/aboutUs/details", "");
                      }}
                    />
                    <Btn
                      variant="primary"
                      size={"lg"}
                      label="Submit"
                      onClick={() => {}}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AboutUsEdit;
