import Actions from "./actions";

const defaultState = {
  bannerData: {
    bannerDetails: null,
    bannerDataSuccess: false,
    bannerDataFail: false,
    bannerDataInProgress: false,
  },

  coverPhotoUuidData: null,
  coverPhotoDataSuccess: false,
  coverPhotoDataFail: false,
  coverPhotoDataProgress: false,

  getCoverPhoto: {
    getPhotoData: null,
    getPhotoDataSuccess: false,
    getPhotoDataFail: false,
    getPhotoDataProgress: false,
  },
  bannerDetailsById: null,
  bannerDetailsSuccess: false,
  bannerDetailsFail: false,
  bannerDetailsInProgress: false,

  // banner add
  bannerAddInProgress: false,
  bannerAddFail: false,
  bannerAddSuccess: false,

  //banner delete
  bannerDeleteInProgress: false,
  bannerDeleteSuccess: false,
  bannerDeleteFail: false,

  //update banner
  updateBannerInProgress: false,
  updateBannerSuccess: false,
  updateBannerFail: false,

  //Attachment file get
  AttachmentFileUuidDetails: null,
  AttachmentFileUuidDetailsSuccess: false,
  AttachmentFileUuidDetailsInProgress: false,
  AttachmentFileUuidDetailsFail: false,

  // delete banner image
  removeBannerImageInProgress: false,
  removeBannerImageSuccess: false,
  removeBannerImageFail: false,
};

function bannerReducer(state = defaultState, action) {
  switch (action.type) {
    case Actions.RESET_STATE:
      return { ...defaultState };
    case Actions.GET_BANNER_DATA:
      return {
        ...state,
        bannerDataInProgress: true,
        bannerDataSuccess: false,
        bannerDataFail: false,
      };

    case Actions.GET_BANNER_DATA_SUCCESS:
      return {
        ...state,
        bannerDataInProgress: false,
        bannerDataSuccess: true,
        bannerDetails: action.details.data.banners,
        bannerDataFail: false,
      };

    case Actions.GET_BANNER_DATA_FAIL:
      return {
        ...state,
        bannerDataInProgress: false,
        bannerDataSuccess: false,
        bannerDataFail: true,
      };

    // banner details
    case Actions.GET_BANNER_DETAILS:
      return {
        ...state,
        bannerDetailsInProgress: true,
        bannerDetailsSuccess: false,
        bannerDetailsFail: false,
      };

    case Actions.GET_BANNER_DETAILS_SUCCESS:
      return {
        ...state,
        bannerDetailsInProgress: false,
        bannerDetailsSuccess: true,
        bannerDetailsById: action.details.data,
        bannerDetailsFail: false,
      };

    case Actions.GET_BANNER_DETAILS_FAIL:
      return {
        ...state,
        bannerDetailsInProgress: false,
        bannerDetailsSuccess: false,
        bannerDetailsFail: true,
      };

    //create banner
    case Actions.POST_BANNER_DATA:
      return {
        ...state,
        bannerAddInProgress: true,
        bannerAddSuccess: false,
        bannerAddFail: false,
      };

    case Actions.POST_BANNER_DATA_SUCCESS:
      return {
        ...state,
        bannerAddInProgress: false,
        bannerAddSuccess: true,
        bannerAddFail: false,
      };

    case Actions.POST_BANNER_DATA_FAIL:
      return {
        ...state,
        bannerAddInProgress: false,
        bannerAddSuccess: false,
        bannerAddFail: true,
      };
    //delete banner
    case Actions.DELETE_BANNER_BY_ID:
      return {
        ...state,
        bannerDeleteInProgress: true,
        bannerDeleteSuccess: false,
        bannerDeleteFail: false,
      };

    case Actions.DELETE_BANNER_BY_ID_SUCCESS:
      return {
        ...state,
        bannerDeleteInProgress: false,
        bannerDeleteSuccess: true,
        bannerDeleteFail: false,
      };

    case Actions.DELETE_BANNER_BY_ID_FAIL:
      return {
        ...state,
        bannerDeleteInProgress: false,
        bannerDeleteSuccess: false,
        bannerDeleteFail: true,
      };

    //update banner
    case Actions.UPDATE_BANNER_DATA:
      return {
        ...state,
        updateBannerInProgress: true,
        updateBannerSuccess: false,
        updateBannerFail: false,
      };

    case Actions.UPDATE_BANNER_DATA_SUCCESS:
      return {
        ...state,
        updateBannerInProgress: false,
        updateBannerSuccess: true,
        updateBannerFail: false,
      };

    case Actions.UPDATE_BANNER_DATA_FAIL:
      return {
        ...state,
        updateBannerInProgress: false,
        updateBannerSuccess: false,
        updateBannerFail: true,
      };

    case Actions.POST_COVERPICTURE_DATA:
      return {
        ...state,
        coverPhotoDataProgress: true,
        coverPhotoDataSuccess: false,
        coverPhotoDataFail: false,
      };
    case Actions.POST_COVERPICTURE_SUCCESS:
      return {
        ...state,
        coverPhotoDataProgress: false,
        coverPhotoDataSuccess: true,
        coverPhotoUuidData: action.details.data.uuid,
        coverPhotoDataFail: false,
      };
    case Actions.POST_COVERPICTURE_FAIL:
      return {
        ...state,
        coverPhotoDataProgress: false,
        coverPhotoDataSuccess: false,
        coverPhotoDataFail: true,
      };
    case Actions.GET_COVERPICTURE_DATA:
      return {
        ...state.getCoverPhoto,
        getPhotoDataProgress: true,
        getPhotoDataSuccess: false,
        getPhotoDataFail: false,
      };
    case Actions.GET_COVERPICTURE_SUCCESS:
      return {
        ...state.getCoverPhoto,
        getPhotoDataProgress: false,
        getPhotoDataSuccess: true,
        getPhotoData: action.data,
        getPhotoDataFail: false,
      };
    case Actions.GET_COVERPICTURE_FAIL:
      return {
        ...state.getCoverPhoto,
        getPhotoDataProgress: false,
        getPhotoDataSuccess: false,
        getPhotoDataFail: true,
      };
    case Actions.POST_ATTACHMENT_DATA:
      return {
        AttachmentFileUuidDetailsInProgress: true,
        AttachmentFileUuidDetailsSuccess: false,
        AttachmentFileUuidDetailsFail: false,
      };
    case Actions.POST_ATTACHMENT_DATA_SUCCESS:
      return {
        AttachmentFileUuidDetails: action.data.data.uuid,
        AttachmentFileUuidDetailsInProgress: false,
        AttachmentFileUuidDetailsSuccess: true,
        AttachmentFileUuidDetailsFail: false,
      };
    case Actions.POST_ATTACHMENT_DATA_FAIL:
      return {
        AttachmentFileUuidDetailsInProgress: false,
        AttachmentFileUuidDetailsSuccess: false,
        AttachmentFileUuidDetailsFail: true,
      };
    case Actions.REMOVE_BANNER_IMAGE:
      return {
        ...state,
        removeBannerImageInProgress: true,
        removeBannerImageSuccess: false,
        removeBannerImageFail: false,
      };

    case Actions.REMOVE_BANNER_IMAGE_SUCCESS:
      return {
        ...state,
        coverPhotoUuidData: null,
        removeBannerImageInProgress: false,
        removeBannerImageSuccess: true,
        removeBannerImageFail: false,
      };

    case Actions.REMOVE_BANNER_IMAGE_FAIL:
      return {
        ...state,
        removeBannerImageInProgress: false,
        removeBannerImageSuccess: false,
        removeBannerImageFail: true,
      };

    default:
      return { ...state };
  }
}

export default bannerReducer;
