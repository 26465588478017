/* eslint-disable react/style-prop-object */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col, Table } from "react-bootstrap";
import { Btn, BreadCrumb } from "../../../components";
import * as dispatcher from "../store/dispatchers";
import FeatureIcon from "../../../components/cards/feature-icon";

const ProgramsCategory = ({ serviceData, getServiceData }) => {
  const location = useLocation();
  const paramVar = location.param;
  const [param, setParam] = useState(paramVar);
  const subcategoryUrl = `/services/${param}/subcategory`;
  const itemsUrl = `${subcategoryUrl}/items/`;
  const contentUrl = `${subcategoryUrl}/contents/`;

  const categoryName = location.pathname.split("/");

  const findTitle = (service) => {
    return service.name === categoryName[2];
  };

  const par = serviceData?.data.services.find(findTitle);

  const modifiedParam = par?.title.en;

  const breadcrumb = [
    {
      label: "Services",
      href: `/services/list`,
      active: false,
    },
    {
      label: modifiedParam,
      href: `/services/${param}/category`,
      active: true,
    },
  ];

  useEffect(() => {
    let categoryName = location.pathname.split("/");
    categoryName = categoryName[2];
    setParam(categoryName);
    getServiceData();
    if (serviceData) {
      localStorage.setItem("serviceData", JSON.stringify(serviceData));
    }
  }, []);

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  const loadTable = () => {
    return renderData.subCategories?.map((item, index) => {
      return (
        <tr
          key={index}
          onClick={() => {
            if (item.screenType === "items") {
              navigation(`${itemsUrl}${item.title.en}`, item.id);
            } else if (item.screenType === "contents") {
              navigation(`${contentUrl}${item.title.en}`, item.id);
              if (item.title.en === "Sheriff / Police") {
                let str = item.title.en;
                str = str.replace("/", "");
                navigation(`${contentUrl}${str}`, item.id);
              }
            } else {
              navigation(`${subcategoryUrl}${item.title.en}`, item.id);
            }
          }}
        >
          <td>
            <FeatureIcon icon={item.iconUrl} />
          </td>
          <td className="cursor-pointer">{item.title.en}</td>
          <td>
            {item.screenType.charAt(0).toUpperCase() + item.screenType.slice(1)}
          </td>
          <td width={150}>
            <div className="btn-wrap d-flex justify-content-center">
              <Btn
                variant="link"
                icon={<span className="icon-View"></span>}
                style={"btn-icon"}
                size={"sm"}
              />
            </div>
          </td>
        </tr>
      );
    });
  };

  const renderData = serviceData?.data.services.find(
    ({ name }) => name === param
  );

  const render = () => {
    if (renderData) {
      return loadTable(renderData);
    } else {
      return [];
    }
  };

  const name = () => {
    if (param === "homelessness") {
      return "Experiencing Homelessness";
    } else if (param === "financialAssistance") {
      return "Financial Assistance";
    } else if (param === "emergencyServices") {
      return "Emergency Services";
    } else if (param === "communityResources") {
      return "Community Resources";
    } else if (param === "partners") {
      return "Partners";
    }
  };

  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <div className="pCategoryFlexAlign">
                  <Col>
                    <div className="screen-title-wrap">
                      <div className="text-wrap">
                        <p className="subtitle1 fixSubtitle">{name()}</p>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div className="btn-wrap">
                      <Btn
                        variant="secondary"
                        size={"lg"}
                        label="Go Back"
                        onClick={() => navigation("/services/list", "")}
                      />
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
            <div className="screen-view-row">
              <Row>
                <Col sm={12}>
                  <div className="table-wrap">
                    <Table striped bordered>
                      <thead>
                        <tr>
                          <th>Icon</th>
                          <th>Title</th>
                          <th>Type</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>{render()}</tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = ({ program }) => {
  return {
    serviceData: program.programsDetails,
  };
};

export default connect(mapStateToProps, dispatcher)(ProgramsCategory);
