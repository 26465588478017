/* eslint-disable react/style-prop-object */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// framework
import { Container, Row, Col, Form, Accordion } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";

// styles

// components
import { Btn, BreadCrumb, Input, Check, Select } from "../../../components";

// utils
import { QUESTION_TYPE, MANDATORY_TYPE } from "../../../utils/service";

const SurveyUserQuestions = () => {
  const location = useLocation();
  const [selectDefaultValue, setSelectDefaultValue] = useState("");
  const [questionType, setQuestionType] = useState("");

  const paramVar = location.param;
  const [param, setParam] = useState(paramVar);

  const breadcrumb = [
    {
      label: "Survey",
      href: "/survey/list",
      active: false,
    },
    {
      label: "Edit",
      href: "/survey/Edit",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  // accordion custom header
  function AccordionCustomHeader({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log("totally custom!"));
    return (
      <div className="text-wrap title-wrap m-0" onClick={decoratedOnClick}>
        <h6>{children}</h6>
      </div>
    );
  }

  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Questions</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/survey/list", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row">
              <div className="accordion-wrap">
                <Accordion defaultActiveKey={param.questionIndex}>
                  {param.param.questions.map((item, index) => {
                    return (
                      <Accordion.Item eventKey={index} key={index}>
                        <div className="accordion-header">
                          <div className="accordion-header-actions">
                            <Accordion.Header className="accordion-header-rowreverse">
                              <AccordionCustomHeader eventKey={index}>
                                Question {index + 1}
                              </AccordionCustomHeader>
                            </Accordion.Header>
                            <div className="accordion-actions">
                              <div className="btn-wrap d-flex justify-content-end">
                                <Btn
                                  variant="link"
                                  icon={<span className="icon-Delete-2"></span>}
                                  style={"btn-icon btn-link-danger"}
                                  size={"sm"}
                                  onClick={() => {
                                    //console.log("test");
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <Accordion.Collapse
                          eventKey={index}
                          className="accordion-border-top pt-4"
                        >
                          <Form className="form-wrap">
                            <Row>
                              <Col lg={6} sm={12}>
                                <Input
                                  label="Question"
                                  placeholder="Name"
                                  defaultValue={item.title}
                                  type="input"
                                  id={"question" + index}
                                  onChange={(data) => {
                                    //console.log(data.target.value, "--- input");
                                  }}
                                />
                                <Select
                                  label="Question Type"
                                  id={"question_type" + index}
                                  data={QUESTION_TYPE}
                                  defaultValue={item.type}
                                  onChange={(val) => {
                                    setQuestionType(val);
                                  }}
                                />
                                <div className="form-fieldsadd-wrap">
                                  <Form.Group
                                    controlId={"add-options"}
                                    className={`form-group`}
                                  >
                                    <Form.Label>Options</Form.Label>
                                    <Row>
                                      <Col sm={10}>
                                        <Input
                                          placeholder="Name"
                                          type="input"
                                          id="question"
                                          onChange={(data) => {
                                            // console.log(
                                            //   data.target.value,
                                            //   "--- input"
                                            // );
                                          }}
                                        />
                                      </Col>
                                      <Col sm={2}>
                                        <Btn
                                          variant="link"
                                          icon={
                                            <span className="icon-Delete-2"></span>
                                          }
                                          style={"btn-icon btn-link-danger"}
                                          size={"sm"}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col sm={10}>
                                        <Input
                                          placeholder="Name"
                                          type="input"
                                          id="question"
                                          onChange={(data) => {
                                            // console.log(
                                            //   data.target.value,
                                            //   "--- input"
                                            // );
                                          }}
                                        />
                                      </Col>
                                      <Col sm={2}>
                                        <Btn
                                          variant="link"
                                          icon={
                                            <span className="icon-Delete-2"></span>
                                          }
                                          style={"btn-icon btn-link-danger"}
                                          size={"sm"}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col sm={12}>
                                        <Btn
                                          variant="outline-primary"
                                          label="Add Option"
                                          // onClick={() => {navigation('/survey/list', '')}}
                                        />
                                      </Col>
                                    </Row>
                                  </Form.Group>
                                </div>
                              </Col>
                              <Col lg={6} sm={12}>
                                <Check
                                  label="Is Mandatory"
                                  id={"is_mandatory" + index}
                                  data={MANDATORY_TYPE}
                                  // defaultValue={""}
                                  onChange={(val) => {
                                    //console.log(val, "--- checkValue");
                                  }}
                                  type="radio"
                                  inline={true}
                                />
                              </Col>
                            </Row>
                          </Form>
                        </Accordion.Collapse>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
            </div>
            <div className="screen-view-row">
              <Row>
                <Col lg={12} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Cancel"
                      onClick={() => {
                        navigation("/survey/list", "");
                      }}
                    />
                    <Btn
                      variant="primary"
                      size={"lg"}
                      label="Submit"
                      onClick={() => {}}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SurveyUserQuestions;
