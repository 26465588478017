/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// framework
import { Container, Row, Col, Form } from "react-bootstrap";

// styles

// components
import {
  Btn,
  BreadCrumb,
  Input,
  Check,
  InputFile,
  Editor,
} from "../../../components";

// utils
import { CATEGORY_DETAILS_OPTIONS } from "../../../utils/service";

const ServiceAndGuidelineSubcategoryEdit = () => {
  const location = useLocation();
  const [categoryDefaultValue, setCategoryDefaultValue] =
    useState("Attachment");
  const [selectDefaultValue, setSelectDefaultValue] = useState("Foster Youth");

  const paramVar = location.param;
  const [param, setParam] = useState(paramVar);

  const breadcrumb = [
    {
      label: "How To's",
      href: "/service/list",
      active: false,
    },
    {
      label: "Edit Subcategory",
      href: "/service/edit",
      active: true,
    },
  ];
  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Edit Subcategory</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/service/list", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row">
              <Form className="form-wrap">
                <Row>
                  <Col lg={6} sm={12}>
                    <Input
                      label="Category"
                      placeholder="Category"
                      type="input"
                      id="category"
                      maxLength={50}
                      showLength={true}
                      onChange={(data) => {
                        //console.log(data.target.value, "--- input");
                      }}
                    />
                    <Check
                      label="Category Details (Optional)"
                      id="category_details"
                      data={CATEGORY_DETAILS_OPTIONS}
                      // defaultValue={categoryDefaultValue}
                      type="radio"
                      inline={true}
                      onChange={(val) => {
                        setCategoryDefaultValue(val);
                      }}
                    />
                    {categoryDefaultValue === "Web Link" ? (
                      <Input
                        placeholder="Enter Link"
                        type="input"
                        id="web_link"
                        onChange={(data) => {
                          //console.log(data.target.value, "--- input");
                        }}
                      />
                    ) : (
                      <InputFile
                        placeholder="Attachment"
                        id="attachment"
                        caption="Accepted formats: .pdf"
                        accept="application/pdf"
                        onChange={(data) => {
                          //console.log(data.target.value, "--- input");
                        }}
                      />
                    )}
                  </Col>
                  <Col sm={12} md={12} lg={6}>
                    <Editor
                      label="Description (Optional)"
                      placeholder="Start Writing..."
                      onBlur={(data) => {
                        //console.log(data, "--- editor");
                      }}
                    />
                  </Col>
                </Row>
              </Form>
            </div>
            <div className="screen-view-row">
              <Row>
                <Col lg={12} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Cancel"
                      onClick={() => {
                        navigation("/service/category", "");
                      }}
                    />
                    <Btn
                      variant="primary"
                      size={"lg"}
                      label="Submit"
                      onClick={() => {}}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ServiceAndGuidelineSubcategoryEdit;
