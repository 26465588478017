import { call, put, takeLatest } from "redux-saga/effects";
import Actions from "../store/actions";
import API from "../../../utils/Api";
import toastr from "../../../utils/Toastr";

function bannerAPICall() {
  return API.get("/cms/banners");
}

function postBannerAPICall(bannerData) {
  return API.post("/cms/banners", bannerData);
}

function updateBannerDetailsById({ id, data }) {
  return API.put(`/cms/banners/${id}`, data);
}

function deleteBannerById(id) {
  return API._delete(`/cms/banners/${id}`);
}

function getBannerDetailsById(id) {
  return API.get(`/cms/banners/${id}`);
}

function postAttachmentApiCall(details) {
  return API.post("/cms/files/upload", details.data);
}

function getAttachmentApiCall(fileUuid) {
  return API.get(`/cms/files/${fileUuid}`);
}

function removeAttachmentApiCall(fileUuid) {
  return API._delete(`/cms/files/${fileUuid}`);
}

function* watchBannerAsync() {
  try {
    const details = yield call(bannerAPICall);
    yield put({ type: Actions.GET_BANNER_DATA_SUCCESS, details });
  } catch (error) {
    yield put({ type: Actions.GET_BANNER_DATA_FAIL, error });
    toastr.error(error.message);
  }
}

function* watchBannerDetailsSync(data) {
  try {
    const details = yield call(getBannerDetailsById, data.data);
    yield put({ type: Actions.GET_BANNER_DETAILS_SUCCESS, details });
  } catch (error) {
    yield put({ type: Actions.GET_BANNER_DETAILS_FAIL, error });
    toastr.error(error.message);
  }
}

function* watchBannerDeleteByIdSync(data) {
  try {
    yield call(deleteBannerById, data.id);
    yield put({ type: Actions.DELETE_BANNER_BY_ID_SUCCESS });
    toastr.success("Banner Deleted Successfully");
  } catch (error) {
    yield put({ type: Actions.DELETE_BANNER_BY_ID_FAIL, error });
    toastr.error(error.message);
  }
}

function* removeBannerImageAsync(data) {
  try {
    const details = yield call(removeAttachmentApiCall, data.data);
    yield put({ type: Actions.REMOVE_BANNER_IMAGE_SUCCESS });
    toastr.success(details.data.message);
  } catch (error) {
    yield put({ type: Actions.REMOVE_BANNER_IMAGE_FAIL, error });
    toastr.error(error.message);
  }
}

function* watchUpdateBannerDetailsSync(data) {
  try {
    const details = yield call(updateBannerDetailsById, data);
    yield put({
      type: Actions.UPDATE_BANNER_DATA_SUCCESS,
    });
    toastr.success("Banner Data Updated Successfully.");
  } catch (error) {
    yield put({
      type: Actions.UPDATE_BANNER_DATA_FAIL,
      data: error,
    });
    toastr.error(error.message);
  }
}

function* postBannerDataAsync(postData) {
  try {
    yield call(postBannerAPICall, postData.data);
    yield put({
      type: Actions.POST_BANNER_DATA_SUCCESS,
    });
    toastr.success("Banner Data Added Successfully.");
  } catch (error) {
    yield put({
      type: Actions.POST_BANNER_DATA_FAIL,
      data: error,
    });
    toastr.error(error.message);
  }
}

function* postCoverPictureAsync(PostData) {
  const details = yield call(postAttachmentApiCall, PostData);
  if (details) {
    const data = yield call(getAttachmentApiCall, details.data.uuid);
    try {
      yield put({ type: Actions.GET_COVERPICTURE_SUCCESS, data });
    } catch (error) {
      yield put({ type: Actions.GET_COVERPICTURE_FAIL, error });
    }
  }
  try {
    yield put({ type: Actions.POST_COVERPICTURE_SUCCESS, details });
  } catch (error) {
    yield put({ type: Actions.POST_COVERPICTURE_FAIL, error });
    console.log(error);
  }
}

function* postAttachmentAsync(postData) {
  const data = yield call(postAttachmentApiCall, postData);
  try {
    yield put({ type: Actions.POST_ATTACHMENT_DATA_SUCCESS, data });
  } catch (error) {
    yield put({ type: Actions.POST_ATTACHMENT_DATA_FAIL, error });
    console.log(error);
  }
}

const bannerSaga = [
  takeLatest(Actions.GET_BANNER_DATA, watchBannerAsync),
  takeLatest(Actions.GET_BANNER_DETAILS, watchBannerDetailsSync),
  takeLatest(Actions.POST_BANNER_DATA, postBannerDataAsync),
  takeLatest(Actions.DELETE_BANNER_BY_ID, watchBannerDeleteByIdSync),
  takeLatest(Actions.UPDATE_BANNER_DATA, watchUpdateBannerDetailsSync),
  takeLatest(Actions.POST_COVERPICTURE_DATA, postCoverPictureAsync),
  takeLatest(Actions.GET_COVERPICTURE_DATA, postCoverPictureAsync),
  takeLatest(Actions.POST_ATTACHMENT_DATA, postAttachmentAsync),
  takeLatest(Actions.REMOVE_BANNER_IMAGE, removeBannerImageAsync),
];

export default bannerSaga;
