import React, { useState } from "react";
import PropTypes from "prop-types";
import { fieldPropTypes } from "redux-form";
import { FormGroup, Label } from "reactstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import Error from "../../utils/Error";
import crossImg from "../../assets/images/cross.png";
import "./index.scss";

function GoogleLocations({
  id,
  meta,
  label,
  isRequired,
  placeholder,
  wrapperClass,
  onClearButton,
  fieldRef = null,
  inputFieldValue,
  onAddressChange,
  isDisabled = false,
  setGetAddress,
  isMultipleLocationInput = false,
}) {
  const [address, setAddressValue] = useState(
    inputFieldValue
      ? inputFieldValue.cityName && inputFieldValue.stateCode
        ? `${inputFieldValue.cityName}, ${inputFieldValue.stateCode}`
        : inputFieldValue
      : ""
  );

  // useEffect(() => {
  //   if (address && inputFieldValue) {
  //     if (inputFieldValue.cityName && inputFieldValue.stateCode) {
  //       setAddressValue(
  //         `${inputFieldValue.cityName}, ${inputFieldValue.stateCode}`
  //       );
  //     } else {
  //       setAddressValue(inputFieldValue.cityName);
  //     }
  //   }
  // }, [inputFieldValue, address]);

  function handleChange(address) {
    setAddressValue(address);
    setGetAddress(address)
  }
  function getAddressDetails(details) {
    geocodeByAddress(details)
      .then((results) => {
        let _address = _formatLocationDetails(results[0]);
        if (!_address.address.hasOwnProperty("zipcode")) {
          fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${_address.lat},${_address.lng}&key=AIzaSyCp_y41Oy6fQNBUy66ppITF3EHqla03-Vk`
          )
            .then((details) => details.json())
            .then((details) => {
              const detail = details.results[0];
              const address = detail.address_components;
              const zipcode = address.find(
                ({ types }) => types[0] === "postal_code"
              );
              if (zipcode) {
                _address.address.zipcode = zipcode.long_name;
              }
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
        if (!isMultipleLocationInput) {
          setAddressValue(
            `${_address.address.addressLine1}, ${_address.address.stateCode}`
          );
        } else {
          setAddressValue("");
        }
        onAddressChange(_address);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }
  function _formatLocationDetails(details) {
    let AddressLine1 = [];
    const AddressDetails = {};
    details.address_components.map((data) => {
      let type = data.types[0];
      switch (type) {
        case "street_number":
        case "neighborhood":
        case "route":
        case "sublocality_level_2":
        case "sublocality":
        // case "premise":
        case "locality":
        case "establishment":
        case "colloquial_area":
        case "administrative_area_level_3":
          AddressLine1.push(data.long_name);
          AddressDetails.cityName = data.long_name;
          break;
        case "administrative_area_level_1":
          AddressDetails.stateName = data.long_name;
          AddressDetails.stateCode = data.short_name;
          break;
        case "country":
          AddressDetails.country = data.short_name;
          break;
        case "postal_code":
          AddressDetails.zipcode = data.long_name;
          break;
        default:
          break;
      }
      return null;
    });

    const lat = details.geometry.location.lat();
    const lng = details.geometry.location.lng();
    AddressDetails.addressLine1 =
      AddressLine1.length > 0 ? AddressLine1.join(",") : "";

    return {
      lat,
      lng,
      address: { ...AddressDetails },
    };
  }
  function onClear() {
    setAddressValue("");
    onClearButton();
  }
  const searchOptions = {
    // types: ["(cities)"],
    country: ["us", "usa"],
    componentRestrictions: { country: "us" },
  };

  return (
    <div className="address">
      <FormGroup className={wrapperClass || ""}>
        <Label className="d-block">
          {label && <span className="label-name"> {label} </span>}
          {isRequired && label ? <span className="error-msg">*</span> : null}
          <PlacesAutocomplete
            id={id}
            value={address}
            required={isRequired}
            onChange={handleChange}
            placeholder={placeholder}
            searchOptions={searchOptions}
            onSelect={(details) => getAddressDetails(details)}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <input
                  {...getInputProps({
                    ref: fieldRef,
                    autoFocus: false,
                    disabled: isDisabled,
                    placeholder: `${placeholder}`,
                    className: meta.error
                      ? "location-search-input invalid-input"
                      : "location-search-input",
                  })}
                />
                <div
                  className={
                    suggestions.length > 0
                      ? "autocomplete-dropdown-container suggestions-container"
                      : "autocomplete-dropdown-container"
                  }
                >
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion, index) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                        key={index}
                      >
                        <div className="suggestion">
                          <span className="autocomplete-icon icon-localities"></span>
                          {suggestion.description}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </Label>
        {address && !isMultipleLocationInput && (
          <div className="close-icon" onClick={(_) => onClear()}>
            <img src={crossImg} alt="close-icon" />
          </div>
        )}
        {meta && address === "" ? (
          <Error show={meta.touched && !!meta.error} text={meta.error} />
        ) : null}
      </FormGroup>
    </div>
  );
}

GoogleLocations.propTypes = {
  ...fieldPropTypes,
  label: PropTypes.string,
};

export default GoogleLocations;
