import React from "react";
import { useHistory } from "react-router-dom";
// framework
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
// components
import { Btn, BreadCrumb, DNDSequence } from "../../../components";

const BannerContentSequence = ({ bannerList }) => {
  const breadcrumb = [
    {
      label: "Banners",
      href: "/banners/list",
      active: false,
    },
    {
      label: "Edit Sequence",
      href: "/banner/content-sequence",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  return (
    <div className="screen-wrap">
      <Container>
        <BreadCrumb data={breadcrumb}></BreadCrumb>
        <div className="screen-view-wrap">
          <div className="screen-view-row">
            <Row>
              <Col lg={6} sm={12} className="d-flex">
                <div className="screen-title-wrap d-flex align-self-center">
                  <div className="text-wrap">
                    <p className="subtitle1">Edit Sequence</p>
                  </div>
                </div>
              </Col>
              <Col lg={6} sm={12}>
                <div className="btn-wrap d-flex justify-content-end">
                  <Btn
                    variant="secondary"
                    size={"lg"}
                    label="Go Back"
                    onClick={() => navigation("/banners/list", "")}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="screen-view-row">
            <div className="screen-details-row">
              <Row>
                <Col sm={12}>
                  <div className="text-wrap subtitle-wrap">
                    <p className="subtitle2">
                      Drag & Drop The Following Items To Change Their Sequence
                    </p>
                    <DNDSequence
                      data={bannerList}
                      onChange={(data) => console.log(data, "on change")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="screen-view-row">
            <Row>
              <Col lg={12} sm={12}>
                <div className="btn-wrap d-flex justify-content-end">
                  <Btn
                    variant="secondary"
                    size={"lg"}
                    label="Cancel"
                    onClick={() => {
                      navigation("/banners/list", "");
                    }}
                  />
                  <Btn
                    variant="primary"
                    size={"lg"}
                    label="Submit"
                    onClick={() => {}}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ banner }) => banner;
export default connect(mapStateToProps, null)(BannerContentSequence);
