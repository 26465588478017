import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Container, Nav, Row, Col, NavDropdown } from "react-bootstrap";
import { GLOBAL_IMAGES } from "../../assets/images/global-images";
import "./nav-header.scss";
import { Navigate } from "react-big-calendar";
import { redirectToLogin, getUserDetails } from "../../utils/Authentication";

const NavHeader = (props) => {
  const [navTitle, setNavTitle] = useState("");
  const [navData] = useState(props.navData);
  const [isHeaderActive, setIsHeaderActive] = useState(false);

  // navigation
  const location = useLocation();
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  useEffect(() => {
    let navName = location.pathname.split("/")[1];
    if (navName === "" || navName === "components") {
      setIsHeaderActive(false);
    } else setIsHeaderActive(true);
    let navObj = navData.find((obj) => obj.nav === navName);
    if (navObj !== undefined) {
      setNavTitle(navObj.title);
    } else {
      setNavTitle("Home");
    }
  }, [location, navData]);

  const handleNavChange = (eventKey) => {
    if (eventKey !== "Logout") {
      let navObj = navData.find((obj) => obj.title === eventKey);
      if (navObj.title === "Events") {
        navigation("/events/list", "");
      }
      if (navObj.title === "Push Notifications") {
        navigation("/notifications/list");
      } else if (navObj.title === "Home") {
        navigation("/home");
      } else {
        navigation(navObj.link, "");
      }
    } else {
      redirectToLogin();
      localStorage.clear();
    }
    setNavTitle(eventKey);
  };

  const loginUser = getUserDetails();

  useEffect(() => {
    window.localStorage.setItem("userFirstName", loginUser.firstName);
    window.localStorage.setItem("userLastName", loginUser.lastName);
  }, [loginUser.firstName, loginUser.lastName]);

  const userFirstName = window.localStorage.getItem("userFirstName");
  const userLastName = window.localStorage.getItem("userLastName");

  return (
    isHeaderActive && (
      <header>
        <Nav activeKey={navTitle} onSelect={handleNavChange}>
          <Container>
            <Row>
              <Col xs={3}>
                <div className="logo-wrap">
                  <Link to="/home">
                    <img src={GLOBAL_IMAGES.Logo} alt="logo"></img>
                  </Link>
                </div>
              </Col>
              <Col xs={9}>
                <div className="nav-wrap">
                  <NavDropdown title={`${userFirstName} ${userLastName}`}>
                    {/* <NavDropdown.Item eventKey="Change Password">
                      Change Password
                    </NavDropdown.Item> */}
                    <NavDropdown.Item eventKey="Logout">
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>

                  <div className="navTitleStyle">
                    <NavDropdown title={navTitle} className="dropdown-nav-wrap">
                      {props.navData.map((item, index) => {
                        return (
                          item.isActive && (
                            <NavDropdown.Item key={index} eventKey={item.title}>
                              {item.title}
                            </NavDropdown.Item>
                          )
                        );
                      })}
                    </NavDropdown>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Nav>
      </header>
    )
  );
};

/**
 * logo: provide logo source
 * navData: show list of naviations
 */

NavHeader.propTypes = {
  logo: PropTypes.string,
  navData: PropTypes.array,
};

NavHeader.defaultProps = {
  logo: GLOBAL_IMAGES.Logo,
  navData: null,
};

export default NavHeader;
