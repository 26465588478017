import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// framework
import { Container, Row, Col } from "react-bootstrap";

// styles

// components
import { Btn, BreadCrumb, Legend } from "../../../components";

// utils
import { CONTACT_DETAILS } from "../../../utils/service";

const ContactDetails = () => {
  // const location = useLocation();

  // const paramVar = location.param;
  const [param] = useState(CONTACT_DETAILS);

  const breadcrumb = [
    {
      label: "Contact Us",
      href: "/contact/details",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Contact Us</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/home", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row no-border">
              <Legend
                label="Details"
                actions={
                  <Btn
                    label="Edit"
                    variant="outline-primary"
                    size={"sm"}
                    onClick={() => navigation("/contact/edit", param)}
                  />
                }
              />
            </div>
            <div className="screen-view-row">
              <div className="screen-details-row">
                <Row>
                  <Col sm={6}>
                    <div className="text-wrap subtitle-wrap">
                      <h6 className="text-uppercase">Address</h6>
                      <p className="overline">{param.address}</p>
                    </div>
                    <div className="text-wrap subtitle-wrap">
                      <h6 className="text-uppercase">Email Address</h6>
                      <p className="overline">{param.email}</p>
                    </div>
                    <div className="text-wrap subtitle-wrap">
                      <h6 className="text-uppercase">Description</h6>
                      <p className="overline">{param.description}</p>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="text-wrap subtitle-wrap">
                      <h6 className="text-uppercase">Phone Number</h6>
                      <p className="overline">{param.phone}</p>
                    </div>
                    <div className="text-wrap subtitle-wrap">
                      <h6 className="text-uppercase">Social Networks</h6>
                      <p className="overline">
                        Facebook :{" "}
                        <a
                          href={param.facebook}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {param.facebook}
                        </a>
                      </p>
                      <p className="overline">
                        Instagram :{" "}
                        <a
                          href={param.instagram}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {param.instagram}
                        </a>
                      </p>
                      <p className="overline">
                        Linked In :{" "}
                        <a
                          href={param.linkdein}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {param.linkdein}
                        </a>
                      </p>
                      <p className="overline">
                        Twitter :{" "}
                        <a
                          href={param.twitter}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {param.twitter}
                        </a>
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContactDetails;
