/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import { Form, Label } from "reactstrap";
import { Row, Col } from "react-bootstrap";
import ErrorStore from "../../utils/ErrorStore";
import { required } from "../../utils/Validator";
import { reduxForm, Field, SubmissionError } from "redux-form";
import html2plaintext from "html2plaintext";
import * as dispatcher from "../push-notifications/store/Dispatchers.js";

import { Btn, Input, Dialog, Editor, Check } from "../../components";
import { NOTIFICATION_OPTIONS } from "../../utils/service";
import { connect } from "react-redux";
import { useEffect } from "react";
import { useMemo } from "react";

let NotificationForm = ({
  formTitle,
  navigation,
  handleSubmit,
  dispatchPostNotificationData,
  postNotificationDataSuccess,
  getDetails,
  notifyId,
  dispatchUpdateNotificationData,
  updateNotificationDataSuccess,
  getSettingsData,
  dispatchResetState,
}) => {
  const [notificationForm, setNotificationForm] = useState("");
  const [modalLocateMap, setModalLocateMap] = useState(false);
  const [categoryDefaultValue, setCategoryDefaultValue] = useState("all");

  const [title, setTitle] = useState(
    getDetails ? getDetails.data.title.en : ""
  );
  const [description, setDescription] = useState(
    getDetails ? getDetails.data.plainDescription.en : ""
  );

  const plainDescription = html2plaintext(description);

  useEffect(() => {
    if (postNotificationDataSuccess) {
      navigation("/notifications/list");
      dispatchResetState();
    }
    if (updateNotificationDataSuccess) {
      navigation(`/notifications/details/${notifyId}`);
      dispatchResetState();
    }
  }, [
    dispatchResetState,
    navigation,
    notifyId,
    postNotificationDataSuccess,
    updateNotificationDataSuccess,
  ]);

  function _validate(details) {
    const errorInstance = ErrorStore();
    errorInstance.add("Title", title, [required]);
    errorInstance.add("Description", description, [required]);
    return errorInstance.get();
  }

  function _handleSubmit(details) {
    let errors = null;
    let notificationFormError = _validate(details);
    if (notificationFormError) {
      errors = { ...(errors || {}), ...notificationFormError };
    }
    if (errors) {
      throw new SubmissionError({ ...errors });
    } else {
      let _data = {
        title: title,
        description: `<!DOCTYPE HTML> <html><head><meta charset="utf-8" /><title></title></head> <body>${description}</body> </html>`,
        plainDescription: plainDescription,
        notificationFor: categoryDefaultValue,
      };
      if (getDetails) {
        dispatchUpdateNotificationData(_data, notifyId);
      } else {
        dispatchPostNotificationData(_data);
      }
    }
  }

  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    if (title || description) {
      setIsDisable(false);
    }
  }, [description, title]);

  const clickDisable = () => {
    setTimeout(() => {
      setIsDisable(true);
    }, 10);
  };

  return (
    <div className="banner-form-container">
      <div className="screen-view-row">
        <Row>
          <Col lg={6} sm={12} className="d-flex">
            <div className="screen-title-wrap d-flex align-self-center">
              <div className="text-wrap">
                <p className="subtitle1">{formTitle} Notification</p>
              </div>
            </div>
          </Col>
          <Col lg={6} sm={12}>
            <div className="btn-wrap d-flex justify-content-end">
              <Btn
                size={"lg"}
                label="Go Back"
                variant="secondary"
                onClick={() => navigation("/notifications/list")}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Form
        id="notification-form"
        onSubmit={handleSubmit(_handleSubmit)}
        className="form-wrap"
        name="notificationForm"
      >
        <Row>
          <Col lg={6} sm={12}>
            {/* <Label className="label-name" for="notification_form">
              Enter Title
              <span className="error-msg"> * </span>
            </Label> */}
            <Row>
              <Col lg="8" md="9" sm="9">
                <Label className="label-name" for="banner_name">
                  Notification Title
                  <span className="error-msg"> * </span>
                </Label>
              </Col>
              <Col lg="4" md="3" sm="3">
                <p className="title">
                  {100 - title.length} out of 100 characters left
                </p>
              </Col>
            </Row>
            <Field
              name="Title"
              component={Input}
              type="text"
              maxLength={100}
              defaultValue={title}
              id="notification_form"
              showLength={true}
              placeholder="Enter Notification Name"
              onchange={(e) => setTitle(e.target.value)}
            />

            {/* <Label className="label-name" for="notification_form">
              Description
              <span className="error-msg"> * </span>
            </Label> */}
            <Field
              name="Description"
              id="description"
              label="Description"
              span="*"
              component={Editor}
              defaultValue={description}
              placeholder="Start Writing..."
              onBlur={(data) => {
                setDescription(data);
              }}
              getDescriptionValue={setDescription}
            ></Field>

            {/* <Editor
              label="Description"
              defaultValue={description}
              placeholder="Start Writing..."
              // onBlur={(data) => {
              //   setDescription(data);
              // }}
              getDescriptionValue={setDescription}
            ></Editor> */}
          </Col>
          <Col lg={6} sm={12}>
            <Label className="label-name" for="notification_form">
              Notification Options
              <span className="error-msg"> * </span>
            </Label>
            <Check
              // label="Notification Options*"
              defaultValue={categoryDefaultValue}
              id="notification_options"
              data={getSettingsData?.data.userOption}
              apiRadio={true}
              type="radio"
              // defaultChecked={true}
              onChange={(e) => {
                setCategoryDefaultValue(e);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12} sm={12}>
            <div className="btn-wrap d-flex justify-content-end">
              <Btn
                variant="secondary"
                size={"lg"}
                label="Cancel"
                onClick={() => {
                  navigation("/notifications/list", "");
                }}
              />
              <Btn
                variant="primary"
                size={"lg"}
                onClick={clickDisable}
                label="Submit"
                type="submit"
                disabled={isDisable === true}
              />
            </div>
          </Col>
        </Row>
      </Form>
      <Dialog
        title={"Map"}
        description={
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.618692267389!2d72.8351312153137!3d19.12437675541878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9d8e30e817f%3A0xa53c0adc9279315d!2sDamacloid%20technologies%20private%20limited!5e0!3m2!1sen!2sin!4v1645602980322!5m2!1sen!2sin"
            allowfullscreen=""
            loading="lazy"
            className="w-100"
            style={{ height: "350px" }}
          ></iframe>
        }
        centered={true}
        show={modalLocateMap}
        size={"lg"}
        close={(val) => {
          setModalLocateMap(val);
        }}
        actions={
          <div className="btn-wrap d-flex justify-content-end">
            <Btn
              variant="primary"
              label="Ok"
              onClick={() => {
                setModalLocateMap(false);
              }}
            />
          </div>
        }
      />
    </div>
  );
};

NotificationForm = reduxForm({
  form: "notificationForm",
  enableReinitialize: true,
})(NotificationForm);

const MapStateToProps = ({ notification }) => {
  return {
    postNotificationDataSuccess: notification.postNotificationDataSuccess,
    updateNotificationDataSuccess: notification.updateNotificationDataSuccess,
  };
};

export default connect(MapStateToProps, dispatcher)(NotificationForm);
