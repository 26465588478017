import CryptoJS from "crypto-js"

export const encryption = (data) => {
    const passwordSecret = 'sakfbak'

    const encryptedPassword = CryptoJS.AES.encrypt(
      data, passwordSecret
    ).toString()

    return encryptedPassword
}

