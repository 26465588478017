/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// framework
import { Container, Row, Col } from "react-bootstrap";

// styles

// components
import {
  Btn,
  Paginator,
  FeatureContentCard,
  BreadCrumb,
  Select,
  Filters,
} from "../../../components";

// utils
import {
  SORTBY_OPTIONS,
  SURVEY_FILTER_OPTIONS,
  SURVEY_LIST,
} from "../../../utils/service";

const SurveyList = () => {
  const location = useLocation();

  const paramVar = location.param;
  const [param, setParam] = useState(paramVar);
  const [sortByValue, setSortByValue] = useState("");

  const breadcrumb = [
    {
      label: "Survey",
      href: "/survey/list",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };
  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">
                        Survey <span className="caption">( 1 - 9 of 9 )</span>
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/home", "")}
                    />
                    <Btn
                      variant="primary"
                      size={"lg"}
                      label="Add New"
                      onClick={() => {
                        navigation("/survey/add", "");
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap align-self-center">
                      <p className="caption">Sort By</p>
                    </div>
                    <Select
                      id="sort_by"
                      data={SORTBY_OPTIONS}
                      defaultValue={sortByValue}
                      onChange={(val) => {
                        setSortByValue(val);
                      }}
                    />
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="d-flex justify-content-end">
                    <Paginator />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row">
              <Row>
                <Col lg={3} sm={12}>
                  <div className="accordion-wrap">
                    <div className="accordion-title">
                      <div className="text-wrap">
                        <p className="subtitle1">Filters</p>
                      </div>
                    </div>
                  </div>
                  <Filters
                    data={SURVEY_FILTER_OPTIONS}
                    onChange={(val, type) => {
                      //console.log("val:", val, ":filterTypeFor:" + type);
                    }}
                  />
                </Col>
                <Col lg={9} sm={12}>
                  <div className="screen-view-left-divider">
                    <div className="d-flex flex-wrap">
                      {SURVEY_LIST.map((item, index) => {
                        return (
                          <div className="feature-content-row" key={index}>
                            <FeatureContentCard
                              title={item.title}
                              description={
                                <React.Fragment>
                                  <div className="text-wrap">
                                    <p className="subtitle2">
                                      <strong>Survey Code : </strong>{" "}
                                      {item.code}
                                    </p>
                                  </div>
                                  <div className="text-wrap">
                                    <p className="subtitle2 title">
                                      <strong>Created : </strong>{" "}
                                      {item.createdOn}
                                    </p>
                                  </div>
                                  <div className="text-wrap d-flex">
                                    <p className="subtitle2 title">
                                      <strong>Service Type : </strong>{" "}
                                      {item.type}
                                    </p>
                                  </div>
                                  <div className="text-wrap d-flex">
                                    <p className="subtitle2 title">
                                      <strong>Survey Audience: </strong>{" "}
                                      {item.audience}
                                    </p>
                                  </div>
                                </React.Fragment>
                              }
                              actions={
                                <React.Fragment>
                                  <a
                                    href={item.shareUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <Btn
                                      variant="secondary"
                                      icon={
                                        <span className="icon-export-outline"></span>
                                      }
                                    />
                                  </a>
                                  <Btn
                                    variant="secondary"
                                    icon={<span className="icon-Edit"></span>}
                                    onClick={() => {
                                      navigation("/survey/edit", "");
                                    }}
                                  />
                                  <Btn
                                    variant="secondary"
                                    icon={<span className="icon-View"></span>}
                                    onClick={() => {
                                      navigation("/survey/details", item);
                                    }}
                                  />
                                </React.Fragment>
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div className="d-flex justify-content-end">
                      <Paginator />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SurveyList;
