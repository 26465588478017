/* eslint-disable react/style-prop-object */
import React from "react";
import { useHistory } from "react-router-dom";
// framework
import { Container, Row, Col } from "react-bootstrap";
// styles
// components
import {
  Btn,
  Paginator,
  FeatureContentCard,
  BreadCrumb,
} from "../../../components";

// utils
import { USER_LIST } from "../../../utils/service";

const UserList = () => {
  // const location = useLocation();
  // const paramVar = location.param
  // const [param, setParam] = useState(paramVar);
  // const [sortByValue, setSortByValue] = useState("");

  const breadcrumb = [
    {
      label: "Users",
      href: "/user/list",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  return (
    <React.Fragment>
      {/* <NavHeader /> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">
                        Total Entries
                        <span className="caption">( 1 - 9 of 9 )</span>
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/home", "")}
                    />
                    <Btn
                      variant="primary"
                      size={"lg"}
                      label="Add New"
                      onClick={() => {
                        navigation("/user/add", "");
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row">
              <Row>
                <Col lg={12} sm={12}>
                  <div className="d-flex justify-content-end">
                    <Paginator />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row">
              <Row>
                <Col sm={12}>
                  <div className="d-flex flex-wrap">
                    {USER_LIST.map((item, index) => {
                      return (
                        <div className="feature-content-row" key={index}>
                          <FeatureContentCard
                            title={item.firstName + " " + item.lastName}
                            description={
                              <React.Fragment>
                                <div className="text-wrap">
                                  <p className="subtitle2">
                                    <strong>Assigned Categories: </strong>
                                    {item.assigned.map((item, index) => {
                                      return item.category + ",";
                                    })}
                                  </p>
                                </div>
                              </React.Fragment>
                            }
                            actions={
                              <React.Fragment>
                                <Btn
                                  variant="secondary"
                                  icon={<span className="icon-Edit"></span>}
                                  onClick={() => {
                                    navigation("/user/edit", item);
                                  }}
                                />
                                <Btn
                                  variant="secondary"
                                  icon={
                                    <span className="icon-Delete-2 danger"></span>
                                  }
                                  style={"btn-link-danger"}
                                  // onClick={() => { }}
                                />
                              </React.Fragment>
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="d-flex justify-content-end">
                    <Paginator />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserList;
