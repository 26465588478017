const Actions = {
  RESET_STATE: " RESET_STATE",

  GET_EVENTS_DATA: "GET_EVENTS_DATA",
  GET_EVENTS_DATA_SUCCESS: "GET_EVENTS_DATA_SUCCESS",
  GET_EVENTS_DATA_FAIL: "GET_EVENTS_DATA_FAIL",

  GET_EVENTS_DATA_BY_ID: " GET_EVENTS_DATA_BY_ID",
  GET_EVENTS_DATA_BY_ID_SUCCESS: "GET_EVENTS_DATA_BY_ID_SUCCESS",
  GET_EVENTS_DATA_BY_ID_FAIL: "GET_EVENTS_DATA_BY_ID_FAIL",

  //Post event data actions
  POST_EVENT_DATA: "POST_EVENT_DATA",
  POST_EVENT_DATA_SUCCESS: "POST_EVENT_DATA_SUCCESS",
  POST_EVENT_DATA_FAIL: "POST_EVENT_DATA_FAIL",

  PUT_EVENT_DATA: "PUT_EVENT_DATA",
  PUT_EVENT_DATA_SUCCESS: "PUT_EVENT_DATA_SUCCESS",
  PUT_EVENT_DATA_FAIL: "PUT_EVENT_DATA_FAIL",

  DELETE_EVENT_DATA: "DELETE_EVENT_DATA",
  DELETE_EVENT_DATA_SUCCESS: "DELETE_EVENT_DATA_SUCCESS",
  DELETE_EVENT_DATA_FAIL: "DELETE_EVENT_DATA_FAIL",
};

export default Actions;
