import Actions from "./Actions";

const defaultState = {
  getNotificationDataLoading: false,
  getNotificationDataSuccess: false,
  getNotificationDataFail: false,
  getNotificationData: null,

  getNotificationByIdLoading: false,
  getNotificationByIdSuccess: false,
  getNotificationByIdFail: false,
  getNotificationByIdData: null,

  postNotificationDataLoading: false,
  postNotificationDataSuccess: false,
  postNotificationDataFail: false,

  updateNotificationDataLoading: false,
  updateNotificationDataSuccess: false,
  updateNotificationDataFail: false,

  deleteNotificationDataLoading: false,
  deleteNotificationDataSuccess: false,
  deleteNotificationDataFail: false,
};

const notificationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case Actions.RESET_STATE:
      return {
        ...defaultState,
      };
    case Actions.GET_NOTIFICATION_DATA:
      return {
        ...state,
        getNotificationDataLoading: true,
        getNotificationDataSuccess: false,
        getNotificationDataFail: false,
      };

    case Actions.GET_NOTIFICATION_DATA_SUCCESS:
      return {
        ...state,
        getNotificationDataLoading: false,
        getNotificationDataSuccess: true,
        getNotificationDataFail: false,
        getNotificationData: action.details,
      };

    case Actions.GET_NOTIFICATION_DATA_FAIL:
      return {
        ...state,
        getNotificationDataLoading: false,
        getNotificationDataSuccess: false,
        getNotificationDataFail: true,
      };

    case Actions.GET_NOTIFICATION_BY_ID:
      return {
        ...state,
        getNotificationByIdLoading: true,
        getNotificationByIdSuccess: false,
        getNotificationByIdFail: false,
      };

    case Actions.GET_NOTIFICATION_BY_ID_SUCCESS:
      return {
        ...state,
        getNotificationByIdLoading: false,
        getNotificationByIdSuccess: true,
        getNotificationByIdFail: false,
        getNotificationByIdData: action.details,
      };

    case Actions.GET_NOTIFICATION_BY_ID_FAIL:
      return {
        ...state,
        getNotificationByIdLoading: false,
        getNotificationByIdSuccess: false,
        getNotificationByIdFail: true,
      };

    case Actions.POST_NOTIFICATION_DATA:
      return {
        ...state,
        postNotificationDataLoading: true,
        postNotificationDataSuccess: false,
        postNotificationDataFail: false,
      };

    case Actions.POST_NOTIFICATION_DATA_SUCCESS:
      return {
        ...state,
        postNotificationDataLoading: false,
        postNotificationDataSuccess: true,
        postNotificationDataFail: false,
      };

    case Actions.POST_NOTIFICATION_DATA_FAIL:
      return {
        ...state,
        postNotificationDataLoading: false,
        postNotificationDataSuccess: false,
        postNotificationDataFail: true,
      };

    case Actions.UPDATE_NOTIFICATION_DATA:
      return {
        ...state,
        updateNotificationDataLoading: true,
        updateNotificationDataSuccess: false,
        updateNotificationDataFail: false,
      };

    case Actions.UPDATE_NOTIFICATION_DATA_SUCCESS:
      return {
        ...state,
        updateNotificationDataLoading: false,
        updateNotificationDataSuccess: true,
        updateNotificationDataFail: false,
      };

    case Actions.UPDATE_NOTIFICATION_DATA_FAIL:
      return {
        ...state,
        updateNotificationDataLoading: false,
        updateNotificationDataSuccess: false,
        updateNotificationDataFail: true,
      };

    case Actions.DELETE_NOTIFICATION_DATA:
      return {
        ...state,
        deleteNotificationDataLoading: true,
        deleteNotificationDataSuccess: false,
        deleteNotificationDataFail: false,
      };

    case Actions.DELETE_NOTIFICATION_DATA_SUCCESS:
      return {
        ...state,
        deleteNotificationDataLoading: false,
        deleteNotificationDataSuccess: true,
        deleteNotificationDataFail: false,
      };

    case Actions.DELETE_NOTIFICATION_DATA_FAIL:
      return {
        ...state,
        deleteNotificationDataLoading: false,
        deleteNotificationDataSuccess: false,
        deleteNotificationDataFail: true,
      };

    default:
      return {
        ...state,
      };
  }
};

export default notificationReducer;
