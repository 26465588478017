import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { getEventsData } from "../store/disptahcers";
import { connect } from "react-redux";

// framework
import { Container, Row, Col } from "react-bootstrap";

// plugin
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import moment from "moment";

// components
import { Btn, BreadCrumb } from "../../../components";

// utils
import * as dates from "../../../utils/dates";
import { useEffect } from "react";
import LoadingSpinner from "../../../components/LoadingSpinner";

const localizer = momentLocalizer(moment);

const EventsCalendarView = ({
  eventData,
  getEventsData,
  eventDataSuccess,
  eventDataLoading,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const breadcrumb = [
    {
      label: "Event Calendar",
      href: "/events",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  useEffect(() => {
    getEventsData();
  }, [getEventsData]);

  useEffect(() => {
    if (eventDataLoading) {
      setIsLoading(true);
    } else if (eventDataSuccess) {
      setIsLoading(false);
    }
  }, [eventDataLoading, eventDataSuccess]);

  const modifiedData = eventData
    ? eventData.data.list.map((value) => {
        return {
          allDay: "",
          id: value ? value.id : "",
          start: new Date(value.startDate),
          end: new Date(value.endDate),
          title: value.title ? value.title.en : "",
        };
      })
    : null;

  return (
    <React.Fragment>
      {/* <NavHeader /> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Event Calendar</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/home", "")}
                    />
                    <Btn
                      variant="primary"
                      size={"lg"}
                      label="Add New"
                      onClick={() => navigation("/events/add", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            {isLoading ? (
              <LoadingSpinner></LoadingSpinner>
            ) : modifiedData !== null ? (
              <div className="screen-view-row">
                <Row>
                  <Col sm={12}>
                    <div className="calendar-wrap">
                      <Calendar
                        // selectable
                        events={modifiedData}
                        // views={allViews}
                        defaultView={
                          window.innerWidth >= 1024 ? Views.MONTH : Views.DAY
                        }
                        // views={{ month: true, week: true, work_week: true, day: true, agenda: true }}
                        views={{ month: true, day: true }}
                        // step={60}
                        // showMultiDayTimes
                        max={dates.add(
                          dates.endOf(new Date(2021, 17, 1), "day"),
                          -1,
                          "hours"
                        )}
                        defaultDate={new Date()}
                        /* components={{
												timeSlotWrapper: ColoredDateCellWrapper,
											}} */
                        localizer={localizer}
                        onSelectEvent={(event) =>
                          navigation(`/events/details/${event.id}`, event)
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            ) : null}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ event }) => {
  return {
    eventData: event.eventData,
    eventDataSuccess: event.eventDataSuccess,
    eventDataLoading: event.eventDataLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEventsData: () => dispatch(getEventsData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsCalendarView);
