/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as dispathcer from "../store/Dispatchers.js";
import moment from "moment";

// framework
import { Container, Row, Col } from "react-bootstrap";

// styles

// components
import { Btn, BreadCrumb, Legend, Dialog } from "../../../components";
import { connect } from "react-redux";
import { useEffect } from "react";

const PushNotificationDetails = ({
  getNotificationByIdData,
  dispatchNotificationById,
  dispatchDeleteNotificationData,
  deleteNotificationDataSuccess,
}) => {
  const location = useLocation();

  const paramVar = location.param;
  const [param, setParam] = useState(paramVar);
  const [modalDelete, setModalDelete] = useState(false);
  const [getId, setGetId] = useState("");

  const breadcrumb = [
    {
      label: "Push Notification",
      href: "/notifications/list",
      active: false,
    },
    {
      label: "NOTIFICATION DETAILS",
      href: "/push-notification/details",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  useEffect(() => {
    const pathname = location.pathname.split("/");
    const notificationId = parseInt(pathname[3]);
    setGetId(notificationId);
    dispatchNotificationById(notificationId);
  }, [dispatchNotificationById, location.pathname]);

  const notificationData = getNotificationByIdData?.data;

  useEffect(() => {
    if (deleteNotificationDataSuccess) {
      navigation("/notifications/list");
    }
  });

  const onDelete = () => {
    dispatchDeleteNotificationData(getId);
  };

  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Push Notification Detail</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/notifications/list", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>

            <div className="screen-view-row">
              <div className="screen-details-row">
                <Row>
                  <Col sm={12}>
                    <div className="text-wrap title-wrap title-underline">
                      <h5> {notificationData?.title.en} </h5>
                    </div>
                  </Col>
                  {/* <Col sm={0}>
                    <div>
                      <Btn
                        label="Edit"
                        variant="outline-primary"
                        size={"sm"}
                        onClick={() =>
                          navigation(
                            `/push-notification/edit/${notificationData?.id}`
                          )
                        }
                      />
                    </div>
                  </Col> */}
                </Row>
              </div>
              <div className="screen-details-row">
                <Row>
                  <Col lg={4} sm={12}>
                    <div className="text-wrap subtitle-wrap">
                      <p className="subtitle1">CREATED</p>
                      <p>
                        {" "}
                        {moment(notificationData?.createdOn).format(
                          "MM-DD-YYYY, h:mm a"
                        )}{" "}
                      </p>
                    </div>
                  </Col>
                  <Col lg={4} sm={12}>
                    <div className="text-wrap subtitle-wrap">
                      <p className="subtitle1">STATUS</p>
                      <p className="subtitle3">
                        {notificationData?.status} On{" "}
                        {moment(notificationData?.sentOn).format(
                          "MM-DD-YYYY, h:mm a"
                        )}{" "}
                      </p>
                    </div>
                  </Col>
                  <Col lg={4} sm={12}>
                    <div className="text-wrap subtitle-wrap">
                      <p className="subtitle1">NOTIFICATION FOR</p>
                      <p className="subtitle3">
                        {" "}
                        {notificationData?.notificationFor
                          .replace(/([A-Z])/g, " $1")
                          // uppercase the first character
                          .replace(/^./, function (str) {
                            return str.toUpperCase();
                          })}{" "}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="screen-details-row">
                <Row>
                  <Col sm={12}>
                    <div className="text-wrap subtitle-wrap">
                      <p className="subtitle1">NOTE</p>
                      <p>
                        {notificationData?.plainDescription.en}{" "}
                        {/* <a
                          target="_blank"
                          href="https://www.leegov.com/resources/leetv"
                        >
                          LeeTV,{" "}
                        </a>{" "}
                        <a
                          target="_blank"
                          href="https://www.youtube.com/watch?v=zribuhiUfL4"
                        >
                          Youtube
                        </a>{" "}
                        or{" "}
                        <a
                          target="_blank"
                          href="https://www.facebook.com/leecountyflbocc/videos/1362873600897990/"
                        >
                          Facebook Live
                        </a>{" "} */}
                      </p>
                      {/* <p>
                        <strong>
                          ​Agendas are posted approximately one week prior to
                          the meeting.​ You can view the agenda and watch
                          meeting videos on our agenda website.
                        </strong>
                      </p>
                      <p>
                        Agendas are posted approximately one week prior to the
                        meeting.​ You can view the agenda and watch meeting
                        videos on our agenda website.
                      </p>
                      <p>
                        Agendas are posted approximately one week prior to the
                        meeting.​ You can view the agenda and watch meeting
                        videos on our agenda website.
                      </p> */}
                    </div>
                  </Col>
                </Row>

                <div className="screen-view-row">
                  <Row>
                    <Col lg={12} sm={12}>
                      <div className="btn-wrap d-flex justify-content-end">
                        <Btn
                          variant="danger"
                          size={"lg"}
                          label="Delete"
                          onClick={() => {
                            setModalDelete(true);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <Dialog
          title={"Delete Item"}
          description={
            <p>Are you sure you want to delete this Notification?</p>
          }
          centered={true}
          show={modalDelete}
          close={(val) => {
            setModalDelete(val);
          }}
          actions={
            <div className="btn-wrap d-flex justify-content-end">
              <Btn
                variant="secondary"
                label="No"
                onClick={() => {
                  setModalDelete(false);
                }}
              />
              <Btn
                variant="primary"
                label="Yes"
                onClick={() => {
                  onDelete();
                }}
              />
            </div>
          }
        />
      </div>
    </React.Fragment>
  );
};

const MapStateToProps = ({ notification }) => {
  return {
    getNotificationByIdData: notification.getNotificationByIdData,
    deleteNotificationDataSuccess: notification.deleteNotificationDataSuccess,
  };
};

export default connect(MapStateToProps, dispathcer)(PushNotificationDetails);
