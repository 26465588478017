import React, { useEffect, useState } from "react";
import { Form, Label } from "reactstrap";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import ErrorStore from "../../../utils/ErrorStore";
import { required } from "../../../utils/Validator";
import { Field, reduxForm, SubmissionError } from "redux-form";
import GoogleLocations from "../../../components/google-locations";
import { Btn, Editor, Input, UploadCoverPicture } from "../../../components";
import * as dispatcher from "../store/dispatchers";

let ServiceForm = ({
  navigation,
  resetState,
  handleSubmit,
  name,
  getDetails,
  cancelBtnUrl,
  coverPhotoUuid,
  uploadCoverPhoto,
  removeCoverPhoto,
  getPostDataSuccess,
  getUpdateDataSuccess,
  createItemsDispatch,
  updateItemsDispatch,
  removeCoverPhotoSuccess,
  coverPhotoDataProgress,
}) => {
  const [selectedImage, setSelectedImage] = useState(
    getDetails ? getDetails.backgroundImageUrl : ""
  );
  const [imageUuid, setImageUuid] = useState(
    getDetails ? getDetails.fileUuid : null
  );
  const [Title, setTitle] = useState(getDetails ? getDetails.title?.en : "");
  const [PhoneNumber, setPhoneNumber] = useState(
    getDetails ? getDetails.phoneNumber : ""
  );
  const [WebsiteURL, setWebsiteURL] = useState(
    getDetails ? getDetails.websiteUrl : ""
  );
  const [Email, setEmail] = useState(getDetails ? getDetails.email : "");
  const [currentLocation, setCurrentLocation] = useState(
    getDetails && getDetails.address
      ? modifySelectedAddress(getDetails.address)
      : ""
  );
  const [addressObj, setAddressObj] = useState(
    getDetails ? getDetails.address : ""
  );
  const [Description, setDescription] = useState(
    getDetails ? getDetails.description?.en : ""
  );

  //to get the onChange value of the Address
  const [getAddress, setGetAddress] = useState("");

  // Defined this state to get the status of removeButton
  const [imageStatus, setImageStatus] = useState(false);

  //validation Error for image size
  const [imageValidationError, setImageValidationError] = useState("");

  // regex Input validation states
  const [validateEmail, setValidateEmail] = useState(true);
  const [validatePhoneNumber, setvalidatePhoneNumber] = useState(true);
  const [validateLink, setValidateLink] = useState(true);

  useEffect(() => {
    if (getPostDataSuccess || getUpdateDataSuccess) {
      navigation(cancelBtnUrl);
      resetState();
    }
  }, [
    cancelBtnUrl,
    getPostDataSuccess,
    getUpdateDataSuccess,
    navigation,
    resetState,
  ]);

  useEffect(() => {
    if (coverPhotoUuid) {
      setImageUuid(coverPhotoUuid);
    }
  }, [coverPhotoUuid]);

  const location = useLocation();
  let pathname = location.pathname.split("/");
  const editId = parseInt(pathname[5]);
  let serviceType = pathname[4];

  serviceType = serviceType
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function ($1) {
      return $1.toLowerCase();
    });

  function _setCurrentLocation(selectedAddress) {
    if (selectedAddress) {
      const address = selectedAddress.address;
      const result = modifySelectedAddress(address);
      setCurrentLocation(result);
      setAddressObj(selectedAddress);
    }
  }

  function modifySelectedAddress(address) {
    return `${address.addressLine1}, ${address.stateCode}`;
  }

  function onClearAddress() {
    setAddressObj("");
    setCurrentLocation("");
  }

  function _validate() {
    const errorInstance = ErrorStore();
    errorInstance.add("title", Title, [required]);
    return errorInstance.get();
  }

  function getModifiedAddressObj(currentAddress) {
    return {
      addressLine1: currentAddress.addressLine1,
      cityName: currentAddress.cityName,
      lat: currentAddress.lat,
      lng: currentAddress.lng,
      stateCode: currentAddress.stateCode,
      stateName: currentAddress.stateName,
      zipcode: currentAddress.zipcode,
    };
  }

  useEffect(() => {
    let emailRegex = /^([a-z\d\.]+)@([a-z\d-]+)\.([a-z]{1,3})$/;
    let phoneRegex = /^([0-9]{10})$/;
    let linkRegex = /[-a-zA-Z\/\:]{2,256}\.[a-z]+\.[a-z]{2,3}/;

    if (Email && !emailRegex.test(Email)) {
      setValidateEmail(false);
    }
    if (Email === "" || Email === undefined || emailRegex.test(Email)) {
      setValidateEmail(true);
    }
    if (PhoneNumber && !phoneRegex.test(PhoneNumber)) {
      setvalidatePhoneNumber(false);
    }
    if (
      PhoneNumber === "" ||
      PhoneNumber === undefined ||
      phoneRegex.test(PhoneNumber)
    ) {
      setvalidatePhoneNumber(true);
    }
    if (WebsiteURL && !linkRegex.test(WebsiteURL)) {
      setValidateLink(false);
    }
    if (
      WebsiteURL === "" ||
      WebsiteURL === undefined ||
      linkRegex.test(WebsiteURL)
    ) {
      setValidateLink(true);
    }
  }, [Email, PhoneNumber, WebsiteURL]);

  function _handleSubmit(details) {
    let errors = null;
    let serviceFormError = _validate(details);
    if (serviceFormError) {
      errors = { ...(errors || {}), ...serviceFormError };
    }
    if (errors) {
      throw new SubmissionError({ ...errors });
    } else {
      // console.log(details);
      let _data = {
        title:
          Title.length > 0 ? Title[0].toUpperCase() + Title.substring(1) : "",
      };
      if (imageUuid) {
        _data.fileUuid = imageStatus ? null : imageUuid;
      }
      if (Email) {
        _data.email = Email;
      }
      if (PhoneNumber) {
        _data.phoneNumber = PhoneNumber;
      }
      if (typeof currentLocation === "object") {
        _data.address = getModifiedAddressObj(currentLocation);
      } else if (addressObj) {
        const { address, lat, lng } = addressObj;
        if (address.en) {
          _data.address = getModifiedAddressObj(addressObj);
        } else {
          const data = {
            ...address,
            lat,
            lng,
          };
          _data.address = getModifiedAddressObj(data);
        }
      }
      if (Email) {
        _data.email = Email;
      }
      if (WebsiteURL) {
        _data.websiteUrl = WebsiteURL;
      }
      if (Description) {
        _data.description = `<!DOCTYPE HTML> <html><head><meta charset="utf-8" /><title></title></head> <body><span>${Description}</span></body> </html>`;
      }
      if (
        !getDetails &&
        imageValidationError === "" &&
        validatePhoneNumber &&
        validateLink &&
        validateEmail
      ) {
        return createItemsDispatch(_data, serviceType);
      }
      if (
        getDetails &&
        imageValidationError === "" &&
        validatePhoneNumber &&
        validateLink &&
        validateEmail
      ) {
        updateItemsDispatch(_data, serviceType, editId);
      }
      if (imageStatus && imageUuid) {
        removeCoverPhoto(imageUuid);
      }
      if (removeCoverPhotoSuccess) {
        updateItemsDispatch(_data, serviceType, editId);
      }
    }
  }

  useEffect(() => {
    if (removeCoverPhotoSuccess) {
      setSelectedImage(null);
      setImageUuid(null);
    }
  }, [removeCoverPhotoSuccess]);

  function removeImage() {
    if (imageUuid) {
      removeCoverPhoto(imageUuid);
    }
  }

  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    if (
      Title ||
      selectedImage ||
      imageUuid ||
      PhoneNumber ||
      WebsiteURL ||
      Email ||
      currentLocation ||
      Description
    ) {
      setIsDisable(false);
    }
  }, [
    Description,
    Email,
    PhoneNumber,
    Title,
    WebsiteURL,
    currentLocation,
    imageUuid,
    selectedImage,
  ]);

  const clickDisable = () => {
    setTimeout(() => {
      setIsDisable(true);
    }, 10);
  };

  return (
    <div className="banner-form-container">
      <div className="screen-view-row">
        <Row>
          <Col lg={6} sm={12} className="d-flex">
            <div className="screen-title-wrap d-flex align-self-center">
              <div className="text-wrap">
                <p className="subtitle1"> {name} </p>
              </div>
            </div>
          </Col>
          <Col lg={6} sm={12}>
            <div className="btn-wrap d-flex justify-content-end">
              <Btn
                variant="secondary"
                size={"lg"}
                label="Go Back"
                onClick={() => navigation(cancelBtnUrl)}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="screen-view-row">
        <Form
          className="form-wrap"
          id="service-form"
          name="serviceForm"
          onSubmit={handleSubmit(_handleSubmit)}
        >
          <Row>
            <Col lg={6} sm={12} md={6}>
              {/* <Label>
                Title <span className="error-msg">*</span>
              </Label> */}
              <Row>
                <Col lg="8" md="9" sm="9">
                  <Label className="label-name" for="banner_name">
                    Title
                    <span className="error-msg"> * </span>
                  </Label>
                </Col>
                <Col lg="4" md="3" sm="3">
                  <p className="title">
                    {100 - Title.length} out of 100 characters left
                  </p>
                </Col>
              </Row>
              <Field
                name="title"
                component={Input}
                defaultValue={Title}
                placeholder="Enter Title"
                type="text"
                id="title"
                maxLength={100}
                showLength={true}
                onchange={(e) => setTitle(e.target.value)}
              ></Field>

              <Label>
                Phone Number <span className="error-msg"></span>
              </Label>
              <Field
                name="phoneNumber"
                component={Input}
                validatePhoneNumber={validatePhoneNumber}
                placeholder="Enter Phone Number"
                maxLength={10}
                type="text"
                id="phone"
                defaultValue={PhoneNumber}
                onchange={(e) => setPhoneNumber(e.target.value)}
              ></Field>

              {/* <Label>
                Website URL <span className="error-msg"></span>
              </Label>
              <Field
                name="websiteURL"
                component={Input}
                validateLink={validateLink}
                placeholder="Enter Website URL"
                type="input"
                id="website"
                defaultValue={WebsiteURL}
                onchange={(e) => setWebsiteURL(e.target.value)}
              ></Field>

              <Label>
                Email <span className="error-msg"></span>
              </Label>
              <Field
                name="email"
                component={Input}
                validateEmail={validateEmail}
                placeholder="Enter Email"
                type="input"
                id="email"
                defaultValue={Email}
                onchange={(e) => setEmail(e.target.value)}
              ></Field> */}

              <Label>
                Address <span className="error-msg"></span>
              </Label>
              <Field
                id="address"
                name="address"
                type="input"
                component={GoogleLocations}
                placeholder="Enter Address"
                inputFieldValue={currentLocation}
                onClearButton={onClearAddress}
                onAddressChange={_setCurrentLocation}
                setGetAddress={setGetAddress}
              ></Field>
            </Col>
            <Col lg={6} sm={12} md={6}>
              <UploadCoverPicture
                selectedImage={selectedImage}
                label="Cover Photo (Optional)"
                onUploadCoverPhoto={uploadCoverPhoto}
                caption="Accepted Formats: PNG, JPG. Max 10 mb"
                id="upload_cover_pic"
                cropConfig={{ aspect: 16 / 9 }}
                onChange={(val) =>
                  setSelectedImage(
                    val === null ? null : coverPhotoUuid ? coverPhotoUuid : ""
                  )
                }
                // _removeImage={(_) => setImageStatus}
                _removeImage={setImageStatus}
                removeImageOnClick={() => removeImage()}
                formTitle={name}
                setImageValidationError={setImageValidationError}
              />
              {imageValidationError !== "" ? (
                <p className="ErrorMsg">{imageValidationError}</p>
              ) : (
                ""
              )}

              <Field
                name="Description"
                id="description"
                component={Editor}
                defaultValue={Description}
                label="Description (Optional)"
                placeholder="Start Writing..."
                onBlur={(data) => {
                  setDescription(data);
                }}
                getDescriptionValue={setDescription}
              ></Field>
            </Col>
            {/* <Col sm={12} md={12} lg={6}> */}
            {/* <Editor
                label="Description (Optional)"
                defaultValue={Description}
                placeholder="Start Writing..."
                onBlur={(event) => setDescription(event)}
              /> */}
            {/* </Col> */}
          </Row>
          <div className="screen-view-row">
            <Row>
              <Col lg={12} sm={12}>
                <div className="btn-wrap d-flex justify-content-end">
                  <Btn
                    variant="secondary"
                    size={"lg"}
                    label="Cancel"
                    onClick={() => {
                      navigation(cancelBtnUrl);
                    }}
                  />
                  <Btn
                    variant="primary"
                    size={"lg"}
                    onClick={clickDisable}
                    label="Submit"
                    type="submit"
                    disabled={coverPhotoDataProgress || isDisable === true}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </div>
  );
};

ServiceForm = reduxForm({
  form: "serviceForm",
  enableReinitialize: true,
})(ServiceForm);

const mapStateToProps = ({ program }) => {
  return {
    getPostDataSuccess: program.getPostDataSuccess,
    coverPhotoUuid: program.coverPhotoUuidData,
    getUpdateDataSuccess: program.getUpdateDataSuccess,
    removeCoverPhotoSuccess: program.removeCoverPhotoSuccess,
    coverPhotoDataProgress: program.uploadCoverPhotoInProgress,
  };
};

export default connect(mapStateToProps, dispatcher)(ServiceForm);
