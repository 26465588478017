import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  Btn,
  Dialog,
  BreadCrumb,
  DisplayCoverPicture,
} from "../../../components";
import { Col, Container, Row } from "react-bootstrap";
import * as dispatcher from "../store/dispatchers";
import LoadingSpinner from "../../../components/LoadingSpinner";
import html2plaintext from "html2plaintext";

function ProgramsCategoryItem({
  itemsList,
  fetchItemsList,
  deleteDataSuccess,
  deleteServiceDispatch,
  itemsListFetchInProgress,
  getPostDataSuccess,
  getUpdateDataSuccess,
  getPostDataLoading,
  getUpdateDataLoading,
  deleteDataLoading,
}) {
  const location = useLocation();
  const paramVar = location.param;
  const [param] = useState(paramVar);
  const [serviceId, setServiceId] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const [modifiedCategoryType, setModifiedCategoryType] = useState("");
  const [modifiedServiceType, setModifiedServiceType] = useState("");
  const baseUrl = `/services/${categoryName}`;
  const addUrl = `${baseUrl}/subcategory/${categoryType}/add/item`;
  const [modalDelete, setModalDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const modifiedParam =
    categoryName === "homelessness" && "Experiencing Homelessness";

  const breadcrumb = [
    {
      label: "Services",
      href: "/services/list",
      active: false,
    },
    {
      label: modifiedParam,
      href: `${baseUrl}/category`,
      active: false,
    },
    {
      label: categoryType,
      href: `${baseUrl}/subcategory`,
      active: true,
    },
  ];

  useEffect(() => {
    let pathname = location.pathname.split("/");
    const categoryName = pathname[2];
    const categoryType = pathname[5];
    const ServiceType = pathname[4];

    setCategoryName(categoryName);
    setCategoryType(categoryType);
    const modifiedCategoryType = categoryType
      .replace(/\s(.)/g, function ($1) {
        return $1.toUpperCase();
      })
      .replace(/\s/g, "")
      .replace(/^(.)/, function ($1) {
        return $1.toLowerCase();
      });
    setModifiedCategoryType(modifiedCategoryType);
    setModifiedServiceType(ServiceType);
    fetchItemsList(modifiedCategoryType, ServiceType);
  }, [fetchItemsList, location.pathname]);

  useEffect(() => {
    if (deleteDataSuccess) {
      // window.location.reload();
      fetchItemsList(modifiedCategoryType, modifiedServiceType);
    }
  }, [deleteDataSuccess]);

  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  useEffect(() => {
    if (itemsListFetchInProgress) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [itemsListFetchInProgress]);

  useEffect(() => {
    if (getPostDataLoading || getUpdateDataLoading || deleteDataLoading) {
      setIsLoading(true);
    } else if (
      getPostDataSuccess ||
      getUpdateDataSuccess ||
      deleteDataSuccess
    ) {
      setTimeout(function () {
        setIsLoading(false);
      }, 8000);
    }
  }, [
    deleteDataLoading,
    deleteDataSuccess,
    getPostDataLoading,
    getPostDataSuccess,
    getUpdateDataLoading,
    getUpdateDataSuccess,
  ]);

  function onDelete() {
    setModalDelete(false);
    return deleteServiceDispatch(modifiedCategoryType, serviceId);
  }

  return (
    <React.Fragment>
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row ">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Details</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() =>
                        navigation(`${baseUrl}/category`, categoryName)
                      }
                    />
                    <Btn
                      variant="primary"
                      size={"lg"}
                      label="Add New"
                      onClick={() => navigation(addUrl, param)}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            {isLoading ? (
              <LoadingSpinner></LoadingSpinner>
            ) : itemsList && itemsList.length > 0 ? (
              itemsList.map((value, index) => {
                const DisplayTitle =
                  value?.title.en[0].toUpperCase() +
                  value?.title.en.substring(1);
                // let plainDescription = html2plaintext(value.description?.en);
                return (
                  <div className="screen-view-row" key={index}>
                    <Row>
                      {value.backgroundImageUrl && (
                        <Col sm={3}>
                          <DisplayCoverPicture src={value.backgroundImageUrl} />
                        </Col>
                      )}
                      <Col sm={value.backgroundImageUrl ? 9 : 12}>
                        <div className="table-wrap">
                          <div className="screen-title-wrap d-flex align-self-center screen-justify">
                            <div className="text-wrap">
                              <p className="subtitle1">{DisplayTitle}</p>
                              <div className="itemsDetails">
                                <div>
                                  {value.description && (
                                    <p
                                      className="descriptionItem"
                                      dangerouslySetInnerHTML={{
                                        __html: value.description.en,
                                      }}
                                    ></p>
                                  )}
                                </div>

                                <div>
                                  {value.address?.address.en && (
                                    <p className="subtitle2 AddMarginItem">
                                      <span className="subtitle1">
                                        Address:{" "}
                                      </span>
                                      {value.address?.address.en}
                                    </p>
                                  )}
                                  {value.phoneNumber && (
                                    <p className="subtitle2 AddMarginItem">
                                      <span className="subtitle1">
                                        Phone Number:{" "}
                                      </span>
                                      {value.phoneNumber}
                                    </p>
                                  )}
                                  {value.email && (
                                    <p className="subtitle2 AddMarginItem">
                                      <span className="subtitle1">Email: </span>
                                      {value.email}
                                    </p>
                                  )}
                                  {value.websiteUrl && (
                                    <p className="subtitle2 AddMarginItem">
                                      <span className="subtitle1">
                                        Website Url:{" "}
                                      </span>
                                      <a href={value.websiteUrl}>
                                        {value.websiteUrl}
                                      </a>
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="btn-wrap category-btn">
                              <Btn
                                variant="secondary"
                                icon={<span className="icon-Edit"></span>}
                                onClick={() =>
                                  navigation(
                                    `${baseUrl}/subcategory/${categoryType}/${value.id}/edit/item`,
                                    param
                                  )
                                }
                              />
                              <Btn
                                variant="secondary"
                                icon={<span className="icon-Delete-2"></span>}
                                style={"btn-icon btn-link-danger"}
                                onClick={() => {
                                  setModalDelete(true);
                                  setServiceId(value.id);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })
            ) : (
              <div className="no-data">
                No Data Found. Click on 'ADD NEW' to create.
              </div>
            )}
          </div>
        </Container>
        <Dialog
          title={"Delete Item"}
          description={<p>Are you sure you want to delete this item?</p>}
          centered={true}
          show={modalDelete}
          close={(val) => {
            setModalDelete(val);
          }}
          actions={
            <div className="btn-wrap d-flex justify-content-end">
              <Btn
                variant="secondary"
                label="No"
                onClick={() => {
                  setModalDelete(false);
                }}
              />
              <Btn
                variant="primary"
                label="Yes"
                onClick={() => {
                  onDelete();
                }}
              />
            </div>
          }
        />
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = ({ program }) => {
  return {
    itemsList: program.itemsList,
    deleteData: program.deleteData,
    deleteDataSuccess: program.deleteDataSuccess,
    itemsListFetchInProgress: program.itemsListFetchInProgress,
    getPostDataSuccess: program.getPostDataSuccess,
    getUpdateDataSuccess: program.getUpdateDataSuccess,
    getPostDataLoading: program.getPostDataLoading,
    getUpdateDataLoading: program.getUpdateDataLoading,
    deleteDataLoading: program.deleteDataLoading,
  };
};

export default connect(mapStateToProps, dispatcher)(ProgramsCategoryItem);
