/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// framework
import { Container, Row, Col, Table } from "react-bootstrap";

// styles

// assets
import { GLOBAL_ICONS } from "../../../assets/icons/global-icons";

// components
import { Btn, BreadCrumb, Legend } from "../../../components";

// utils
import { SETTING_LIST } from "../../../utils/service";

const SettingList = () => {
  const location = useLocation();

  const paramVar = location.param;
  const [param, setParam] = useState(paramVar);
  const [sortByValue, setSortByValue] = useState("");

  const breadcrumb = [
    {
      label: "Settings",
      href: "/settings/list",
      active: false,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  return (
    <React.Fragment>
      {/* <NavHeader /> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Edit Settings</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/home", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row no-border">
              <Legend
                label="Mobile Home Section"
                actions={
                  <Btn
                    label="Edit"
                    variant="outline-primary"
                    size={"sm"}
                    onClick={() =>
                      navigation("/settings/edit", {
                        settingTab: "settingEditTab1",
                      })
                    }
                  />
                }
              />
            </div>
            <div className="screen-view-row">
              <Row>
                <Col sm={12}>
                  <div className="table-wrap">
                    <Table striped bordered>
                      <thead>
                        <tr>
                          <th>Feature Name</th>
                          <th>Label</th>
                          <th>Enabled/Disabled</th>
                        </tr>
                      </thead>
                      <tbody>
                        {SETTING_LIST.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.feature}</td>
                              <td>{item.label}</td>
                              <td>
                                {item.enabled && (
                                  <img
                                    src={GLOBAL_ICONS.CheckMark}
                                    alt="check-mark"
                                    width="24px"
                                  ></img>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row no-border">
              <Legend
                label="Technical Contact Details"
                actions={
                  <Btn
                    label="Edit"
                    variant="outline-primary"
                    size={"sm"}
                    onClick={() =>
                      navigation("/settings/edit", {
                        settingTab: "settingEditTab2",
                      })
                    }
                  />
                }
              />
            </div>
            <div className="screen-view-row">
              <div className="screen-details-row">
                <Row>
                  <Col sm={12}>
                    <div className="text-wrap subtitle-wrap">
                      <p className="subtitle2">
                        <strong>EMAIL ID:</strong>{" "}
                        support@infojiniconsulting.com
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SettingList;
