import axios from "axios";
import { API_URL } from "../constants";
import { getSessionToken } from "./Authentication";

const baseUrl = API_URL;

function post(endpointUrl, details = {}, config = {}) {
  const url = getUrl(endpointUrl);
  const headers = getHeaders();
  return axios
    .post(url, details, { headers, ...config })
    .then(handleSuccess)
    .catch(handleError);
}
function get(endpointUrl) {
  const url = getUrl(endpointUrl);
  const headers = getHeaders();
  return axios.get(url, { headers }).then(handleSuccess).catch(handleError);
}

function put(endpointUrl, details = {}) {
  const url = getUrl(endpointUrl);
  const headers = getHeaders();
  return axios
    .put(url, details, { headers })
    .then(handleSuccess)
    .catch(handleError);
}

function _delete(endpointUrl) {
  const url = getUrl(endpointUrl);
  const headers = getHeaders();
  return axios.delete(url, { headers }).then(handleSuccess).catch(handleError);
}

function getHeaders() {
  const token = getSessionToken();
  // const token = "abcd1234";
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Pragma: "no-cache",
  };
  if (token) {
    headers.Authorization = `JWT ${token}`;
    // headers.Authorization = token;
  }
  return headers;
}

function handleSuccess(res) {
  return res.data;
}
function handleError(err) {
  throw err && err.response ? err.response.data : {};
}

function getUrl(endpointUrl) {
  return `${baseUrl}${endpointUrl}`;
}

const functions = {
  post,
  get,
  put,
  _delete,
};
export default functions;
