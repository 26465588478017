/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// framework
import { Container } from "react-bootstrap";

// components
import { Btn, BreadCrumb, Dialog } from "../../../components";

import EventForm from "../EventForm/index.js";
import { dispatchResetState, postEventsData } from "../store/disptahcers";
import { connect } from "react-redux";

const EventsAdd = ({
  postEventsData,
  postEventDataSuccess,
  dispatchResetState,
}) => {
  const [modalLocateMap, setModalLocateMap] = useState(false);

  const [eventDuration, setEventDuration] = useState("Single Day");

  const breadcrumb = [
    {
      label: "Event Calendar",
      href: "/events/list",
      active: false,
    },
    {
      label: "Add",
      href: "/events/add",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  const onEventAdd = (data) => {
    postEventsData(data);
  };

  useEffect(() => {
    if (postEventDataSuccess) {
      navigation("/events/list");
      dispatchResetState();
    }
  }, [dispatchResetState, postEventDataSuccess]);

  return (
    <React.Fragment>
      {/* <NavHeader /> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <EventForm
              setModalLocateMap={setModalLocateMap}
              navigation={navigation}
              setEventDuration={setEventDuration}
              ActionName="Add Event"
              eventDuration={eventDuration}
              onSubmit={onEventAdd}
            />
          </div>
        </Container>
        <Dialog
          title={"Map"}
          description={
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.618692267389!2d72.8351312153137!3d19.12437675541878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9d8e30e817f%3A0xa53c0adc9279315d!2sDamacloid%20technologies%20private%20limited!5e0!3m2!1sen!2sin!4v1645602980322!5m2!1sen!2sin"
              allowfullscreen=""
              loading="lazy"
              className="w-100"
              style={{ height: "350px" }}
            ></iframe>
          }
          centered={true}
          show={modalLocateMap}
          size={"lg"}
          close={(val) => {
            setModalLocateMap(val);
          }}
          actions={
            <div className="btn-wrap d-flex justify-content-end">
              <Btn
                variant="primary"
                label="Ok"
                onClick={() => {
                  setModalLocateMap(false);
                }}
              />
            </div>
          }
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ event }) => {
  return {
    eventDetails: event.eventData,
    postEventDataSuccess: event.postEventDataSuccess,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    postEventsData: (data) => dispatch(postEventsData(data)),
    dispatchResetState: () => dispatch(dispatchResetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsAdd);
