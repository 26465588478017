/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import { BreadCrumb } from "../../../components";
import ServiceForm from "../ServiceForm";
import { connect } from "react-redux";
import { getItemDetailsById } from "../store/dispatchers";

const ProgramsCategoryEditItems = ({
  itemsDetails,
  getItemDetailsById,
  itemsDetailInProgress,
}) => {
  const location = useLocation();
  const [categoryName, setCategoryName] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const subCategoryUrl = `/services/${categoryName}/subcategory/items/${categoryType}`;

  const pathnames = location.pathname.split("/");

  const serviceData = JSON.parse(localStorage.getItem("serviceData"));

  const findTitle = (service) => {
    return service.name === pathnames[2];
  };

  const par = serviceData?.data.services.find(findTitle);

  const modifiedParam = par?.title.en;

  const breadcrumb = [
    {
      label: "Services",
      href: "/services/list",
      active: false,
    },
    {
      label: modifiedParam,
      href: `/services/${categoryName}/category`,
      active: false,
    },
    {
      label: categoryType,
      href: subCategoryUrl,
      active: false,
    },
    {
      label: "Edit",
      href: "/services/subcategory/edit",
      active: true,
    },
  ];

  useEffect(() => {
    let pathname = location.pathname.split("/");
    const categoryName = pathname[2];
    const categoryType = pathname[4];
    const itemId = pathname[5];
    setCategoryName(categoryName);
    setCategoryType(categoryType);

    const _categoryType = categoryType
      .replace(/\s(.)/g, function ($1) {
        return $1.toUpperCase();
      })
      .replace(/\s/g, "")
      .replace(/^(.)/, function ($1) {
        return $1.toLowerCase();
      });

    getItemDetailsById({
      categoryName: "items",
      categoryType: _categoryType,
      id: itemId,
    });
  }, []);

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  return (
    <React.Fragment>
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            {!itemsDetailInProgress && (
              <ServiceForm
                name="Edit Items"
                navigation={navigation}
                getDetails={itemsDetails}
                cancelBtnUrl={subCategoryUrl}
              ></ServiceForm>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ program }) => {
  return {
    itemsDetails: program.itemsDetails,
    itemsDetailInProgress: program.itemsDetailInProgress,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getItemDetailsById: (data) => dispatch(getItemDetailsById(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgramsCategoryEditItems);
