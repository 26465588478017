/* eslint-disable no-unused-vars */
/* eslint-disable react/style-prop-object */
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// framework
import { Container, Row, Col, Table } from "react-bootstrap";

// styles

// components
import { Btn, BreadCrumb } from "../../../components";

// utils
import { SERVICE_GUIDELINE_CATEGORY } from "../../../utils/service";

const ServicesAndGuidelineSubcategoryList = () => {
  const location = useLocation();

  const paramVar = location.param;
  const [param, setParam] = useState(paramVar);

  const breadcrumb = [
    {
      label: "How To's",
      href: "/service/list",
      active: false,
    },
    {
      label: "Category",
      href: "/service/category",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">{param.title}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/service/list", "")}
                    />
                    <Btn
                      variant="primary"
                      size={"lg"}
                      label="Add New"
                      onClick={() => navigation("/service/subcategory/add", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row">
              <Row>
                <Col sm={12}>
                  <div className="table-wrap">
                    <Table striped bordered>
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Details</th>
                          <th>Service Type</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {SERVICE_GUIDELINE_CATEGORY.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td
                                className="cursor-pointer"
                                onClick={() => {
                                  navigation("/service/details", item);
                                }}
                              >
                                {item.title}
                              </td>
                              <td className="cursor-pointer text-wrap">
                                <a
                                  href={item.url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {item.urlText}
                                </a>
                              </td>
                              <td
                                className="cursor-pointer"
                                onClick={() => {
                                  navigation("/service/details", item);
                                }}
                              >
                                {item.type}
                              </td>
                              <td width={150}>
                                <div className="btn-wrap d-flex justify-content-end">
                                  <Btn
                                    variant="link"
                                    icon={<span className="icon-Edit"></span>}
                                    style={"btn-icon"}
                                    size={"sm"}
                                    onClick={() => {
                                      navigation(
                                        "/service/subcategory/edit",
                                        item
                                      );
                                    }}
                                  />
                                  <Btn
                                    variant="link"
                                    icon={
                                      <span className="icon-Delete-2"></span>
                                    }
                                    style={"btn-icon btn-link-danger"}
                                    size={"sm"}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ServicesAndGuidelineSubcategoryList;
