import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
// framework
import { Container, Row, Col, Form, Table } from "react-bootstrap";
// styles
// components
import { Btn, BreadCrumb, Input } from "../../../components";

const UserEdit = () => {
  const location = useLocation();

  const paramVar = location.param;
  const [param] = useState(paramVar);
  // const [sortByValue, setSortByValue] = useState("");

  const breadcrumb = [
    {
      label: "Users",
      href: "/user/list",
      active: false,
    },
    {
      label: "Edit",
      href: "/user/edit",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  return (
    <React.Fragment>
      {/* <NavHeader /> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Edit User</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/user/list", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row">
              <Form className="form-wrap">
                <Row>
                  <Col lg={6} sm={12}>
                    <Input
                      label="First Name"
                      placeholder="Enter First Name"
                      type="input"
                      id="firstName"
                      defaultValue={param.firstName}
                      maxLength={30}
                      showLength={true}
                      onChange={(data) => {
                        //console.log(data.target.value, "--- input");
                      }}
                    />
                  </Col>
                  <Col lg={6} sm={12}>
                    <Input
                      label="Last Name"
                      placeholder="Enter Last Name"
                      type="input"
                      id="lastName"
                      defaultValue={param.lastName}
                      maxLength={30}
                      showLength={true}
                      onChange={(data) => {
                        //console.log(data.target.value, "--- input");
                      }}
                    />
                  </Col>
                  <Col lg={6} sm={12}>
                    <Input
                      label="Email Address"
                      placeholder="Enter Email Address"
                      type="email"
                      id="email"
                      disabled={true}
                      defaultValue={param.email}
                      maxLength={50}
                      showLength={true}
                      onChange={(data) => {
                        //console.log(data.target.value, "--- input");
                      }}
                    />
                  </Col>
                  <Col lg={6} sm={12}>
                    <Input
                      label="Password"
                      placeholder="Enter Password"
                      type="password"
                      id="current-password"
                      maxLength={20}
                      showLength={true}
                      onChange={(data) => {
                        //console.log(data.target.value, "--- input");
                      }}
                    />
                  </Col>
                </Row>
              </Form>
            </div>
            <div className="screen-view-row">
              <div className="table-wrap">
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>
                        <Form.Group className="form-group" controlId="view">
                          <Form.Check type="checkbox" label="View" />
                        </Form.Group>
                      </th>
                      <th>
                        <Form.Group className="form-group" controlId="add-edit">
                          <Form.Check type="checkbox" label="Add/Edit" />
                        </Form.Group>
                      </th>
                      <th>
                        <Form.Group className="form-group" controlId="delete">
                          <Form.Check type="checkbox" label="Delete" />
                        </Form.Group>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {param.assigned.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.category}</td>
                          <td>
                            <Form.Group
                              className="form-group"
                              controlId={"view" + index}
                            >
                              <Form.Check
                                type="checkbox"
                                label=""
                                defaultChecked={item.isView}
                              />
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group
                              className="form-group"
                              controlId={"add-edit" + index}
                            >
                              <Form.Check
                                type="checkbox"
                                label=""
                                defaultChecked={item.isAddEdit}
                              />
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group
                              className="form-group"
                              controlId={"delete" + index}
                            >
                              <Form.Check
                                type="checkbox"
                                label=""
                                defaultChecked={item.isDelete}
                              />
                            </Form.Group>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="screen-view-row">
              <Row>
                <Col lg={12} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Cancel"
                      onClick={() => {
                        navigation("/user/list", "");
                      }}
                    />
                    <Btn
                      variant="primary"
                      size={"lg"}
                      label="Submit"
                      onClick={() => {}}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserEdit;
