import PropTypes from "prop-types";

// plugin
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// inner component
import { SequenceListGroup } from "./sequence-list-group";

// style
import "./dnd-sequence.scss";

const DNDSequence = (props) => {
  return (
    <div className="dnd-sequence-wrap">
      <DndProvider backend={HTML5Backend}>
        <SequenceListGroup data={props.data} onChange={props.onChange} />
      </DndProvider>
    </div>
  );
};

/**
 * data: provide set of data
 * onChange: handlers from firing regardless of the rendered element.
 */

DNDSequence.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
};

DNDSequence.defaultProps = {
  data: [],
  onChange: () => {},
};
export default DNDSequence;
