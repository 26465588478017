import { reducer as formReducer } from "redux-form";
import bannerReducer from "./screens/banner/store/reducer";
import programsReducer from "./screens/programs/store/reducer";
import eventReducer from "./screens/events/store/reducer";
import homeReducer from "./screens/home/store/reducers";
import notificationReducer from "./screens/push-notifications/store/reducer";
import loginReducer from "./screens/auth/login/store/reducers";

const reducers = {
  form: formReducer,
  banner: bannerReducer,
  program: programsReducer,
  event: eventReducer,
  home: homeReducer,
  notification: notificationReducer,
  login: loginReducer,
};

export default reducers;
