import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

// framework
import { Container, Row, Col, Form } from "react-bootstrap";

// styles

// components
import { Btn, BreadCrumb, Input } from "../../../components";

// utils
// import { CATEGORY_DETAILS_OPTIONS } from '../../../utils/service';

const DirectoryEdit = () => {
  // const location = useLocation();
  // const [categoryDefaultValue, setCategoryDefaultValue] = useState("Web Link");

  // const paramVar = location.param;
  // const [param, setParam] = useState(paramVar);

  const breadcrumb = [
    {
      label: "Directory",
      href: "/directory/details",
      active: false,
    },
    {
      label: "Edit",
      href: "/directory/edit",
      active: true,
    },
  ];

  useEffect(() => {}, []);

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Edit Directory</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/directory/details", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row">
              <Form className="form-wrap">
                <Row>
                  <Col lg={6} sm={12}>
                    <Input
                      label="Url"
                      placeholder="Url"
                      type="input"
                      id="Url"
                      maxLength={250}
                      defaultValue="https://www.smc-connect.org/"
                      showLength={true}
                      onChange={(data) => {
                        console.log(data.target.value, "--- input");
                      }}
                    />
                  </Col>
                </Row>
              </Form>
            </div>
            <div className="screen-view-row">
              <Row>
                <Col lg={12} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Cancel"
                      onClick={() => {
                        navigation("/directory/details", "");
                      }}
                    />
                    <Btn
                      variant="primary"
                      size={"lg"}
                      label="Submit"
                      onClick={() => {}}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DirectoryEdit;
