import React from "react";
import Routes from "./routes";
import { APP_FEATURE } from "./utils/service";
import { NavHeader, NavFooter } from "./components";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import "./assets/styles/index.scss";
import { isAuthenticated } from "./utils/Authentication";
import Login from "./screens/auth/login";

function App() {
  return (
    <Router>
      {!isAuthenticated() ? (
        <>
          <Redirect to="/"></Redirect>
          <Login></Login>
        </>
      ) : (
        <>
          <NavHeader navData={APP_FEATURE} />
          <Routes />
          <NavFooter />
        </>
      )}
    </Router>
  );
}
export default App;
