import Actions from "./actions";

const defaultState = {
  eventData: null,
  eventDataLoading: false,
  eventDataFail: false,
  eventDataSuccess: false,

  eventDataId: null,
  eventDataIdSuccess: false,
  eventIdDataFail: false,
  eventIdDataLoading: false,

  //Event Add
  postEventDataLoading: false,
  postEventDataSuccess: false,
  postEventDataFail: false,

  putEventDataLoading: false,
  putEventDataSuccess: false,
  putEventDataFail: false,
  putEventDataDetails: null,

  deleteEventsDataLoading: false,
  deleteEventsDataSuccess: false,
  deleteEventsDataFail: false,
};

const eventReducer = (state = defaultState, action) => {
  switch (action.type) {
    case Actions.RESET_STATE:
      return {
        ...defaultState,
      };
    case Actions.GET_EVENTS_DATA:
      return {
        ...state,
        eventDataLoading: true,
        eventDataFail: false,
        eventDataSuccess: false,
      };
    case Actions.GET_EVENTS_DATA_SUCCESS:
      return {
        ...state,
        eventData: action.details,
        eventDataLoading: false,
        eventDataFail: false,
        eventDataSuccess: true,
      };
    case Actions.GET_EVENTS_DATA_FAIL:
      return {
        ...state,
        eventDataLoading: false,
        eventDataFail: true,
        eventDataSuccess: false,
      };

    case Actions.GET_EVENTS_DATA_BY_ID:
      return {
        ...state,
        eventDataIdSuccess: false,
        eventIdDataFail: false,
        eventIdDataLoading: true,
      };

    case Actions.GET_EVENTS_DATA_BY_ID_SUCCESS:
      return {
        ...state,
        eventDataId: action.details,
        eventDataIdSuccess: true,
        eventIdDataFail: false,
        eventIdDataLoading: false,
      };

    case Actions.GET_EVENTS_DATA_BY_ID_FAIL:
      return {
        ...state,
        eventDataIdSuccess: false,
        eventIdDataFail: true,
        eventIdDataLoading: false,
      };
    case Actions.POST_EVENT_DATA:
      return {
        ...state,
        postEventDataLoading: true,
        postEventDataSuccess: false,
        postEventDataFail: false,
      };
    case Actions.POST_EVENT_DATA_SUCCESS:
      return {
        ...state,
        postEventDataLoading: false,
        postEventDataSuccess: true,
        postEventDataFail: false,
      };
    case Actions.POST_EVENT_DATA_FAIL:
      return {
        ...state,
        postEventDataLoading: false,
        postEventDataSuccess: false,
        postEventDataFail: false,
      };

    case Actions.PUT_EVENT_DATA:
      return {
        ...state,
        putEventDataLoading: true,
        putEventDataSuccess: false,
        putEventDataFail: false,
      };

    case Actions.PUT_EVENT_DATA_SUCCESS:
      return {
        ...state,
        putEventDataLoading: false,
        putEventDataSuccess: true,
        putEventDataFail: false,
        putEventDataDetails: action.details,
      };

    case Actions.PUT_EVENT_DATA_FAIL:
      return {
        ...state,
        putEventDataLoading: false,
        putEventDataSuccess: false,
        putEventDataFail: true,
      };

    case Actions.DELETE_EVENT_DATA:
      return {
        ...state,
        deleteEventsDataLoading: true,
        deleteEventsDataSuccess: false,
        deleteEventsDataFail: false,
      };

    case Actions.DELETE_EVENT_DATA_SUCCESS:
      return {
        ...state,
        deleteEventsDataLoading: false,
        deleteEventsDataSuccess: true,
        deleteEventsDataFail: false,
      };

    case Actions.DELETE_EVENT_DATA_FAIL:
      return {
        ...state,
        deleteEventsDataLoading: false,
        deleteEventsDataSuccess: false,
        deleteEventsDataFail: true,
      };
    default:
      return {
        ...state,
      };
  }
};

export default eventReducer;
