import React from "react";
import { useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import { BreadCrumb } from "../../../components";
import BannerForm from "../bannerForm";
import { connect } from "react-redux";
import * as dispatcher from "../store/dispatchers";
import { useEffect } from "react";

const EventsAdd = ({ bannerAddSuccess, postBannerData, resetState }) => {
  const breadcrumb = [
    {
      label: "Banner",
      href: "/banners/list",
      active: false,
    },
    {
      label: "Add Banner",
      href: "/banner/add",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  useEffect(() => {
    if (bannerAddSuccess) {
      setTimeout(() => {
        navigation("/banners/list");
      }, 10);

      resetState();
    }
  }, [bannerAddSuccess]);

  const onBannerAdd = (data) => {
    postBannerData(data);
  };

  return (
    <React.Fragment>
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb} />
          <div className="screen-view-wrap">
            <BannerForm
              formTitle="Add"
              navigation={navigation}
              onSubmit={onBannerAdd}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = ({ banner }) => {
  return {
    bannerAddSuccess: banner.bannerAddSuccess,
  };
};

export default connect(mapStatetoProps, dispatcher)(EventsAdd);
