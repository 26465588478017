import React from "react";
import PropTypes from "prop-types";

// framework
import { Image } from "react-bootstrap";

// styles
import "./display-cover-picture.scss";

const DisplayCoverPicture = ({
  src,
  fluid,
  rounded,
  roundedCircle,
  thumbnail,
  style,
  containerStyle,
  caption,
}) => {
  return (
    <div className={`${containerStyle} cover-pic-wrap`}>
      <div className="cover-img-wrap">
        <Image
          src={src}
          fluid={fluid}
          rounded={rounded}
          roundedCircle={roundedCircle}
          thumbnail={thumbnail}
          className={style}
        />
      </div>
      {caption && (
        <div className="text-wrap subtitle-wrap mb-2">
          <p className="caption textAlign">{caption}</p>
        </div>
      )}
    </div>
  );
};

/**
 * src: source of an image
 * cpation: set caption
 * style: Manually style the image
 * containerStyle: Manually style the wrapper
 * fluid: Sets image as fluid image.
 * rounded: Sets image shape as rounded.
 * roundedCircle: Sets image shape as circle.
 * thumbnail: Sets image shape as thumbnail.
 */

DisplayCoverPicture.propTypes = {
  src: PropTypes.string,
  cpation: PropTypes.string,
  style: PropTypes.string,
  containerStyle: PropTypes.string,
  fluid: PropTypes.bool,
  rounded: PropTypes.bool,
  roundedCircle: PropTypes.bool,
  thumbnail: PropTypes.bool,
};

DisplayCoverPicture.defaultProps = {
  src: "",
  cpation: "",
  style: "",
  containerStyle: "",
  fluid: true,
  rounded: false,
  roundedCircle: false,
  thumbnail: false,
};

export default DisplayCoverPicture;
