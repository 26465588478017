import React from "react";
import PropTypes from "prop-types";

// framework
import { Modal } from "react-bootstrap";

// style
import "./dialog.scss";

const Dialog = (props) => {
  const handleClose = () => {
    props.close(!props.show);
  };

  return (
    <Modal
      show={props.show}
      size={props.size}
      onHide={handleClose}
      backdrop={props.backdrop}
      keyboard={false}
      fullscreen={props.fullscreen}
      centered={props.centered}
    >
      {props.title && (
        <Modal.Header closeButton={props.closeButton}>
          <Modal.Title>
            <div className="text-wrap subtitle-wrap mb-0">
              <p className="subtitle1 text-truncate">{props.title}</p>
            </div>
          </Modal.Title>
        </Modal.Header>
      )}

      {props.description && (
        <Modal.Body>
          <div>{props.description}</div>
        </Modal.Body>
      )}

      {props.actions && <Modal.Footer>{props.actions}</Modal.Footer>}
    </Modal>
  );
};

/**
 * show: When true The modal will show itself.
 * size: Render a large, extra large or small modal. When not provided, the modal is rendered with medium (default) size. 'sm' | 'lg' | 'xl'
 * title: Render dialog title
 * description: Render dialog description
 * actions: actions: handlers from firing regardless of the rendered element.
 * fullscreen: Renders a fullscreen modal. Specifying a breakpoint will render the modal as fullscreen below the breakpoint size. true | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down'
 * backdrop: Include a backdrop component. Specify 'static' for a backdrop that doesn't trigger an "onHide" when clicked. 'static' | true | false
 * centered: vertically center the Dialog in the window
 * closeButton: Specify whether the Component should contain a close button
 */

Dialog.propTypes = {
  show: PropTypes.bool,
  size: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  actions: PropTypes.object,
  fullscreen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  centered: PropTypes.bool,
  closeButton: PropTypes.bool,
};

Dialog.defaultProps = {
  show: false,
  size: "md",
  title: "",
  description: null,
  actions: null,
  fullscreen: null,
  backdrop: "static",
  centered: true,
  closeButton: true,
};

export default Dialog;
