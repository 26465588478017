import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// framework
import { Container, Row, Col } from "react-bootstrap";

// styles

// components
import { Btn, BreadCrumb, Legend } from "../../../components";

const DirectoryDetails = () => {
  const location = useLocation();

  const paramVar = location.param;
  const [param] = useState(paramVar);

  const breadcrumb = [
    {
      label: "Directory",
      href: "/directory/list",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Directory</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/home", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row no-border">
              <Legend
                label="Details"
                actions={
                  <Btn
                    label="Edit"
                    variant="outline-primary"
                    size={"sm"}
                    onClick={() => navigation("/directory/edit", param)}
                  />
                }
              />
            </div>
            <div className="screen-view-row no-border mb-0 pb-0">
              <div className="screen-details-row">
                <Row>
                  <Col sm={12}>
                    <div className="text-wrap subtitle-wrap mb-0">
                      <p className="subtitle2">
                        URL:{" "}
                        <a
                          href="https://www.smc-connect.org/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://www.smc-connect.org/
                        </a>
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DirectoryDetails;
