import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { Btn, BreadCrumb, FeatureProgramsCard } from "../../../components";
import { getServiceData } from "../store/dispatchers";
import LoadingSpinner from "../../../components/LoadingSpinner";

const ProgramsList = ({
  serviceData,
  dispatchServiceData,
  programsDataInProgress,
  programsDataSuccess,
}) => {
  const location = useLocation();
  const paramVar = location.param;
  const [param, setParam] = useState(paramVar);
  const [isLoading, setIsLoading] = useState(false);

  const breadcrumb = [
    {
      label: "Services",
      href: "/services/list",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  const handleProgramSelection = (serviceName) => {
    setParam(serviceName);
    navigation(`/services/${serviceName}/category`, serviceName);
  };

  useEffect(() => {
    dispatchServiceData();
  }, [dispatchServiceData]);

  useEffect(() => {
    if (programsDataInProgress) {
      setIsLoading(true);
    } else if (programsDataSuccess) {
      setIsLoading(false);
    }
  }, [programsDataInProgress, programsDataSuccess]);

  return (
    <React.Fragment>
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Services</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/home", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row">
              <Row>
                {isLoading ? (
                  <LoadingSpinner></LoadingSpinner>
                ) : (
                  serviceData?.data.services.map((item, index) => {
                    return (
                      <Col
                        sm={12}
                        md={12}
                        lg={{ span: 5, offset: index % 2 ? 2 : 0 }}
                        key={index}
                      >
                        <div
                          className="feature-content-row no-border pb-0"
                          key={index}
                        >
                          <FeatureProgramsCard
                            title={item.title.en}
                            icon={item.iconUrl}
                            count={index}
                            onClick={() => handleProgramSelection(item.name)}
                            actions={
                              <React.Fragment>
                                <Btn
                                  variant="link"
                                  icon={<span className="icon-View"></span>}
                                  style={"btn-icon"}
                                />
                              </React.Fragment>
                            }
                          />
                        </div>
                      </Col>
                    );
                  })
                )}
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = ({ program }) => {
  return {
    serviceData: program.programsDetails,
    programsDataInProgress: program.programsDataInProgress,
    programsDataSuccess: program.programsDataSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchServiceData: () => dispatch(getServiceData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgramsList);
