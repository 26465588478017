const Actions = {
  RESET_STATE: "RESET_STATE",

  GET_NOTIFICATION_DATA: "GET_NOTIFICATION_DATA",
  GET_NOTIFICATION_DATA_SUCCESS: "GET_NOTIFICATION_DATA_SUCCESS",
  GET_NOTIFICATION_DATA_FAIL: "GET_NOTIFICATION_DATA_FAIL",

  GET_NOTIFICATION_BY_ID: "GET_NOTIFICATION_BY_ID",
  GET_NOTIFICATION_BY_ID_SUCCESS: "GET_NOTIFICATION_BY_ID_SUCCESS",
  GET_NOTIFICATION_BY_ID_FAIL: "GET_NOTIFICATION_BY_ID_FAIL",

  POST_NOTIFICATION_DATA: " POST_NOTIFICATIO_DATA",
  POST_NOTIFICATION_DATA_SUCCESS: "POST_NOTIFICATIO_DATA_SUCCESS",
  POST_NOTIFICATION_DATA_FAIL: " POST_NOTIFICATIO_DATA_FAIL",

  UPDATE_NOTIFICATION_DATA: "UPDATE_NOTIFICATION_DATA",
  UPDATE_NOTIFICATION_DATA_SUCCESS: "UPDATE_NOTIFICATION_DATA_SUCCESS",
  UPDATE_NOTIFICATION_DATA_FAIL: "UPDATE_NOTIFICATION_DATA_FAIL",

  DELETE_NOTIFICATION_DATA: "DELETE_NOTIFICATION_DATA",
  DELETE_NOTIFICATION_DATA_SUCCESS: "DELETE_NOTIFICATION_DATA_SUCCESS",
  DELETE_NOTIFICATION_DATA_FAIL: "DELETE_NOTIFICATION_DATA_FAIL",
};

export default Actions;
