/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// framework
import { Container, Row, Col, Form } from "react-bootstrap";

// styles

// components
import { Btn, BreadCrumb, Input } from "../../../components";

const SurveyEdit = () => {
  const location = useLocation();
  const [selectDefaultValue, setSelectDefaultValue] = useState("");

  const paramVar = location.param;
  const [param, setParam] = useState(paramVar);

  const breadcrumb = [
    {
      label: "Survey",
      href: "/survey/list",
      active: false,
    },
    {
      label: "Edit",
      href: "/survey/edit",
      active: true,
    },
  ];

  useEffect(() => {}, []);

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">App Fedback</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/survey/list", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row">
              <Form className="form-wrap">
                <Row>
                  <Col lg={6} sm={12}>
                    <Form.Group
                      className="form-group form-group-inline"
                      controlId="survey-enable"
                    >
                      <Form.Label>Enable</Form.Label>
                      <Form.Check type="switch" label="" />
                    </Form.Group>
                    <Input
                      label="Survey Name"
                      placeholder="Enter Survey Name"
                      type="input"
                      id="surevy_name"
                      onChange={(data) => {
                        //console.log(data.target.value, "--- input");
                      }}
                    />
                    <Input
                      label="Survey Code"
                      placeholder="Enter Survey Code"
                      type="input"
                      id="survey_code"
                      onChange={(data) => {
                        //console.log(data.target.value, "--- input");
                      }}
                    />
                    <Input
                      label="No. of Questions"
                      placeholder="Enter Survey Code"
                      type="number"
                      id="number_of_question"
                      onChange={(data) => {
                        //console.log(data.target.value, "--- input");
                      }}
                    />
                  </Col>
                  <Col lg={6} sm={12}>
                    <Input
                      label="Description (Optional)"
                      placeholder="Enter Description"
                      type="input"
                      as="textarea"
                      rows={10}
                      maxLength={100}
                      showLength={true}
                      id="description"
                      onChange={(data) => {
                        //console.log(data.target.value, "--- input");
                      }}
                    />
                    <Input
                      label="Success Message (Optional)"
                      placeholder="Enter Description"
                      type="input"
                      as="textarea"
                      rows={10}
                      maxLength={100}
                      showLength={true}
                      id="success_message"
                      onChange={(data) => {
                        //console.log(data.target.value, "--- input");
                      }}
                    />
                  </Col>
                </Row>
              </Form>
            </div>
            <div className="screen-view-row">
              <Row>
                <Col lg={12} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Cancel"
                      onClick={() => {
                        navigation("/survey/list", "");
                      }}
                    />
                    <Btn
                      variant="primary"
                      size={"lg"}
                      label="Next"
                      onClick={() => {}}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SurveyEdit;
