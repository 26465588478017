import Actions from "./actions";

const defaultState = {
  programsDataInProgress: false,
  programsDataSuccess: false,
  programsDataFail: false,
  programsDetails: null,

  contentDataInProgress: false,
  contentDataSuccess: false,
  contentDataFail: false,
  contentDetails: null,

  contentDetailsById: null,
  contentDetailsByIdInProgress: false,
  contentDetailsByIdSuccess: false,
  contentDetailsByIdFail: false,

  itemsListFetchInProgress: false,
  itemsListFetchSuccess: false,
  itemsListFetchFail: false,
  itemsList: null,

  getPostDataLoading: false,
  getPostDataFail: false,
  getPostDataSuccess: false,
  getPostData: null,

  getUpdateDataLoading: false,
  getUpdateDataSuccess: false,
  getUpdateDataFail: false,
  getUpdateData: null,

  getPostContentDataLoading: false,
  getPostContentDataFail: false,
  getPostContentDataSuccess: false,
  getPostContentData: null,

  getUpdateContentDataLoading: false,
  getUpdateContentDataSuccess: false,
  getUpdateContentDataFail: false,
  getUpdateContentData: null,

  deleteDataLoading: false,
  deleteDataSuccess: false,
  deleteDataFail: false,
  deleteData: null,

  deleteContentDataLoading: false,
  deleteContentDataSuccess: false,
  deleteContentDataFail: false,
  deleteContentData: null,

  itemsDetailInProgress: false,
  itemsDetailsFetchSuccess: false,
  itemsDetailsFetchFail: false,
  itemsDetails: null,

  coverPhotoUuidData: null,
  uploadCoverPhotoInProgress: false,
  uploadCoverPhotoSuccess: false,
  uploadCoverPhotoFail: false,

  removeCoverPhotoInProgress: false,
  removeCoverPhotoSuccess: false,
  removeCoverPhotoFail: false,

  coverPhotoDataSuccess: false,
  coverPhotoDataFail: false,
  coverPhotoDataProgress: false,

  fileUploadUuid: null,
  fileUploadInProgress: false,
  fileUploadSuccess: false,
  fileUploadFail: false,
};

function programsReducer(state = defaultState, action) {
  switch (action.type) {
    case Actions.RESET_STATE:
      return {
        ...defaultState,
      };
    case Actions.GET_SERVICE_DATA:
      return {
        ...state,
        programsDataInProgress: true,
        programsDataSuccess: false,
        programsDataFail: false,
      };
    case Actions.GET_SERVICE_DATA_SUCCESS:
      return {
        ...state,
        programsDataInProgress: false,
        programsDataSuccess: true,
        programsDataFail: false,
        programsDetails: action.details,
      };
    case Actions.GET_SERVICE_DATA_FAIL:
      return {
        ...state,
        programsDataInProgress: false,
        programsDataSuccess: false,
        programsDataFail: true,
      };

    case Actions.GET_ITEM_DETAILS_BY_ID:
      return {
        ...state,
        itemsDetailInProgress: true,
        itemsDetailsFetchSuccess: false,
        itemsDetailsFetchFail: false,
      };
    case Actions.GET_ITEM_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        itemsDetailInProgress: false,
        itemsDetailsFetchSuccess: true,
        itemsDetailsFetchFail: false,
        itemsDetails: action.details.data,
      };
    case Actions.GET_ITEM_DETAILS_BY_ID_FAIL:
      return {
        ...state,
        itemsDetailInProgress: false,
        itemsDetailsFetchSuccess: false,
        itemsDetailsFetchFail: true,
      };

    case Actions.GET_CONTENT_DATA:
      return {
        ...state,
        contentDataInProgress: true,
        contentDataSuccess: false,
        contentDataFail: false,
      };
    case Actions.GET_CONTENT_DATA_SUCCESS:
      return {
        ...state,
        contentDataInProgress: false,
        contentDataSuccess: true,
        contentDataFail: false,
        contentDetails: action.details.data.list,
      };
    case Actions.GET_CONTENT_DATA_FAIL:
      return {
        ...state,
        contentDataInProgress: false,
        contentDataSuccess: false,
        contentDataFail: true,
      };
    case Actions.FETCH_ITEMS_LIST:
      return {
        ...state,
        itemsListFetchInProgress: true,
        itemsListFetchSuccess: false,
        itemsListFetchFail: false,
      };
    case Actions.FETCH_ITEMS_LIST_SUCCESS:
      return {
        ...state,
        itemsListFetchInProgress: false,
        itemsListFetchSuccess: true,
        itemsListFetchFail: false,
        itemsList: action.details.data.list,
      };
    case Actions.FETCH_ITEMS_LIST_FAIL:
      return {
        ...state,
        itemsListFetchInProgress: false,
        itemsListFetchSuccess: false,
        itemsListFetchFail: true,
      };

    case Actions.GET_POST_DATA:
      return {
        ...state,
        getPostDataLoading: true,
        getPostDataFail: false,
        getPostDataSuccess: false,
      };
    case Actions.GET_POST_DATA_SUCCESS:
      return {
        ...state,
        getPostDataLoading: false,
        getPostDataFail: false,
        getPostDataSuccess: true,
        getPostData: action.details,
      };
    case Actions.GET_POST_DATA_FAIL:
      return {
        ...state,
        getPostDataLoading: false,
        getPostDataFail: true,
        getPostDataSuccess: false,
      };

    case Actions.GET_POST_CONTENT_DATA:
      return {
        ...state,
        getPostContentDataLoading: true,
        getPostContentDataFail: false,
        getPostContentDataSuccess: false,
      };

    case Actions.GET_POST_CONTENT_DATA_SUCCESS:
      return {
        ...state,
        getPostContentDataLoading: false,
        getPostContentDataFail: false,
        getPostContentDataSuccess: true,
        getPostContentData: action.details,
      };

    case Actions.GET_POST_CONTENT_DATA_FAIL:
      return {
        ...state,
        getPostContentDataLoading: false,
        getPostContentDataFail: true,
        getPostContentDataSuccess: false,
      };

    case Actions.GET_UPDATE_DATA:
      return {
        ...state,
        getUpdateDataLoading: true,
        getUpdateDataSuccess: false,
        getUpdateDataFail: false,
      };
    case Actions.GET_UPDATE_DATA_SUCCESS:
      return {
        ...state,
        getUpdateDataLoading: false,
        getUpdateDataSuccess: true,
        getUpdateDataFail: false,
        getUpdateData: action.details,
      };
    case Actions.GET_UPDATE_DATA_FAIL:
      return {
        ...state,
        getUpdateDataLoading: false,
        getUpdateDataSuccess: false,
        getUpdateDataFail: true,
      };

    case Actions.GET_UPDATE_CONTENT_DATA:
      return {
        ...state,
        getUpdateContentDataLoading: true,
        getUpdateContentDataSuccess: false,
        getUpdateContentDataFail: false,
      };

    case Actions.GET_UPDATE_CONTENT_DATA_SUCCESS:
      return {
        ...state,
        getUpdateContentDataLoading: false,
        getUpdateContentDataSuccess: true,
        getUpdateContentDataFail: false,
        getUpdateContentData: action.details,
      };

    case Actions.GET_UPDATE_CONTENT_DATA_FAIL:
      return {
        ...state,
        getUpdateContentDataLoading: false,
        getUpdateContentDataSuccess: false,
        getUpdateContentDataFail: true,
      };

    case Actions.DELETE_DATA:
      return {
        ...state,
        deleteDataLoading: true,
        deleteDataSuccess: false,
        deleteDataFail: false,
      };
    case Actions.DELETE_DATA_SUCCESS:
      return {
        ...state,
        deleteDataLoading: false,
        deleteDataSuccess: true,
        deleteDataFail: false,
        deleteData: action.details,
      };
    case Actions.DELETE_DATA_FAIL:
      return {
        ...state,
        deleteDataLoading: false,
        deleteDataSuccess: false,
        deleteDataFail: true,
      };

    case Actions.DELETE_CONTENT_DATA:
      return {
        ...state,
        deleteContentDataLoading: true,
        deleteContentDataSuccess: false,
        deleteContentDataFail: false,
      };

    case Actions.DELETE_CONTENT_DATA_SUCCESS:
      return {
        ...state,
        deleteContentDataLoading: false,
        deleteContentDataSuccess: true,
        deleteContentDataFail: false,
        deleteContentData: action.details,
      };

    case Actions.DELETE_CONTENT_DATA_FAIL:
      return {
        ...state,
        deleteContentDataLoading: false,
        deleteContentDataSuccess: false,
        deleteContentDataFail: true,
      };

    case Actions.UPLOAD_COVER_PHOTO:
      return {
        ...state,
        uploadCoverPhotoInProgress: true,
        uploadCoverPhotoSuccess: false,
        uploadCoverPhotoFail: false,
      };
    case Actions.UPLOAD_COVER_PHOTO_SUCCESS:
      return {
        ...state,
        uploadCoverPhotoInProgress: false,
        uploadCoverPhotoSuccess: true,
        coverPhotoUuidData: action.details.data.uuid,
        uploadCoverPhotoFail: false,
      };
    case Actions.UPLOAD_COVER_PHOTO_FAIL:
      return {
        ...state,
        uploadCoverPhotoInProgress: false,
        uploadCoverPhotoSuccess: false,
        uploadCoverPhotoFail: true,
      };

    case Actions.REMOVE_COVER_PHOTO:
      return {
        ...state,
        removeCoverPhotoInProgress: true,
        removeCoverPhotoSuccess: false,
        removeCoverPhotoFail: false,
      };

    case Actions.REMOVE_COVER_PHOTO_SUCCESS:
      return {
        ...state,
        coverPhotoUuidData: null,
        removeCoverPhotoInProgress: false,
        removeCoverPhotoSuccess: true,
        removeCoverPhotoFail: false,
      };

    case Actions.REMOVE_COVER_PHOTO_FAIL:
      return {
        ...state,
        removeCoverPhotoInProgress: false,
        removeCoverPhotoSuccess: false,
        removeCoverPhotoFail: true,
      };
    case Actions.FILE_UPLOAD:
      return {
        fileUploadInProgress: true,
        fileUploadSuccess: false,
        fileUploadFail: false,
      };
    case Actions.FILE_UPLOAD_SUCCESS:
      return {
        fileUploadUuid: action.data.data.uuid,
        fileUploadInProgress: false,
        fileUploadSuccess: true,
        fileUploadFail: false,
      };
    case Actions.FILE_UPLOAD_FAIL:
      return {
        fileUploadInProgress: false,
        fileUploadSuccess: false,
        fileUploadFail: true,
      };

    case Actions.GET_CONTENTS_DETAILS_BY_ID:
      return {
        ...state,
        contentDetailsByIdInProgress: true,
        contentDetailsByIdSuccess: false,
        contentDetailsByIdFail: false,
      };
    case Actions.GET_CONTENTS_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        contentDetailsByIdInProgress: false,
        contentDetailsByIdSuccess: true,
        contentDetailsByIdFail: false,
        contentDetailsById: action.details.data,
      };
    case Actions.GET_CONTENTS_DETAILS_BY_ID_FAIL:
      return {
        ...state,
        contentDetailsByIdInProgress: false,
        contentDetailsByIdSuccess: false,
        contentDetailsByIdFail: true,
      };
    default:
      return { ...state };
  }
}

export default programsReducer;
