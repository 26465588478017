const Actions = {
  RESET_STATE: "RESET_STATE",

  GET_SERVICE_DATA: "GET_SERVICE_DATA",
  GET_SERVICE_DATA_SUCCESS: "GET_SERVICE_DATA_SUCCESS",
  GET_SERVICE_DATA_FAIL: "GET_SERVICE_DATA_FAIL",

  GET_CONTENT_DATA: "GET_CONTENT_DATA",
  GET_CONTENT_DATA_SUCCESS: "GET_CONTENT_DATA_SUCCESS",
  GET_CONTENT_DATA_FAIL: "GET_CONTENT_DATA_FAIL",

  FETCH_ITEMS_LIST: "FETCH_ITEMS_LIST",
  FETCH_ITEMS_LIST_SUCCESS: "FETCH_ITEMS_LIST_SUCCESS",
  FETCH_ITEMS_LIST_FAIL: "FETCH_ITEMS_LIST_FAIL",

  GET_POST_DATA: "GET_POST_DATA",
  GET_POST_DATA_SUCCESS: "GET_POST_DATA_SUCCESS",
  GET_POST_DATA_FAIL: "GET_POST_DATA_FAIL",

  GET_UPDATE_DATA: "GET_UPDATE_DATA",
  GET_UPDATE_DATA_SUCCESS: "GET_UPDATE_DATA_SUCCESS",
  GET_UPDATE_DATA_FAIL: "GET_UPDATE_DATA_FAIL",

  GET_POST_CONTENT_DATA: "GET_POST_CONTENT_DATA",
  GET_POST_CONTENT_DATA_SUCCESS: "GET_POST_CONTENT_DATA_SUCCESS",
  GET_POST_CONTENT_DATA_FAIL: "GET_POST_CONTENT_DATA_FAIL",

  GET_UPDATE_CONTENT_DATA: "GET_UPDATE_CONTENT_DATA",
  GET_UPDATE_CONTENT_DATA_SUCCESS: "GET_UPDATE_CONTENT_DATA_SUCCESS",
  GET_UPDATE_CONTENT_DATA_FAIL: "GET_UPDATE_CONTENT_DATA_FAIL",

  DELETE_DATA: "DELETE_DATA",
  DELETE_DATA_SUCCESS: "DELETE_DATA_SUCCESS",
  DELETE_DATA_FAIL: "DELETE_DATA_FAIL",

  DELETE_CONTENT_DATA: "DELETE_CONTENT_DATA",
  DELETE_CONTENT_DATA_SUCCESS: "DELETE_CONTENT_DATA_SUCCESS",
  DELETE_CONTENT_DATA_FAIL: "DELETE_CONTENT_DATA_FAIL",

  GET_ITEM_DETAILS_BY_ID: "GET_ITEM_DETAILS_BY_ID",
  GET_ITEM_DETAILS_BY_ID_SUCCESS: "GET_ITEM_DETAILS_BY_ID_SUCCESS",
  GET_ITEM_DETAILS_BY_ID_FAIL: "GET_ITEM_DETAILS_BY_ID_FAIL",

  UPLOAD_COVER_PHOTO: "UPLOAD_COVER_PHOTO",
  UPLOAD_COVER_PHOTO_SUCCESS: "UPLOAD_COVER_PHOTO_SUCCESS",
  UPLOAD_COVER_PHOTO_FAIL: "UPLOAD_COVER_PHOTO_FAIL",

  REMOVE_COVER_PHOTO: "REMOVE_COVER_PHOTO",
  REMOVE_COVER_PHOTO_SUCCESS: "REMOVE_COVER_PHOTO_SUCCESS",
  REMOVE_COVER_PHOTO_FAIL: "REMOVE_COVER_PHOTO_FAIL",

  //post banner image
  POST_COVERPICTURE_DATA: "POST_COVERPICTURE_DATA",
  POST_COVERPICTURE_SUCCESS: "POST_COVERPICTURE_sUCCESS",
  POST_COVERPICTURE_FAIL: "POST_COVERPICTURE_FAIL",

  //get banner image
  GET_COVERPICTURE_DATA: "GET_COVERPICTURE_DATA",
  GET_COVERPICTURE_SUCCESS: "GET_COVERPICTURE_sUCCESS",
  GET_COVERPICTURE_FAIL: "GET_COVERPICTURE_FAIL",

  FILE_UPLOAD: "FILE_UPLOAD",
  FILE_UPLOAD_SUCCESS: "FILE_UPLOAD_SUCCESS",
  FILE_UPLOAD_FAIL: "FILE_UPLOAD_FAIL",

  GET_CONTENTS_DETAILS_BY_ID: "GET_CONTENTS_DETAILS_BY_ID",
  GET_CONTENTS_DETAILS_BY_ID_SUCCESS: "GET_CONTENTS_DETAILS_BY_ID_SUCCESS",
  GET_CONTENTS_DETAILS_BY_ID_FAIL: "GET_CONTENTS_DETAILS_BY_ID_FAIL",
};

export default Actions;
