import React from "react";
import PropTypes from "prop-types";

// framework
import { Form } from "react-bootstrap";

// plugin
// import { DatePicker } from "@y0c/react-datepicker";
import * as dayjs from "dayjs";

//plugin
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

// style
import "../input-date-time.scss";

const InputTime = (props) => {
  
  const handleChange = (date) => {
    // Day.js object
    props.onChange(date);
  };

  return (
    <Form.Group
      controlId={props.id}
      className={`${props.containerStyle} form-group`}
    >
      {props.label && (
        <Form.Label className={`${props.labelStyle}`}>{props.label}</Form.Label>
      )}
      <div
        className={`form-date-wrap ${props.isInvalid ? "is-invalid" : ""} ${
          props.isValid ? "is-valid" : ""
        }`}
      >
        {/* <DatePicker
          initialDate={dayjs(props.initialDate)}
          onChange={handleChange}
          showDefaultIcon={true}
          readOnly={props.readOnly}
          disabled={props.disabled}
          placeholder={props.placeholder}
          onClear={(data) => {
            console.log(data, "asd");
          }}
          clear={!props.disabled && !props.readOnly}
          showTimeOnly={true}
        /> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["TimePicker"]}>
            <TimePicker 
              label={props.label} 
              value={props.initialDate ? dayjs(props.initialDate) : null}
              onChange={handleChange}
            />
          </DemoContainer>
        </LocalizationProvider>
      </div>
      {props.helperText && (
        <Form.Control.Feedback
          type={`${props.isValid && "valid"} ${props.isInvalid && "invalid"}`}
        >
          {props.helperText}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

/**
 * label: set label text
 * id: set element id
 * placeholder: placeholder of input
 * initialDate: Initial display date
 * dateFormat: set date format
 * style: Manually style the control
 * labelStyle: Manually style the label
 * containerStyle: Manually style the wrapper
 * helperText:  providing helper text
 * disabled: Make the control disabled
 * isValid : Manually style the input as valid
 * isInvalid : Manually style the input as invalid
 * readOnly : Readlonly element
 * onChange: handlers from firing regardless of the rendered element.
 */

InputTime.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.string,
  labelStyle: PropTypes.string,
  // initialDate: PropTypes.object || PropTypes.string,
  dateFormat: PropTypes.string,
  containerStyle: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

InputTime.defaultProps = {
  label: "",
  id: "",
  placeholder: "",
  style: "",
  labelStyle: "",
  initialDate: null,
  dateFormat: "YYYY/MM/DD", // YYYY/MM/DD HH:MM A
  containerStyle: "",
  helperText: "",
  disabled: false,
  isValid: false,
  isInvalid: false,
  readOnly: false,
  required: false,
  includeTime: false,
  onChange: () => {},
};

export default InputTime;
