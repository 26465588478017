import { all } from "redux-saga/effects";
import bannerSaga from "./screens/banner/saga/banner.saga";
import serviceSaga from "./screens/programs/saga/service.saga";
import eventSaga from "./screens/events/saga/events.saga";
import homeSaga from "./screens/home/saga/home.saga";
import notificationSaga from "./screens/push-notifications/saga/notifications.saga";
import loginSaga from "./screens/auth/login/saga/login.saga";

export default function* rootSaga() {
  yield all([
    ...bannerSaga,
    ...serviceSaga,
    ...eventSaga,
    ...homeSaga,
    ...notificationSaga,
    ...loginSaga,
  ]);
}
