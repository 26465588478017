/* eslint-disable no-unused-vars */
/* eslint-disable react/style-prop-object */
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// framework
import { Container, Row, Col } from "react-bootstrap";

// styles

// components
import { Btn, FeatureProgramsCard, BreadCrumb } from "../../../components";

// utils
import { SERVICE_GUIDELINE_LIST } from "../../../utils/service";

const ServicesAndGuidelineList = () => {
  const location = useLocation();

  const paramVar = location.param;
  const [param, setParam] = useState(paramVar);
  const [sortByValue, setSortByValue] = useState("");

  const breadcrumb = [
    {
      label: "How To's",
      href: "/service/list",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">How To`S</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/home", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row">
              <Row>
                {SERVICE_GUIDELINE_LIST.map((item, index) => {
                  return (
                    <Col
                      sm={12}
                      md={12}
                      lg={{ span: 5, offset: index % 2 ? 2 : 0 }}
                      key={index}
                    >
                      <div
                        className="feature-content-row no-border pb-0"
                        key={index}
                      >
                        <FeatureProgramsCard
                          title={item.title}
                          subTitle={item.count + " listing"}
                          icon={<i className={item.icon}></i>}
                          count={index}
                          onClick={() => {
                            navigation("/service/category", item);
                          }}
                          actions={
                            <React.Fragment>
                              <Btn
                                variant="link"
                                icon={<span className="icon-View"></span>}
                                style={"btn-icon"}
                              />
                            </React.Fragment>
                          }
                        />
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ServicesAndGuidelineList;
