import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// framework
import { Container, Row, Col, Form } from "react-bootstrap";

// components
import { Btn, BreadCrumb, Input } from "../../components";

// utils

const ChangePassword = () => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  // const location = useLocation();

  const breadcrumb = [
    {
      label: "Change Password",
      href: "/change-password",
      active: true,
    },
  ];

  useEffect(() => {}, []);

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Change Password</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/home", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row">
              <Form className="form-wrap">
                <Row>
                  <Col lg={6} sm={12}>
                    <Input
                      label="Current Password"
                      type={showCurrentPassword ? "input" : "password"}
                      id="current-password"
                      onChange={(data) => {
                        //console.log(data.target.value, "--- input");
                      }}
                      containerStyle={"mb-3"}
                    />
                    <Form.Group
                      className="form-group"
                      controlId="show-current-password"
                    >
                      <Form.Check
                        type="checkbox"
                        label="Show Password"
                        defaultChecked={showCurrentPassword}
                        onChange={(event) =>
                          setShowCurrentPassword(!showCurrentPassword)
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} sm={12}>
                    <Input
                      label="New Password"
                      type={showNewPassword ? "input" : "password"}
                      id="new-password"
                      onChange={(data) => {
                        //console.log(data.target.value, "--- input");
                      }}
                      containerStyle={"mb-3"}
                    />
                    <Form.Group
                      className="form-group"
                      controlId="show-new-password"
                    >
                      <Form.Check
                        type="checkbox"
                        label="Show Password"
                        defaultChecked={showNewPassword}
                        onChange={(event) =>
                          setShowNewPassword(!showNewPassword)
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} sm={12}>
                    <Input
                      label="Re-Type New Password"
                      type={"password"}
                      id="renew-password"
                      onChange={(data) => {
                        //console.log(data.target.value, "--- input");
                      }}
                    />
                  </Col>
                </Row>
              </Form>
            </div>
            <div className="screen-view-row">
              <Row>
                <Col lg={12} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Cancel"
                      onClick={() => {
                        navigation("/home", "");
                      }}
                    />
                    <Btn
                      variant="primary"
                      size={"lg"}
                      label="Submit"
                      onClick={() => {}}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ChangePassword;
