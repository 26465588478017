//development
const DEV_URL = "http://leecounty-hvs-reach.infojiniconsulting.com/api/v1";

//staging
const STAGING_URL = "http://leecounty-hvs-reach-staging.infojiniconsulting.com/api/v1";

//production
const PROD_URL = "https://hvsreach.infojiniconsulting.com/api/v1";

//using one will be added here
export const API_URL = PROD_URL;
