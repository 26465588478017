/* eslint-disable react/style-prop-object */
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  BreadCrumb,
  Btn,
  Dialog,
  DisplayCoverPicture,
} from "../../../components";
import { deleteContentDispatch, getContentData } from "../store/dispatchers";
import LoadingSpinner from "../../../components/LoadingSpinner";

function ProgramsCategoryContent({
  getContentData,
  contentList,
  deleteContentDispatch,
  deleteContentDataSuccess,
  contentDataInProgress,
  getPostContentDataLoading,
  getPostContentDataSuccess,
  getUpdateContentDataLoading,
  getUpdateContentDataSuccess,
  deleteContentDataLoading,
}) {
  const location = useLocation();
  const paramVar = location.param;
  const [param, setParam] = useState(paramVar);
  const [categoryName, setCategoryName] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const baseUrl = `/services/${categoryName}`;
  const addUrl = `${baseUrl}/subcategory/${categoryType}/add/content`;
  const editUrl = `${baseUrl}/subcategory/${categoryType}`;
  const [modalDelete, setModalDelete] = useState(false);
  const [contentId, setContentId] = useState(null);
  const [getCategoryType, setGetCategoryType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const pathnames = location.pathname.split("/");

  const serviceData = JSON.parse(localStorage.getItem("serviceData"));

  const findTitle = (service) => {
    return service.name === pathnames[2];
  };

  const par = serviceData?.data.services.find(findTitle);

  const modifiedParam = par?.title.en;

  const breadcrumb = [
    {
      label: "Services",
      href: "/services/list",
      active: false,
    },
    {
      label: modifiedParam,
      href: `${baseUrl}/category`,
      active: false,
    },
    {
      label: categoryType,
      href: `${baseUrl}/subcategory`,
      active: true,
    },
  ];

  useEffect(() => {
    let pathname = location.pathname.split("/");
    const categoryName = pathname[2];
    const categoryType = pathname[5];
    const serviceType = pathname[4];

    setCategoryName(categoryName);
    setCategoryType(categoryType);

    const modifiedCategoryType = categoryType
      .replace(/\s(.)/g, function ($1) {
        return $1.toUpperCase();
      })
      .replace(/\s/g, "")
      .replace(/^(.)/, function ($1) {
        return $1.toLowerCase();
      });

    setGetCategoryType(modifiedCategoryType);
    getContentData(modifiedCategoryType, serviceType);

    // if (deleteContentDataSuccess) {
    //   window.location.reload();
    // }
  }, [location, deleteContentDataSuccess, getContentData]);

  useEffect(() => {
    if (contentDataInProgress) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [contentDataInProgress]);

  useEffect(() => {
    if (
      getPostContentDataLoading ||
      getUpdateContentDataLoading ||
      deleteContentDataLoading
    ) {
      setIsLoading(true);
    } else if (
      getPostContentDataSuccess ||
      getUpdateContentDataSuccess ||
      deleteContentDataSuccess
    ) {
      setTimeout(function () {
        setIsLoading(false);
      }, 8000);
    }
  }, [
    deleteContentDataLoading,
    deleteContentDataSuccess,
    getPostContentDataLoading,
    getPostContentDataSuccess,
    getUpdateContentDataLoading,
    getUpdateContentDataSuccess,
  ]);

  const onContentDelete = () => {
    return deleteContentDispatch(getCategoryType, contentId);
  };

  const onContentEdit = (id) => {
    return navigation(`${editUrl}/${id}/edit/content`, "");
  };

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  return (
    <React.Fragment>
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row ">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Details</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() =>
                        navigation(`${baseUrl}/category`, categoryName)
                      }
                    />
                    <Btn
                      variant="primary"
                      size={"lg"}
                      label="Add New"
                      onClick={() => navigation(addUrl, param)}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            {isLoading ? (
              <LoadingSpinner />
            ) : contentList && contentList.length > 0 ? (
              contentList.map((item, index) => {
                const DisplayTitle =
                  item?.title.en[0].toUpperCase() + item?.title.en.substring(1);
                return (
                  <div className="screen-view-row " key={index}>
                    <Row>
                      {item.imageUrl && (
                        <Col sm={3}>
                          <DisplayCoverPicture src={item.imageUrl} />
                        </Col>
                      )}
                      <Col>
                        <div className="table-wrap">
                          <div className="screen-title-wrap d-flex align-self-center screen-justify">
                            <div className="text-wrap">
                              <p className="subtitle1">{DisplayTitle}</p>
                              <div className="itemsDetails">
                                {item.description && (
                                  <p
                                    className="descriptionItem"
                                    dangerouslySetInnerHTML={{
                                      __html: item.description.en,
                                    }}
                                  ></p>
                                )}
                              </div>
                              {item.url && item.mediaType === "link" ? (
                                <p className="subtitle2">
                                  Website URL:{" "}
                                  <a
                                    href={item.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {item.url}
                                  </a>
                                </p>
                              ) : item.mediaType === "attachment" ? (
                                "Attachment available"
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="btn-wrap category-btn">
                              <Btn
                                variant="secondary"
                                icon={<span className="icon-Edit"></span>}
                                onClick={(_) => onContentEdit(item.id)}
                              />
                              <Btn
                                variant="secondary"
                                icon={<span className="icon-Delete-2"></span>}
                                style={"btn-icon btn-link-danger"}
                                onClick={() => {
                                  setModalDelete(true);
                                  setContentId(item.id);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })
            ) : (
              <div className="no-data">
                No Data Found. Click on 'ADD NEW' to create.
              </div>
            )}
          </div>
        </Container>
        <Dialog
          title={"Delete Content"}
          description={<p>Are you sure you want to delete this content?</p>}
          centered={true}
          show={modalDelete}
          close={(val) => {
            setModalDelete(val);
          }}
          actions={
            <div className="btn-wrap d-flex justify-content-end">
              <Btn
                variant="secondary"
                label="No"
                onClick={() => {
                  setModalDelete(false);
                }}
              />
              <Btn
                variant="primary"
                label="Yes"
                onClick={() => {
                  setModalDelete(false);
                  onContentDelete();
                }}
              />
            </div>
          }
        />
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = ({ program }) => {
  return {
    contentList: program.contentDetails,
    deleteContentData: program.deleteContentData,
    deleteContentDataSuccess: program.deleteContentDataSuccess,
    contentDataInProgress: program.contentDataInProgress,
    getPostContentDataLoading: program.getPostContentDataLoading,
    getPostContentDataSuccess: program.getPostContentDataSuccess,
    getUpdateContentDataLoading: program.getUpdateContentDataLoading,
    getUpdateContentDataSuccess: program.getUpdateContentDataSuccess,
    deleteContentDataLoading: program.deleteContentDataLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContentData: (modifiedCategoryType, ServiceType) =>
      dispatch(getContentData(modifiedCategoryType, ServiceType)),
    deleteContentDispatch: (getCategoryType, contentId) =>
      dispatch(deleteContentDispatch(getCategoryType, contentId)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProgramsCategoryContent);
