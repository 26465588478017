import { call, put, takeLatest } from "redux-saga/effects";
import Actions from "../store/actions";
import API from "../../../utils/Api";
import toastr from "../../../utils/Toastr";

function serviceAPICall() {
  return API.get("/cms/services");
}

function postServiceAPI({ data, serviceType }) {
  return API.post(`/cms/items/${serviceType}`, data);
}

function postContentAPI({ data, serviceType }) {
  return API.post(`/cms/contents/${serviceType}`, data);
}

function putServiceApi({ data, serviceType, editId }) {
  return API.put(`/cms/items/${serviceType}/${editId}`, data);
}

function putContentApi({ data, serviceType, editId }) {
  return API.put(`/cms/contents/${serviceType}/${editId}`, data);
}

function deleteServiceApi({ serviceType, id }) {
  return API._delete(`/cms/items/${serviceType}/${id}`);
}

function deleteContentApi({ serviceType, id }) {
  return API._delete(`/cms/contents/${serviceType}/${id}`);
}

function serviceCategoryApiCall({ categoryName, categoryType }) {
  return API.get(`/cms/${categoryName}/${categoryType}`);
}

function serviceDetailsByIdApiCall({ categoryName, categoryType, id }) {
  return API.get(`/cms/${categoryName}/${categoryType}/${id}`);
}

function getAttachmentApiCall(fileUuid) {
  return API.get(`/cms/files/${fileUuid}`);
}

function uploadFileApiCall(details) {
  return API.post("/cms/files/upload", details);
}

function removeAttachmentApiCall(fileUuid) {
  return API._delete(`/cms/files/${fileUuid}`);
}

function* watchContentDetailsByIdSync({ data }) {
  try {
    const details = yield call(serviceDetailsByIdApiCall, data);
    yield put({ type: Actions.GET_CONTENTS_DETAILS_BY_ID_SUCCESS, details });
  } catch (error) {
    yield put({ type: Actions.GET_CONTENTS_DETAILS_BY_ID_FAIL, error });
    toastr.error(error.message);
  }
}

function* watchItemDetailsByIdSync({ data }) {
  try {
    const details = yield call(serviceDetailsByIdApiCall, data);
    yield put({ type: Actions.GET_ITEM_DETAILS_BY_ID_SUCCESS, details });
  } catch (error) {
    yield put({ type: Actions.GET_ITEM_DETAILS_BY_ID_FAIL, error });
    toastr.error(error.message);
  }
}

function* watchUploadCoverPhotoSync({ data }) {
  const details = yield call(uploadFileApiCall, data);
  if (details) {
    yield call(getAttachmentApiCall, details.data.uuid);
  }
  try {
    yield put({ type: Actions.UPLOAD_COVER_PHOTO_SUCCESS, details });
  } catch (error) {
    yield put({ type: Actions.UPLOAD_COVER_PHOTO_FAIL, error });
    toastr.error(error.message);
  }
}

function* watchRemoveCoverPhotoSync({ data }) {
  try {
    const details = yield call(removeAttachmentApiCall, data);
    if (details) {
      yield put({ type: Actions.REMOVE_COVER_PHOTO_SUCCESS });
    }
    toastr.success(details.data.message);
  } catch (error) {
    yield put({ type: Actions.REMOVE_COVER_PHOTO_FAIL, error });
    toastr.error(error.message);
  }
}

function* watchServiceAsync() {
  try {
    const details = yield call(serviceAPICall);
    yield put({ type: Actions.GET_SERVICE_DATA_SUCCESS, details });
  } catch (error) {
    yield put({ type: Actions.GET_SERVICE_DATA_FAIL, error });
    toastr.error(error.message);
  }
}

function* watchPostServiceAsync(data) {
  try {
    const details = yield call(postServiceAPI, data);
    yield put({ type: Actions.GET_POST_DATA_SUCCESS, details });
    toastr.success("Item Added Successfully");
  } catch (error) {
    yield put({ type: Actions.GET_POST_DATA_FAIL, error });
    toastr.error(error.message);
  }
}

function* watchPostContentAsync(data) {
  try {
    const details = yield call(postContentAPI, data);
    yield put({ type: Actions.GET_POST_CONTENT_DATA_SUCCESS, details });
    toastr.success("Content Added Successfully");
  } catch (error) {
    yield put({ type: Actions.GET_POST_CONTENT_DATA_FAIL, error });
    toastr.error(error.message);
  }
}

function* watchPutServiceAsync(data) {
  try {
    const details = yield call(putServiceApi, data);
    yield put({
      type: Actions.GET_UPDATE_DATA_SUCCESS,
      details,
    });
    toastr.success("Item Updated Successfully");
  } catch (error) {
    yield put({
      type: Actions.GET_UPDATE_DATA_FAIL,
      error,
    });
    toastr.error(error.message);
  }
}

function* watchPutContentAsync(data) {
  try {
    const details = yield call(putContentApi, data);
    yield put({
      type: Actions.GET_UPDATE_CONTENT_DATA_SUCCESS,
      details,
    });
    setTimeout(function () {
      toastr.success("Content Updated Successfully");
    }, 1000);
  } catch (error) {
    yield put({
      type: Actions.GET_UPDATE_CONTENT_DATA_FAIL,
      error,
    });
    toastr.error(error.message);
  }
}

function* watchFileAttachmentSync(details) {
  const data = yield call(uploadFileApiCall, details.data);
  try {
    yield put({ type: Actions.FILE_UPLOAD_SUCCESS, data });
  } catch (error) {
    yield put({ type: Actions.FILE_UPLOAD_FAIL, error });
    toastr.error(error.message);
  }
}

function* watchDeleteServiceAsync(data) {
  try {
    const details = yield call(deleteServiceApi, data);
    yield put({
      type: Actions.DELETE_DATA_SUCCESS,
      details,
    });
    toastr.success("Item Deleted Successfully");
  } catch (error) {
    yield put({
      type: Actions.DELETE_DATA_FAIL,
      error,
    });
    toastr.error(error.message);
  }
}

function* watchDeleteContentAsync(data) {
  try {
    yield call(deleteContentApi, data);
    yield put({
      type: Actions.DELETE_CONTENT_DATA_SUCCESS,
    });
    setTimeout(function () {
      toastr.success("Content Deleted Successfully");
    }, 2000);
  } catch (error) {
    yield put({
      type: Actions.DELETE_CONTENT_DATA_FAIL,
      error,
    });
    setTimeout(function () {
      toastr.error(error);
    }, 1000);
  }
}

function* watchFetchItemSync({ modifiedCategoryType, ServiceType }) {
  const data = {
    categoryName: ServiceType,
    categoryType: modifiedCategoryType,
  };
  const details = yield call(serviceCategoryApiCall, data);
  try {
    yield put({ type: Actions.FETCH_ITEMS_LIST_SUCCESS, details });
  } catch (error) {
    yield put({ type: Actions.FETCH_ITEMS_LIST_FAIL, error });
  }
}
function* watchFetchContentSync({ modifiedCategoryType, ServiceType }) {
  const data = {
    categoryName: ServiceType,
    categoryType: modifiedCategoryType,
  };
  const details = yield call(serviceCategoryApiCall, data);
  try {
    yield put({ type: Actions.GET_CONTENT_DATA_SUCCESS, details });
  } catch (error) {
    yield put({ type: Actions.GET_CONTENT_DATA_FAIL, error });
  }
}

const serviceSaga = [
  takeLatest(Actions.GET_SERVICE_DATA, watchServiceAsync),
  takeLatest(Actions.FETCH_ITEMS_LIST, watchFetchItemSync),
  takeLatest(Actions.GET_CONTENT_DATA, watchFetchContentSync),
  takeLatest(Actions.GET_POST_DATA, watchPostServiceAsync),
  takeLatest(Actions.GET_UPDATE_DATA, watchPutServiceAsync),
  takeLatest(Actions.DELETE_DATA, watchDeleteServiceAsync),
  takeLatest(Actions.FILE_UPLOAD, watchFileAttachmentSync),
  takeLatest(Actions.GET_POST_CONTENT_DATA, watchPostContentAsync),
  takeLatest(Actions.GET_UPDATE_CONTENT_DATA, watchPutContentAsync),
  takeLatest(Actions.DELETE_CONTENT_DATA, watchDeleteContentAsync),
  takeLatest(Actions.UPLOAD_COVER_PHOTO, watchUploadCoverPhotoSync),
  takeLatest(Actions.REMOVE_COVER_PHOTO, watchRemoveCoverPhotoSync),
  takeLatest(Actions.GET_ITEM_DETAILS_BY_ID, watchItemDetailsByIdSync),
  takeLatest(Actions.GET_CONTENTS_DETAILS_BY_ID, watchContentDetailsByIdSync),
];

export default serviceSaga;
