import { takeLatest, call, put } from "redux-saga/effects";
import API from "../../../../utils/Api";
import Actions from "../store/actions";
import Toastr from "../../../../utils/Toastr";

function loginApiCall(details) {
  return API.post("/login", details);
}

function getLoginDataApiCall(details) {
  return API.get("", details);
}

function* watchLoginAsync(data) {
  try {
    const details = yield call(loginApiCall, data.data);
    yield put({
      type: Actions.UPDATE_LOGIN_FORM_SUCCESS,
      details,
    });
    Toastr.success(`Login successful !!!`);
  } catch (error) {
    Toastr.error(error?.message);
    yield put({
      type: Actions.ON_RESET_STATE,
    });
    yield put({
      type: Actions.UPDATE_LOGIN_FORM_FAIL,
      data: error,
    });
  }
}

function* watchGetLoginAsync(data) {
  try {
    const details = yield call(getLoginDataApiCall, data.data);
    yield put({
      type: Actions.GET_LOGIN_FETCH_SUCCESS,
      details,
    });
  } catch (errors) {
    yield put({
      type: Actions.GET_LOGIN_FETCH_FAIL,
      data: errors,
    });
  }
}

const loginSaga = [
  takeLatest(Actions.UPDATE_LOGIN_FETCH, watchLoginAsync),
  takeLatest(Actions.GET_LOGIN_FETCH, watchGetLoginAsync),
];
export default loginSaga;
