import React, { useState, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { Row, Col } from 'react-bootstrap'
import { Form } from "react-bootstrap";
import JoditEditor from "jodit-react";
import "./editor.scss";
import Error from "../../utils/Error";
import htmlToPlainText from "html2plaintext"

const Editor = (props) => {
  // const editor = useRef(props.defaultValue);
  const content = htmlToPlainText(props.defaultValue);
  

  // editor config
  const config = {
    readonly: props.readOnly || false,
    height: 400,
    disablePlugins: "powered-by-jodit, sticky",
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    placeholder: props.placeholder,
    limitChars: 1000,
    // enter:"br",
    removeButtons: ['brush', 'file'],
    cleanHTML: {
      fillEmptyParagraph: false,

    },
    addNewLineOnDBLClick: false,
    uploader: {
      url: "http://localhost:8181/index-test.php?action=fileUpload",
    },
    filebrowser: {
      ajax: {
        url: "http://localhost:8181/index-test.php",
      },
    },
    beautyHTML: true,
    "showCharsCounter": true,
    // "showWordsCounter": false,
    // "showXPathInStatusbar": false
  };

  // handle on change of input
  const handleChange = (editorContent) => {
    let modifiedContent = `<!DOCTYPE HTML> <html><head><meta charset="utf-8" /><title></title></head> <body>${editorContent.trim()}</body> </html>`;
    props.onBlur(modifiedContent);
    // setContent(modifiedContent);
  };

  //using useMemo in order to get the onChange value
  const Edit = useMemo(() => {
    return (
      <JoditEditor
        // ref={editor}
        value={content}
        config={config}
        // onBlur={handleChange}
        onChange={(content) => props.getDescriptionValue(content)}
      />
    );
  }, []);

  return (
    <Form.Group
      controlId={props.id}
      className={`${props.containerStyle} ${props.readOnly && "form-readonly"
        } ${props.isInvalid && "form-invalid"} form-group`}
    >
      <Row>
        <Col lg="8">
          {props.label && (
            <Form.Label
              className={`${props.labelStyle} ${props.showLength ? "form-character-limit" : ""
                }`}
            >
              {props.label}
              {props.span ? <span className="error-msg">{props.span}</span> : ""}
            </Form.Label>
          )}
        </Col>
        <Col lg="4">
          <p className="title">
            {1000 - content.length} out of 1000 characters left
          </p>
        </Col>
      </Row>
      <div className="editor-wrap">
        {/* <JoditEditor
          ref={editor}
          value={content}
          config={config}
          // onBlur={handleChange}
          onChange={(content) => setContent(content)}
        /> */}
        {Edit}
      </div>
      {props.helperText && (
        <Form.Control.Feedback
          type={`${props.isValid && "valid"} ${props.isInvalid && "invalid"}`}
        >
          {props.helperText}
        </Form.Control.Feedback>
      )}

      {props.meta && props.defaultValue === "" ? (
        <Error
          show={props.meta.touched && !!props.meta.error}
          text={props.meta.error}
        />
      ) : null}
    </Form.Group>
  );
};

/**
 * label: set label text
 * id :  set element id
 * placeholder: placeholder of input
 * defaultValue: set defaultValue
 * style: Manually style the control
 * labelStyle: Manually style the label
 * containerStyle: Manually style the wrapper
 * helperText:  providing helper text
 * disabled: Make the control disabled
 * isValid : Manually style the input as valid
 * isInvalid : Manually style the input as invalid
 * readOnly : Readlonly element
 * required : set is required or not
 * onBlur: handlers from firing regardless of the rendered element.
 */

Editor.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  style: PropTypes.string,
  labelStyle: PropTypes.string,
  containerStyle: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  onBlur: PropTypes.func,
};

Editor.defaultProps = {
  label: "",
  id: "",
  placeholder: "",
  defaultValue: "",
  style: "",
  labelStyle: "",
  containerStyle: "",
  helperText: "",
  disabled: false,
  isValid: false,
  isInvalid: false,
  readOnly: false,
  required: false,
  onBlur: () => { },
};

export default Editor;
