import React from "react";
import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { BreadCrumb } from "../../../components";
import NotificationsForm from "../notificationsForm";
import * as dispatcher from "../store/Dispatchers";

const PushNotificationEdit = ({
  getNotificationByIdData,
  dispatchNotificationById,
}) => {
  const breadcrumb = [
    {
      label: "Push Notification",
      href: "/notifications/list",
      active: false,
    },
    {
      label: "Edit",
      href: "/push-notification/edit",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  const location = useLocation();
  const pathname = location.pathname.split("/");
  const notifyId = parseInt(pathname[3]);

  useEffect(() => {
    dispatchNotificationById(notifyId);
  }, [dispatchNotificationById, notifyId]);

  return (
    <React.Fragment>
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            {getNotificationByIdData && (
              <NotificationsForm
                navigation={navigation}
                getDetails={getNotificationByIdData}
                formTitle="Edit"
                notifyId={notifyId}
              ></NotificationsForm>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

const MapStateToProps = ({ notification }) => {
  return {
    getNotificationByIdData: notification.getNotificationByIdData,
  };
};

export default connect(MapStateToProps, dispatcher)(PushNotificationEdit);
