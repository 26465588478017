import Actions from "./Actions";

export const dispatchGetNotificationData = () => {
  return {
    type: Actions.GET_NOTIFICATION_DATA,
  };
};

export const dispatchResetState = () => {
  return {
    type: Actions.RESET_STATE,
  };
};

export const dispatchGetSettingsData = () => {
  return {
    type: Actions.GET_SETTINGS_DATA,
  };
};

export const dispatchNotificationById = (id) => {
  return {
    type: Actions.GET_NOTIFICATION_BY_ID,
    id,
  };
};

export const dispatchPostNotificationData = (data) => {
  return {
    type: Actions.POST_NOTIFICATION_DATA,
    data,
  };
};

export const dispatchDeleteNotificationData = (id) => {
  return {
    type: Actions.DELETE_NOTIFICATION_DATA,
    id,
  };
};

export const dispatchUpdateNotificationData = (data, id) => {
  return {
    type: Actions.UPDATE_NOTIFICATION_DATA,
    data,
    id,
  };
};
