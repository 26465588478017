import Actions from "./Actions";

let defaultState = {
  settingDetails: null,
  HomeDetailsLoading: false,
  HomeDetailsSucess: false,
  HomeDetailsFail: false,
};

const homeReducer = (state = defaultState, action) => {
  switch (action.type) {
    case Actions.GET_SETTINGS_DATA:
      return {
        ...state,
        HomeDetailsLoading: true,
        HomeDetailsSucess: false,
        HomeDetailsFail: false,
      };

    case Actions.GET_SETTINGS_DATA_SUCCESS:
      return {
        ...state,
        HomeDetailsLoading: false,
        HomeDetailsSucess: true,
        HomeDetailsFail: false,
        settingDetails: action.details,
      };

    case Actions.GET_SETTINGS_DATA_FAIL:
      return {
        ...state,
        HomeDetailsLoading: false,
        HomeDetailsSucess: false,
        HomeDetailsFail: true,
      };

    default:
      return state;
  }
};

export default homeReducer;
