import Actions from "./actions";

export const getEventsData = () => {
  return {
    type: Actions.GET_EVENTS_DATA,
  };
};

export const dispatchResetState = () => {
  return {
    type: Actions.RESET_STATE,
  };
};

export const getEventsDataById = (id) => {
  return {
    type: Actions.GET_EVENTS_DATA_BY_ID,
    id,
  };
};

export const postEventsData = (data) => {
  return {
    type: Actions.POST_EVENT_DATA,
    data,
  };
};

export const putEventsData = (data, id) => {
  return {
    type: Actions.PUT_EVENT_DATA,
    data,
    id,
  };
};

export const deleteEventsData = (id) => {
  return {
    type: Actions.DELETE_EVENT_DATA,
    id,
  };
};
