import { call, put, takeLatest } from "redux-saga/effects";
import Actions from "../store/Actions";
import API from "../../../utils/Api";

function settingsApiCall() {
  return API.get("/cms/settings");
}

function* watchSettingsAsync() {
  try {
    const details = yield call(settingsApiCall);
    yield put({
      type: Actions.GET_SETTINGS_DATA_SUCCESS,
      details,
    });
  } catch (error) {
    yield put({
      type: Actions.GET_SETTINGS_DATA_FAIL,
      error,
    });
  }
}

const homeSaga = [takeLatest(Actions.GET_SETTINGS_DATA, watchSettingsAsync)];

export default homeSaga;
