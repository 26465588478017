import React, { useEffect, useState } from "react";
import BannerForm from "../bannerForm";
import { Container } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { BreadCrumb } from "../../../components";
import { connect } from "react-redux";
import * as dispatcher from "../store/dispatchers";

const BannerEdit = ({
  resetState,
  bannerDetails,
  getBannerDetails,
  updateBannerData,
  updateBannerSuccess,
  bannerDetailsInProgress,
}) => {
  const location = useLocation();
  const [itemId, setItemId] = useState();
  const breadcrumb = [
    {
      label: "Banners",
      href: "/banners/list",
      active: false,
    },
    {
      label: "Edit",
      href: "/banner/edit",
      active: true,
    },
  ];

  useEffect(() => {
    let pathname = location.pathname.split("/");
    const itemId = pathname[3];
    setItemId(itemId);
    getBannerDetails(parseInt(itemId));
  }, []);

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  useEffect(() => {
    if (updateBannerSuccess) {
      navigation("/banners/list");
      resetState();
    }
  }, [updateBannerSuccess]);

  const onBannerEdit = (data) => {
    updateBannerData(parseInt(itemId), data);
  };

  return (
    <React.Fragment>
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb} />
          <div className="screen-view-wrap">
            {!bannerDetailsInProgress && (
              <BannerForm
                details={bannerDetails}
                formTitle="Edit"
                navigation={navigation}
                onSubmit={onBannerEdit}
              />
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ banner }) => {
  return {
    bannerDetails: banner.bannerDetailsById,
    bannerDetailsInProgress: banner.bannerDetailsInProgress,
    updateBannerSuccess: banner.updateBannerSuccess,
  };
};

export default connect(mapStateToProps, dispatcher)(BannerEdit);
