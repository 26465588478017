import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { FeatureCard } from "../../components";
import "./home.scss";
import { getSettingsData } from "./store/dispatchers";
import { connect } from "react-redux";
import { useEffect } from "react";
import LoadingSpinner from "../../components/LoadingSpinner";

const Home = ({
  dispatchSettingsData,
  settingsData,
  HomeDetailsLoading,
  HomeDetailsSucess,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  function handleClick(link) {
    history.push({
      pathname: link,
    });
  }

  useEffect(() => {
    dispatchSettingsData();
  }, [dispatchSettingsData]);

  useEffect(() => {
    if (HomeDetailsLoading) {
      setIsLoading(true);
    } else if (HomeDetailsSucess) {
      setIsLoading(false);
    }
  }, [HomeDetailsLoading, HomeDetailsSucess]);

  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap login-screen-wrap">
        <Container>
          <Row>
            {isLoading ? (
              <LoadingSpinner></LoadingSpinner>
            ) : (
              settingsData?.data.feature
                .sort((a, b) => (a.sortId > b.sortId ? 1 : -1))
                .map((item, index) => {
                  return (
                    <Col sm={6} lg={3} key={index}>
                      <FeatureCard
                        icon={item.iconUrl}
                        title={item.displayName.en}
                        onClick={() => {
                          handleClick(`/${item.name}/list`);
                        }}
                      />
                    </Col>
                  );
                })
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ home }) => {
  return {
    settingsData: home.settingDetails,
    HomeDetailsLoading: home.HomeDetailsLoading,
    HomeDetailsSucess: home.HomeDetailsSucess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchSettingsData: () => dispatch(getSettingsData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
