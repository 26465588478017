/* eslint-disable no-lone-blocks */
import { useState, useCallback, useEffect } from "react";

// framework
import { ListGroup } from "react-bootstrap";

// plugin
import update from "immutability-helper";

// inner component
import { SequenceListItem } from "./sequence-item";

export const SequenceListGroup = (props) => {
  {
    const [data, setData] = useState(props.data);

    useEffect(() => {
      props.onChange(data);
    }, [data, props]);

    const moveList = useCallback((dragIndex, hoverIndex) => {
      setData((prevList) =>
        update(prevList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevList[dragIndex]],
          ],
        })
      );
    }, []);

    const renderList = useCallback(
      (data, index) => {
        return (
          <SequenceListItem
            key={index}
            index={index}
            id={data.id}
            text={data.title}
            moveList={moveList}
          />
        );
      },
      [moveList]
    );

    return <ListGroup>{data.map((data, i) => renderList(data, i))}</ListGroup>;
  }
};
