import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import {
  Btn,
  Legend,
  Dialog,
  BreadCrumb,
  FeatureContentCard,
  DisplayCoverPicture,
} from "../../../components";
import { connect } from "react-redux";
import { getBannerDetails, deleteBannerById } from "../store/dispatchers";
import "../banner.scss";

const BannerDetail = ({
  getBannerDetails,
  bannerDetails,
  deleteBannerById,
  bannerDeleteSuccess,
}) => {
  const [modalDelete, setModalDelete] = useState(false);
  const location = useLocation();
  const [itemId, setItemId] = useState("");

  const breadcrumb = [
    {
      label: "Banners",
      href: "/banners/list",
      active: false,
    },
    {
      label: "Details",
      href: "/banner/detail",
      active: true,
    },
  ];

  useEffect(() => {
    let pathname = location.pathname.split("/");
    const itemId = pathname[3];
    setItemId(itemId);
    getBannerDetails(parseInt(itemId));
  }, []);

  useEffect(() => {
    if (bannerDeleteSuccess) {
      navigation("/banners/list");
    }
  }, [bannerDeleteSuccess]);

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  function onBannerDelete() {
    return deleteBannerById(parseInt(itemId));
  }

  return (
    <React.Fragment>
      {bannerDetails && (
        <div className="screen-wrap">
          <Container>
            <BreadCrumb data={breadcrumb}></BreadCrumb>
            <div className="screen-view-wrap">
              <div className="screen-view-row">
                <Row>
                  <Col lg={6} sm={12} className="d-flex">
                    <div className="screen-title-wrap d-flex align-self-center">
                      <div className="text-wrap">
                        <p className="subtitle1">Banner Details</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} sm={12}>
                    <div className="btn-wrap d-flex justify-content-end">
                      <Btn
                        variant="secondary"
                        size={"lg"}
                        label="Go Back"
                        onClick={() => navigation("/banners/list", "")}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="screen-view-row no-border">
                <Legend
                  label="Details"
                  actions={
                    <Btn
                      label="Edit"
                      variant="outline-primary"
                      size={"sm"}
                      onClick={() => navigation(`/banner/edit/${itemId}`, "")}
                    />
                  }
                />
              </div>
              <div className="screen-view-row">
                <Row>
                  {bannerDetails?.imageUrl && (
                    <Col sm={3}>
                      <DisplayCoverPicture src={bannerDetails.imageUrl} />
                    </Col>
                  )}

                  <Col sm={9}>
                    <FeatureContentCard
                      title={bannerDetails?.title.en}
                      description={
                        <React.Fragment>
                          <div className="text-wrap d-flex">
                            <div className="subtitle2">
                              {bannerDetails?.link && (
                                <div>
                                  URL:{" "}
                                  <a href={bannerDetails.link}>
                                    {bannerDetails.link}
                                  </a>
                                </div>
                              )}
                              {bannerDetails?.fileDetails && (
                                <div className="download-file">
                                  <div className="file-name">
                                    {bannerDetails.fileDetails.originalName}
                                  </div>
                                  <Link
                                    to={bannerDetails.fileDetails.path}
                                    target="_blank"
                                    download
                                    className="download-icon"
                                  >
                                    Download
                                  </Link>
                                </div>
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      }
                    />
                  </Col>
                </Row>
              </div>
              <div className="screen-view-row">
                <Row>
                  <Col lg={12} sm={12}>
                    <div className="btn-wrap d-flex justify-content-end">
                      <Btn
                        variant="danger"
                        size={"lg"}
                        label="Delete"
                        onClick={() => {
                          setModalDelete(true);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Container>
          <Dialog
            title={"Delete Banner"}
            description={<p>Are you sure you want to delete the banner?</p>}
            centered={true}
            show={modalDelete}
            close={(val) => {
              setModalDelete(val);
            }}
            actions={
              <div className="btn-wrap d-flex justify-content-end">
                <Btn
                  variant="secondary"
                  label="No"
                  onClick={() => {
                    setModalDelete(false);
                  }}
                />
                <Btn
                  variant="primary"
                  label="Yes"
                  onClick={() => {
                    onBannerDelete();
                  }}
                />
              </div>
            }
          />
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ banner }) => {
  return {
    bannerDetails: banner.bannerDetailsById,
    bannerDeleteSuccess: banner.bannerDeleteSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBannerDetails: (data) => dispatch(getBannerDetails(data)),
    deleteBannerById: (id) => dispatch(deleteBannerById(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerDetail);
