import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import { BreadCrumb } from "../../../components";
import ServicesFormContent from "../ServicesFormContent";

const ProgramsCategoryAddContent = () => {
  const location = useLocation();
  const [categoryName, setCategoryName] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const subCategoryUrl = `/services/${categoryName}/subcategory/contents/${categoryType}`;

  const pathnames = location.pathname.split("/");

  const serviceData = JSON.parse(localStorage.getItem("serviceData"));

  const findTitle = (service) => {
    return service.name === pathnames[2];
  };

  const par = serviceData?.data.services.find(findTitle);

  const modifiedParam = par?.title.en;

  const breadcrumb = [
    {
      label: "Services",
      href: "/services/list",
      active: false,
    },
    {
      label: modifiedParam,
      href: `/services/${categoryName}/category`,
      active: false,
    },
    {
      label: categoryType,
      href: `/services/${categoryName}/subcategory/contents/${categoryType}`,
      active: false,
    },
    {
      label: "Add",
      href: "/services/subcategory/edit",
      active: true,
    },
  ];

  useEffect(() => {
    let pathname = location.pathname.split("/");
    const categoryName = pathname[2];
    const categoryType = pathname[4];
    setCategoryName(categoryName);
    setCategoryType(categoryType);
  });
  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <ServicesFormContent
              name="Add Content"
              navigation={navigation}
              cancelBtnUrl={subCategoryUrl}
            ></ServicesFormContent>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProgramsCategoryAddContent;
