/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
// framework
import { Container } from "react-bootstrap";

// components
import { Btn, BreadCrumb, Dialog } from "../../../components";

import EventForm from "../EventForm/index.js";
import { connect } from "react-redux";
import {
  dispatchResetState,
  getEventsData,
  getEventsDataById,
  putEventsData,
} from "../store/disptahcers";
import moment from "moment";

const EventsEdits = ({
  eventData,
  getEventsData,
  putEventDataDetails,
  putEventsData,
  putEventDataSuccess,
  getEventsDataById,
  eventDataId,
  dispatchResetState,
}) => {
  //formated Data values
  let formatedsrtDate = moment(eventDataId?.data.startDate).format(
    "YYYY-MM-DD"
  );
  let formatedEndDate = moment(eventDataId?.data.endDate).format("YYYY-MM-DD");

  const [modalLocateMap, setModalLocateMap] = useState(false);
  const [eventDuration, setEventDuration] = useState(
    formatedsrtDate === formatedEndDate ? "Single Day" : "Multiple Day"
  );
  const [eventDataById, setEventDataId] = useState("");

  const location = useLocation();

  const paramVar = location.param;
  const [param] = useState(paramVar);
  const [EventsId, setEventsId] = useState("");

  const breadcrumb = [
    {
      label: "Event Calendar",
      href: "/events/list",
      active: false,
    },
    {
      label: "Edit",
      href: "/events/edit",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  useEffect(() => {
    let pathname = location?.pathname.split("/");
    const pathID = parseInt(pathname[3]);
    setEventsId(pathID);
    // getEventsData();
    getEventsDataById(pathID);
    setEventDataId(eventDataId);
  }, [getEventsData, getEventsDataById, location?.pathname]);

  useEffect(() => {
    if (putEventDataSuccess) {
      navigation(`/events/details/${EventsId}`);
      dispatchResetState();
    }
  }, [EventsId, dispatchResetState, putEventDataSuccess]);

  const setEditDetails = eventData?.data?.list.find(
    ({ id }) => parseInt(id) === parseInt(EventsId)
  );

  const onEventsEdit = (data) => {
    putEventsData(data, EventsId);
  };

  // console.log(location ? location.param : "","events edit")
  
  return (
    <React.Fragment>
      {/* <NavHeader /> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            {eventDataId && (
              <EventForm
                setModalLocateMap={setModalLocateMap}
                navigation={navigation}
                setEventDuration={setEventDuration}
                ActionName="Edit Event"
                eventDuration={eventDuration}
                details={eventDataId}
                onSubmit={onEventsEdit}
              ></EventForm>
            )}
          </div>
        </Container>
        <Dialog
          title={"Map"}
          description={
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.618692267389!2d72.8351312153137!3d19.12437675541878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9d8e30e817f%3A0xa53c0adc9279315d!2sDamacloid%20technologies%20private%20limited!5e0!3m2!1sen!2sin!4v1645602980322!5m2!1sen!2sin"
              allowfullscreen=""
              loading="lazy"
              className="w-100"
              style={{ height: "350px" }}
            ></iframe>
          }
          centered={true}
          show={modalLocateMap}
          size={"lg"}
          close={(val) => {
            setModalLocateMap(val);
          }}
          actions={
            <div className="btn-wrap d-flex justify-content-end">
              <Btn
                variant="primary"
                label="Ok"
                onClick={() => {
                  setModalLocateMap(false);
                }}
              />
            </div>
          }
        />
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = ({ event }) => {
  return {
    // eventData: event.eventData,
    eventDataId: event.eventDataId,
    putEventDataDetails: event.putEventDataDetails,
    putEventDataSuccess: event.putEventDataSuccess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getEventsData: () => dispatch(getEventsData()),
    getEventsDataById: (id) => dispatch(getEventsDataById(id)),
    putEventsData: (data, id) => dispatch(putEventsData(data, id)),
    dispatchResetState: () => dispatch(dispatchResetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventsEdits);
