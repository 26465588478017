import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { BreadCrumb } from "../../../components";
import ServicesFormContent from "../ServicesFormContent";
import { useHistory, useLocation } from "react-router-dom";
import * as dispatcher from "../store/dispatchers";

const ProgramsCategoryEditContent = ({
  contentDetailsById,
  contentDetailsByIdInProgress,
  getContentsDetailsById,
}) => {
  const location = useLocation();
  const [categoryName, setCategoryName] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const subCategoryUrl = `/services/${categoryName}/subcategory/contents/${categoryType}`;

  const breadcrumb = [
    {
      label: "Services",
      href: "/services/list",
      active: false,
    },
    {
      label: categoryName,
      href: `/services/${categoryName}/category`,
      active: false,
    },
    {
      label: categoryType,
      href: subCategoryUrl,
      active: false,
    },
    {
      label: "Edit",
      href: "/services/subcategory/edit",
      active: true,
    },
  ];

  useEffect(() => {
    let pathname = location.pathname.split("/");
    const categoryName = pathname[2];
    const categoryType = pathname[4];
    const ItemId = pathname[5];

    setCategoryName(categoryName);
    setCategoryType(categoryType);

    const _categoryType = categoryType
      .replace(/\s(.)/g, function ($1) {
        return $1.toUpperCase();
      })
      .replace(/\s/g, "")
      .replace(/^(.)/, function ($1) {
        return $1.toLowerCase();
      });
    getContentsDetailsById({
      categoryName: "contents",
      categoryType: _categoryType,
      id: ItemId,
    });
  }, []);

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  return (
    <React.Fragment>
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            {!contentDetailsByIdInProgress && (
              <ServicesFormContent
                name="Edit Content"
                navigation={navigation}
                getDetails={contentDetailsById}
                cancelBtnUrl={subCategoryUrl}
              ></ServicesFormContent>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = ({ program }) => {
  return {
    contentDetailsById: program.contentDetailsById,
    contentDetailsByIdInProgress: program.contentDetailsByIdInProgress,
  };
};

export default connect(
  mapStateToProps,
  dispatcher
)(ProgramsCategoryEditContent);
