/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as dispatcher from "../store/Dispatchers.js";

// framework
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment";

// styles

// components
import {
  Btn,
  Paginator,
  FeatureContentCard,
  BreadCrumb,
  Select,
  Filters,
  Dialog,
} from "../../../components";

// utils
import {
  SORTBY_OPTIONS,
  PUSHNOTIFICATION_FILTER_OPTIONS,
} from "../../../utils/service";
import { connect } from "react-redux";
import { useEffect } from "react";
import LoadingSpinner from "../../../components/LoadingSpinner/index.js";

const PushNotificationList = ({
  getNotificationData,
  dispatchGetNotificationData,
  getNotificationDataLoading,
  postNotificationDataSuccess,
  postNotificationDataLoading,
  deleteNotificationDataLoading,
  deleteNotificationDataSuccess,
  dispatchDeleteNotificationData,
  dispatchResetState,
}) => {
  const location = useLocation();

  const paramVar = location.param;
  const [param, setParam] = useState(paramVar);
  const [sortByValue, setSortByValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [getId, setGetId] = useState("");

  const breadcrumb = [
    {
      label: "Push Notification",
      href: "/push-notification/list",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  useEffect(() => {
    dispatchGetNotificationData();
  }, [dispatchGetNotificationData, deleteNotificationDataSuccess]);

  useEffect(() => {
    if (getNotificationDataLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [getNotificationDataLoading]);

  useEffect(() => {
    if (deleteNotificationDataSuccess) {
      // window.location.reload();
      navigation("/notifications/list");
    }
  }, [deleteNotificationDataSuccess]);

  // useEffect(() => {
  //   if (postNotificationDataLoading || deleteNotificationDataLoading) {
  //     setIsLoading(true);
  //   } else if (postNotificationDataSuccess || deleteNotificationDataSuccess) {
  //     setIsLoading(false);
  //   }
  // }, [
  //   deleteNotificationDataLoading,
  //   deleteNotificationDataSuccess,
  //   postNotificationDataLoading,
  //   postNotificationDataSuccess,
  // ]);

  const onDelete = () => {
    setModalDelete(false);
    dispatchDeleteNotificationData(getId);
  };

  //console.log(getNotificationData);

  return (
    <React.Fragment>
      {/* <NavHeader/> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={6} className="d-flex  ">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">
                        Push Notifications{" "}
                        {/* <span className="caption">( 1 - 10 of 138 )</span> */}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={6}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/home", "")}
                    />
                    <Btn
                      variant="primary"
                      size={"lg"}
                      label="Add New"
                      onClick={() => {
                        navigation("/push-notification/add", "");
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            {/* <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap align-self-center">
                      <p className="caption">Sort By</p>
                    </div>
                    <Select
                      id="sort_by"
                      data={SORTBY_OPTIONS}
                      defaultValue={sortByValue}
                      onChange={(val) => {
                        setSortByValue(val);
                      }}
                    />
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="d-flex justify-content-end">
                    <Paginator />
                  </div>
                </Col>
              </Row>
            </div> */}
            <div className="screen-view-row">
              <Row>
                {/* <Col lg={3} sm={12} className="d-flex">
                  <div className="accordion-wrap">
                    <div className="accordion-title">
                      <div className="text-wrap">
                        <p className="subtitle1">Filters</p>
                      </div>
                    </div>
                    <Filters
                      data={PUSHNOTIFICATION_FILTER_OPTIONS}
                      onChange={(value) => {
                        console.log("val:", value);
                      }}
                      onDateChange={(start, end) => {
                        console.log("val:", start, end);
                      }}
                    />
                  </div>
                </Col> */}
                <Col lg={12} sm={12}>
                  {/* className="screen-view-left-divider" - To be added in the div below */}
                  <div>
                    <div className="">
                      {isLoading ? (
                        <LoadingSpinner></LoadingSpinner>
                      ) : getNotificationData &&
                        getNotificationData.data?.list?.length > 0 ? (
                        getNotificationData.data.list.map((item, index) => {
                          // let data = new Date(item.sentOn);
                          // let hrs = data.getHours();
                          // let amPm = hrs >= 12 ? "pm" : "am"
                          // hrs = hrs % 12;
                          // hrs = hrs ? hrs : 12
                          // let mins = data.getMinutes() + 5;
                          // mins = mins < 10 ? '0'+mins : mins;
                          return (
                            <div className="feature-content-row" key={index}>
                              <FeatureContentCard
                                title={item.title.en}
                                subTitle={item.plainDescription.en}
                                description={
                                  <React.Fragment>
                                    <div className="text-wrap">
                                      <p className="subtitle2">
                                        <strong>For : </strong>{" "}
                                        {item.notificationFor
                                          .replace(/([A-Z])/g, " $1")
                                          // uppercase the first character
                                          .replace(/^./, function (str) {
                                            return str.toUpperCase();
                                          })}
                                      </p>
                                    </div>
                                    <div className="text-wrap">
                                      <p className="subtitle2 title">
                                        <strong>Created : </strong>{" "}
                                        {moment(item.createdOn).format(
                                          "MM-DD-YYYY, h:mm a"
                                        )}
                                      </p>
                                    </div>
                                    <div className="text-wrap d-flex">
                                      <p className="subtitle2 title">
                                        <strong>Status : </strong> {item.status}{" "}
                                        On{" "}
                                        {moment(item.sentOn).format(
                                          "MM-DD-YYYY, hh:mm a"
                                        )}{" "}
                                        {""}
                                      </p>
                                    </div>
                                  </React.Fragment>
                                }
                                actions={
                                  <React.Fragment>
                                    {/* <Btn
                                    variant="secondary"
                                    icon={
                                      <span className="icon-Duplicate"></span>
                                    }
                                    onClick={() => {
                                      navigation("/push-notification/add", "");
                                    }}
                                  /> */}
                                    <Btn
                                      variant="secondary"
                                      icon={<span className="icon-View"></span>}
                                      onClick={() => {
                                        navigation(
                                          `/notifications/details/${item.id}`
                                        );
                                      }}
                                    />
                                    {
                                      <Btn
                                        variant="secondary"
                                        icon={
                                          <span className="icon-Delete-2"></span>
                                        }
                                        style={"btn-icon btn-link-danger"}
                                        onClick={() => {
                                          setModalDelete(true);
                                          setGetId(item.id);
                                        }}
                                      />
                                    }
                                  </React.Fragment>
                                }
                              />
                            </div>
                          );
                        })
                      ) : (
                        <div className="no-data">
                          No Data Found. Click on 'ADD NEW' to create.
                        </div>
                      )}
                    </div>
                    {/* <div className="d-flex justify-content-end">
                      <Paginator />
                    </div> */}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>

        <Dialog
          title={"Delete Item"}
          description={
            <p>Are you sure you want to delete this Notification?</p>
          }
          centered={true}
          show={modalDelete}
          close={(val) => {
            setModalDelete(val);
          }}
          actions={
            <div className="btn-wrap d-flex justify-content-end">
              <Btn
                variant="secondary"
                label="No"
                onClick={() => {
                  setModalDelete(false);
                }}
              />
              <Btn
                variant="primary"
                label="Yes"
                onClick={() => {
                  onDelete();
                }}
              />
            </div>
          }
        />
      </div>
    </React.Fragment>
  );
};

const MapStateToProps = ({ notification }) => {
  return {
    getNotificationData: notification.getNotificationData,
    getNotificationDataLoading: notification.getNotificationDataLoading,
    postNotificationDataSuccess: notification.postNotificationDataSuccess,
    postNotificationDataLoading: notification.postNotificationDataLoading,
    deleteNotificationDataLoading: notification.deleteNotificationDataLoading,
    deleteNotificationDataSuccess: notification.deleteNotificationDataSuccess,
  };
};

export default connect(MapStateToProps, dispatcher)(PushNotificationList);
