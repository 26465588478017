/* eslint-disable react/style-prop-object */
import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
// import CryptoJS from "crypto-js";
import { encryption } from "../../../utils/helpers/encryption";

// framework
import { Container, Row, Col, Card, Form } from "react-bootstrap";

// styles
import "./login.scss";

// assets
// import { GLOBAL_ICONS } from '../../../assets/icons/global-icons';
import CircularBack from "../../../assets/icons/circular-back.png";

// components
import { Btn, IconButton, Input } from "../../../components";

// navigations
// import { navigate } from '../../../navigation/navigation-service';

import { Field, reduxForm, SubmissionError } from "redux-form";
import * as dispatcher from "../login/store/dispatchers.js";
import { connect } from "react-redux";
import ErrorStore from "../../../utils/ErrorStore";
import { required } from "../../../utils/Validator";
import { useEffect } from "react";
import {
  setSession,
  setUserDetails,
  isAuthenticated,
} from "../../../utils/Authentication";
import parser from "query-string";

let Login = ({
  handleSubmit,
  updateLogin,
  loginSuccess,
  sessionDetails,
  errorMsg,
}) => {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const history = useHistory();

  // function handleClick(link) {
  //     history.push({
  //         pathname: link,
  //         param: { detail: "test" }
  //     });
  // }

  // const handleSubmit = () => {
  //   if (email === "alex@lee.gov" && password === "123456") {
  //     // if user is valid then navigate to home page component
  //     history.push("/home");
  //   } else {
  //     setError("Inavlid email or password");
  //   }
  // };

  // login
  // const login = () => {
  //     history.push({
  //         pathname: "/home",
  //     });
  //     // NavigationService.navigate("/home")
  // }

  function _validate() {
    const errorInstance = ErrorStore();
    errorInstance.add("email", email, [required]);
    errorInstance.add("password", password, [required]);
    return errorInstance.get();
  }

  // const passwordSecret = 'sakfbak'

  // const encryptedPassword = CryptoJS.AES.encrypt(
  //   password, passwordSecret
  // ).toString()

  // console.log(encryptedPassword);

  const enPassword =  encryption(password)
  const enUsername = encryption(email)

  function _handleSubmit(details) {
    let errors = null;
    let serviceFormError = _validate(details);
    if (serviceFormError) {
      errors = { ...(errors || {}), ...serviceFormError };
    }
    if (errors) {
      throw new SubmissionError({ ...errors });
    } else {
      let _data = {
        username: enUsername,
        password: enPassword,
      };

      updateLogin(_data);
    }
  }

  useEffect(() => {
    const queryString = history.location.search;
    const queryParams = parser.parse(queryString);

    if (loginSuccess) {
      window.location.reload();
      setSession(sessionDetails?.data.token);

      setUserDetails({
        firstName: sessionDetails?.data.details.firstName,
        lastName: sessionDetails?.data.details.lastName,
        role: sessionDetails?.data.details.role,
        username: sessionDetails?.data.details.username,
        active: sessionDetails?.data.details.isActive,
      });

      if (queryParams.redirect_url) {
        history.push(queryParams.redirect_url);
      } else {
        history.push("/home");
      }
    }
  }, [
    history,
    loginSuccess,
    sessionDetails?.data.details.firstName,
    sessionDetails?.data.details.isActive,
    sessionDetails?.data.details.lastName,
    sessionDetails?.data.details.role,
    sessionDetails?.data.details.username,
    sessionDetails?.data.token,
  ]);

  return (
    <>
      {!isAuthenticated() ? (
        <div className="screen-wrap login-screen-wrap">
          <Container>
            <Row>
              <Col sm={12}>
                <div className="login-wrap">
                  <div className="login-card-wrap">
                    <Card>
                      <div
                        className={`login-card-body login-action-body ${
                          !showForgotPassword && "show"
                        }`}
                      >
                        <Card.Body>
                          <Card.Title className="text-wrap">
                            <p className="subtitle1">CMS Login</p>
                          </Card.Title>
                          <Form
                            className="form-wrap"
                            id="login-form"
                            name="loginForm"
                            onSubmit={handleSubmit(_handleSubmit)}
                          >
                            {/* <Input
                          placeholder="Email address"
                          type="input"
                          id="login-email"
                          onchange={(e) => setEmail(e.target.value)}
                          style="form-underline"
                        /> */}

                            <Field
                              name="email"
                              component={Input}
                              placeholder="Email address"
                              type="input"
                              id="login-email"
                              defaultValue={email}
                              onchange={(e) => setEmail(e.target.value)}
                              style="form-underline"
                            ></Field>
                            {/* <Input
                          placeholder="Password"
                          type={!showPassword ? "password" : "input"}
                          id="login-password"
                          value={password}
                          onchange={(e) => setPassword(e.target.value)}
                          style="form-underline"
                        /> */}

                            <Field
                              name="password"
                              component={Input}
                              placeholder="Password"
                              type={!showPassword ? "password" : "input"}
                              id="login-password"
                              defaultValue={password}
                              onchange={(e) => setPassword(e.target.value)}
                              style="form-underline"
                            ></Field>
                            <Form.Group
                              className="form-group"
                              controlId="show-password"
                            >
                              <Form.Check
                                type="checkbox"
                                label="Show Password"
                                defaultChecked={showPassword}
                                onChange={(event) =>
                                  setShowPassword(!showPassword)
                                }
                              />
                            </Form.Group>
                            {/* <Form.Group className="form-group d-flex align-items-end flex-column">
                              <Btn
                                variant="link"
                                label="Forgot Password?"
                                style={"text-capitalize"}
                                onClick={() => {
                                  setShowForgotPassword(true);
                                }}
                              />
                            </Form.Group> */}
                            <div className="d-grid">
                              <Btn
                                variant="primary"
                                label="Login"
                                type="submit"
                              />
                              {error && <p className="error">{error}</p>}
                            </div>
                          </Form>
                        </Card.Body>
                      </div>
                      <div
                        className={`login-card-body forgot-action-body ${
                          showForgotPassword && "show"
                        }`}
                      >
                        <Card.Body>
                          <IconButton
                            src={CircularBack}
                            onClick={() => {
                              setShowForgotPassword(false);
                            }}
                          />
                          <Card.Title className="text-wrap">
                            <p className="subtitle1">Forgot Password?</p>
                            <p className="subtitle2">
                              Please enter your Email Address and click on
                              'Submit'
                            </p>
                          </Card.Title>
                          <Form className="form-wrap">
                            <Input
                              placeholder="Email Id."
                              type="input"
                              id="forgot-password"
                              onChange={(data) => {
                                //console.log(data.target.value, "input");
                              }}
                              style="form-underline"
                            />
                            <div className="d-grid">
                              <Btn
                                variant="primary"
                                label="Submit"
                                onClick={() => {}}
                              />
                            </div>
                            {errorMsg && errorMsg.message ? (
                              <Col className="text-danger d-block">
                                {errorMsg.message}
                              </Col>
                            ) : null}
                          </Form>
                        </Card.Body>
                      </div>
                    </Card>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <Redirect to="/home"></Redirect>
      )}
    </>
  );
};

Login = reduxForm({
  form: "loginForm",
  enableReinitialize: true,
})(Login);

const mapStateToProps = ({ login }) => {
  return {
    loginSuccess: login.loginSuccess,
    sessionDetails: login.sessionDetails,
    errorMsg: login.errorMsg,
  };
};

export default connect(mapStateToProps, dispatcher)(Login);
