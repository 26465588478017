import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as dispatcher from "../store/dispatchers";
import { Container, Row, Col } from "react-bootstrap";
import {
  Btn,
  Dialog,
  BreadCrumb,
  FeatureContentCard,
  DisplayCoverPicture,
} from "../../../components";
import { useEffect } from "react";
import LoadingSpinner from "../../../components/LoadingSpinner";

const BannerList = ({
  bannerList,
  getBannerData,
  deleteBannerById,
  bannerDeleteSuccess,
  bannerDataInProgress,
  bannerDataSuccess,
  bannerAddInProgress,
  bannerAddSuccess,
  updateBannerInProgress,
  updateBannerSuccess,
  bannerDeleteInProgress,
}) => {
  const location = useLocation();
  const paramVar = location.param;
  const [param] = useState(paramVar);
  const [bannerId, setBannerId] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const breadcrumb = [
    {
      label: "Banners",
      href: "/banners/list",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  useEffect(() => {
    getBannerData();
  }, [getBannerData, bannerDeleteSuccess]);

  useEffect(() => {
    if (bannerDeleteSuccess) {
      navigation("/banners/list");
    }
  }, [bannerDeleteSuccess]);

  useEffect(() => {
    if (bannerDataInProgress) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [bannerDataInProgress, bannerDataSuccess]);

  useEffect(() => {
    if (
      bannerAddInProgress ||
      bannerDeleteInProgress ||
      updateBannerInProgress
    ) {
      setIsLoading(true);
    } else if (bannerAddSuccess || bannerDeleteSuccess || updateBannerSuccess) {
      setIsLoading(false);
    }
  }, [
    bannerAddInProgress,
    bannerAddSuccess,
    bannerDeleteInProgress,
    bannerDeleteSuccess,
    updateBannerInProgress,
    updateBannerSuccess,
  ]);

  function onBannerDelete() {
    setModalDelete(false);
    return deleteBannerById(parseInt(bannerId));
  }

  return (
    <React.Fragment>
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Banners</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="subtitle-wrap text-wrap d-flex justify-content-end mb-1">
                    <p className="caption">
                      Maximum of 10 Banners can be created.
                    </p>
                  </div>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/home", "")}
                    />
                    {bannerList?.length === 10 ? (
                      ""
                    ) : (
                      <Btn
                        variant="primary"
                        size={"lg"}
                        label="Add New"
                        onClick={() => navigation("/banner/add", param)}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </div>
            {/* <div className="screen-view-row no-border">
              <Legend
                label="Banners"
                actions={
                  <Btn
                    variant="outline-primary"
                    size={"sm"}
                    label="Edit Sequence"
                    onClick={() => navigation("/banner/content-sequence", "")}
                  />
                }
              />
            </div> */}
            <div className="screen-view-row">
              {isLoading ? (
                <LoadingSpinner />
              ) : bannerList && bannerList.length > 0 ? (
                bannerList.map((item, index) => {
                  const DisplayTitle =
                    item?.title.en[0].toUpperCase() +
                    item?.title.en.substring(1);
                  return (
                    <div className="feature-content-row" key={index}>
                      <Row>
                        {item.imageUrl && (
                          <Col sm={3}>
                            <DisplayCoverPicture src={item.imageUrl} />
                          </Col>
                        )}
                        <Col sm={item.imageUrl ? 9 : 12}>
                          <FeatureContentCard
                            title={
                              <React.Fragment>
                                <div className="bannerName">
                                  {item.title === undefined ? "" : DisplayTitle}
                                </div>
                              </React.Fragment>
                            }
                            description={
                              <React.Fragment>
                                <div className="text-wrap d-flex">
                                  {item.url && item.mediaType === "link" ? (
                                    <div className="subtitle2">
                                      URL :{" "}
                                      <a
                                        href={item.url}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {item.url}
                                      </a>
                                    </div>
                                  ) : item.mediaType === "attachment" ? (
                                    "Attachment available"
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </React.Fragment>
                            }
                            actions={
                              <React.Fragment>
                                <Btn
                                  variant="secondary"
                                  icon={<span className="icon-Edit"></span>}
                                  onClick={() => {
                                    navigation(`/banner/edit/${item.id}`, "");
                                  }}
                                />
                                <Btn
                                  variant="secondary"
                                  icon={<span className="icon-Delete-2"></span>}
                                  style={"btn-icon btn-link-danger"}
                                  onClick={() => {
                                    setModalDelete(true);
                                    setBannerId(item.id);
                                  }}
                                />
                              </React.Fragment>
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                  );
                })
              ) : (
                <div className="no-data">
                  No Data Found. Click on 'ADD NEW' to create.
                </div>
              )}
            </div>
          </div>
        </Container>
        <Dialog
          title={"Delete Banner"}
          description={<p>Are you sure you want to delete the banner?</p>}
          centered={true}
          show={modalDelete}
          close={(val) => {
            setModalDelete(val);
          }}
          actions={
            <div className="btn-wrap d-flex justify-content-end">
              <Btn
                variant="secondary"
                label="No"
                onClick={() => {
                  setModalDelete(false);
                }}
              />
              <Btn
                variant="primary"
                label="Yes"
                onClick={() => {
                  onBannerDelete();
                }}
              />
            </div>
          }
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ banner }) => {
  return {
    bannerList: banner.bannerDetails,
    bannerDeleteSuccess: banner.bannerDeleteSuccess,
    bannerDataInProgress: banner.bannerDataInProgress,
    bannerDataSuccess: banner.bannerDataSuccess,
    bannerAddInProgress: banner.bannerAddInProgress,
    bannerAddSuccess: banner.bannerAddSuccess,
    updateBannerInProgress: banner.updateBannerInProgress,
    updateBannerSuccess: banner.updateBannerSuccess,
    bannerDeleteInProgress: banner.bannerDeleteInProgress,
  };
};

export default connect(mapStateToProps, dispatcher)(BannerList);
