import Actions from "../store/Actions";
import { call, put, takeLatest } from "redux-saga/effects";
import API from "../../../utils/Api";
import toastr from "../../../utils/Toastr";

function getNotificationCall() {
  return API.get("/cms/notifications");
}

function getNotificationByIdCall({ id }) {
  return API.get(`/cms/notifications/${id}`);
}

function postNotificationCall({ data }) {
  return API.post("/cms/notifications", data);
}

function putNotificationCall({ data, id }) {
  return API.put(`/cms/notifications/${id}`, data);
}

function deleteNotificationCall({ id }) {
  return API._delete(`/cms/notifications/${id}`);
}

function* watchGetNotificationsAsync() {
  try {
    const details = yield call(getNotificationCall);
    yield put({
      type: Actions.GET_NOTIFICATION_DATA_SUCCESS,
      details,
    });
  } catch (error) {
    yield put({
      type: Actions.GET_NOTIFICATION_DATA_FAIL,
      error,
    });
  }
}

function* watchPostNotificationsAsync(data) {
  try {
    yield call(postNotificationCall, data);
    yield put({
      type: Actions.POST_NOTIFICATION_DATA_SUCCESS,
    });
    toastr.success("Notification Added Successfully");
  } catch (error) {
    yield put({
      type: Actions.POST_NOTIFICATION_DATA_FAIL,
      error,
    });
    toastr.error(error.message);
  }
}

function* watchPutNotificationsAsync(data) {
  try {
    yield call(putNotificationCall, data);
    yield put({
      type: Actions.UPDATE_NOTIFICATION_DATA_SUCCESS,
    });
    toastr.success("Notification Updated Successfully");
  } catch (error) {
    yield put({
      type: Actions.UPDATE_NOTIFICATION_DATA_FAIL,
      error,
    });
    toastr.error(error.message);
  }
}

function* watchDeleteNotificationsAsync(id) {
  try {
    yield call(deleteNotificationCall, id);
    yield put({
      type: Actions.DELETE_NOTIFICATION_DATA_SUCCESS,
    });
    toastr.success("Notification deleted Successfully");
  } catch (error) {
    yield put({
      type: Actions.DELETE_NOTIFICATION_DATA_FAIL,
      error,
    });
    toastr.error(error.message);
  }
}

function* watchGetNotificationByIdAsync(id) {
  try {
    const details = yield call(getNotificationByIdCall, id);
    yield put({
      type: Actions.GET_NOTIFICATION_BY_ID_SUCCESS,
      details,
    });
  } catch (error) {
    yield put({
      type: Actions.GET_NOTIFICATION_BY_ID_FAIL,
      error,
    });
  }
}

const notificationSaga = [
  takeLatest(Actions.GET_NOTIFICATION_DATA, watchGetNotificationsAsync),
  takeLatest(Actions.GET_NOTIFICATION_BY_ID, watchGetNotificationByIdAsync),
  takeLatest(Actions.POST_NOTIFICATION_DATA, watchPostNotificationsAsync),
  takeLatest(Actions.DELETE_NOTIFICATION_DATA, watchDeleteNotificationsAsync),
  takeLatest(Actions.UPDATE_NOTIFICATION_DATA, watchPutNotificationsAsync),
];

export default notificationSaga;
