import { call, put, takeLatest } from "redux-saga/effects";
import Actions from "../store/actions";
import API from "../../../utils/Api";
import toastr from "../../../utils/Toastr";

function eventApiCall() {
  return API.get("/cms/events");
}

function eventApiCallById({ id }) {
  return API.get(`/cms/events/${id}`);
}

function postEventAPICall(data) {
  return API.post("/cms/events", data);
}

function putEventAPICall({ data, id }) {
  return API.put(`/cms/events/${id}`, data);
}

function deleteEventApiCall({ id }) {
  return API._delete(`/cms/events/${id}`);
}

function* watchEventApiAsync() {
  try {
    const details = yield call(eventApiCall);
    yield put({ type: Actions.GET_EVENTS_DATA_SUCCESS, details });
  } catch (error) {
    yield put({ type: Actions.GET_EVENTS_DATA_FAIL, error });
  }
}

function* watchEventApiByIdAsync(id) {
  try {
    const details = yield call(eventApiCallById, id);
    yield put({ type: Actions.GET_EVENTS_DATA_BY_ID_SUCCESS, details });
  } catch (error) {
    yield put({ type: Actions.GET_EVENTS_DATA_BY_ID_FAIL, error });
  }
}

function* watchPostEventApiAsync(postData) {
  try {
    const details = yield call(postEventAPICall, postData.data);
    yield put({ type: Actions.POST_EVENT_DATA_SUCCESS, details });
    toastr.success("Events Data added succesfully");
  } catch (error) {
    yield put({ type: Actions.POST_EVENT_DATA_FAIL, error });
    toastr.error(error.message);
  }
}

function* watchPutEventApiAsync(data) {
  try {
    const details = yield call(putEventAPICall, data);
    yield put({ type: Actions.PUT_EVENT_DATA_SUCCESS, details });
    toastr.success("Event data has been updated succesfully");
  } catch (error) {
    yield put({ type: Actions.PUT_EVENT_DATA_FAIL, error });
    toastr.error(error.message);
  }
}

function* watchDeleteEventApiAsync(id) {
  try {
    yield call(deleteEventApiCall, id);
    yield put({ type: Actions.DELETE_EVENT_DATA_SUCCESS });
    toastr.success("Event data has been deleted succesfully");
  } catch (error) {
    yield put({ type: Actions.DELETE_EVENT_DATA_FAIL, error });
  }
}

const eventSaga = [
  takeLatest(Actions.GET_EVENTS_DATA, watchEventApiAsync),
  takeLatest(Actions.POST_EVENT_DATA, watchPostEventApiAsync),
  takeLatest(Actions.PUT_EVENT_DATA, watchPutEventApiAsync),
  takeLatest(Actions.GET_EVENTS_DATA_BY_ID, watchEventApiByIdAsync),
  takeLatest(Actions.DELETE_EVENT_DATA, watchDeleteEventApiAsync),
];

export default eventSaga;
