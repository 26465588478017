import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";

import "../feature-card.scss";

const FeatureIcon = (props) => {
  return (
    <div className="feature-icons">
      <Row>
        <Col>
          <div className="feature-icons-card">
            <img src={props.icon} alt="Icon" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

FeatureIcon.propTypes = {
  icon: PropTypes.string,
};
FeatureIcon.defaultProps = {
  icon: "",
};
export default FeatureIcon;
