import React, { useState } from "react";

// framework
import { Container, Row, Col, Form } from "react-bootstrap";

// plugin
import { Formik } from "formik";

// components
import {
  Btn,
  Input,
  Check,
  InputFile,
  Dialog,
  InputDate,
  InputDateRange,
} from "../../components";

// utils
import {
  PHONE_NUMBER_REGEX,
  ONLY_NUMBER_REGEX,
  EMAIL_ID_REGEX,
  URL_REGEX,
} from "../../utils/helpers/regex";
import {
  CATEGORY_DETAILS_OPTIONS,
  EVENTS_DURATIONS_OPTIONS,
} from "../../utils/service";

const Components = () => {
  const [categoryValue, setCategoryValue] = useState("Web Link");
  const [durationValue, setDurationValue] = useState("Single Day");
  const [modalFormSuccess, setModalFormSuccess] = useState(false);

  let setFieldValueFunction = null;

  const handleSelectFieldObject = (setFieldValue) => {
    setFieldValueFunction = setFieldValue;
  };

  /* useEffect(() => {
    setFieldValueFunction("category", "Web Link");
  }, []) */

  const handleCategoryChange = (value, _dataArr) => {
    setFieldValueFunction("category", value);
    setCategoryValue(value);
  };
  const handleDurationChange = (value, _dataArr) => {
    setFieldValueFunction("events_durations", value);
    setDurationValue(value);
  };

  return (
    <div className="screen-wrap">
      <Container>
        <Row>
          <Col>
            <div className="screen-view-wrap">
              <div className="screen-view-row">
                <Row>
                  <Col sm={12} className="d-flex">
                    <div className="screen-title-wrap d-flex align-self-center">
                      <div className="text-wrap">
                        <p className="subtitle1">Form Validation</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <Formik
                initialValues={{
                  fname: "",
                  lname: "",
                  address: "",
                  landmark: "",
                  extension: "",
                  phone: "",
                  category: categoryValue,
                  weblink: "",
                  attachment: "",
                  coverImage: "",
                  events_durations: durationValue,
                  events_date: null,
                  events_range_date: "",
                  email: "",
                  description: "",
                  facebook: "",
                  instagram: "",
                  linkdein: "",
                  twitter: "",
                }}
                validate={(values) => {
                  const errors = {};
                  if (!values.fname) {
                    errors.fname = "Required";
                  }
                  if (!values.lname) {
                    errors.lname = "Required";
                  }
                  if (!values.address) {
                    errors.address = "Required";
                  }
                  if (!values.landmark) {
                    errors.landmark = "Required";
                  }
                  if (!values.extension) {
                    errors.extension = "Required";
                  } else if (!ONLY_NUMBER_REGEX.test(values.extension)) {
                    errors.extension = "Enter only digit";
                  }
                  if (!values.phone) {
                    errors.phone = "Required";
                  } else if (!PHONE_NUMBER_REGEX.test(values.phone)) {
                    errors.phone = "Enter valid mobile number";
                  }
                  if (!values.category) {
                    errors.category = "Required";
                  }
                  if (values.weblink && !URL_REGEX.test(values.weblink)) {
                    errors.weblink = "Enter valid url";
                  }
                  if (values.events_durations === "Single Day") {
                    if (!values.events_date) {
                      errors.events_date = "Required";
                    }
                  } else {
                    if (
                      !(
                        values.events_range_date[0] &&
                        values.events_range_date[1]
                      )
                    ) {
                      errors.events_range_date = "Required";
                    }
                  }
                  if (values.email && !EMAIL_ID_REGEX.test(values.email)) {
                    errors.email = "Enter valid Email Id";
                  }
                  if (values.facebook && !URL_REGEX.test(values.facebook)) {
                    errors.facebook = "Enter valid url";
                  }
                  if (values.instagram && !URL_REGEX.test(values.instagram)) {
                    errors.instagram = "Enter valid url";
                  }
                  if (values.linkdein && !URL_REGEX.test(values.linkdein)) {
                    errors.linkdein = "Enter valid url";
                  }
                  if (values.twitter && !URL_REGEX.test(values.twitter)) {
                    errors.twitter = "Enter valid url";
                  }
                  return errors;
                }}
                onSubmit={(values) => {
                  setModalFormSuccess(true);
                }}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  values,
                  errors,
                  touched,
                }) => (
                  <React.Fragment>
                    <div className="screen-view-row">
                      <Form className="form-wrap">
                        {handleSelectFieldObject(setFieldValue)}
                        <Row>
                          <Col lg={6} sm={12}>
                            <Row>
                              <Col sm={6}>
                                <Input
                                  label="First Name."
                                  placeholder="Enter Name"
                                  type="input"
                                  id="fname"
                                  defaultValue={values.fname}
                                  onChange={(event) => {
                                    handleChange(event);
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                  isInvalid={errors.fname && touched.fname}
                                  helperText={
                                    errors.fname &&
                                    touched.fname &&
                                    errors.fname
                                  }
                                />
                              </Col>
                              <Col sm={6}>
                                <Input
                                  label="Last Name."
                                  placeholder="Enter Last Name"
                                  type="input"
                                  id="lname"
                                  defaultValue={values.lname}
                                  onChange={(event) => {
                                    handleChange(event);
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                  isInvalid={errors.lname && touched.lname}
                                  helperText={
                                    errors.lname &&
                                    touched.lname &&
                                    errors.lname
                                  }
                                />
                              </Col>
                            </Row>
                            <Input
                              label="Address"
                              placeholder="Address"
                              type="input"
                              id="address"
                              defaultValue={values.address}
                              onChange={(event) => {
                                handleChange(event);
                              }}
                              onBlur={(event) => {
                                handleBlur(event);
                              }}
                              isInvalid={errors.address && touched.address}
                              helperText={
                                errors.address &&
                                touched.address &&
                                errors.address
                              }
                            />
                            <Input
                              placeholder="Add optional details like room number, floor, landmark"
                              type="input"
                              id="landmark"
                              defaultValue={values.landmark}
                              onChange={(event) => {
                                handleChange(event);
                              }}
                              onBlur={(event) => {
                                handleBlur(event);
                              }}
                              isInvalid={errors.landmark && touched.landmark}
                              helperText={
                                errors.landmark &&
                                touched.landmark &&
                                errors.landmark
                              }
                            />
                            <Row>
                              <Col sm={3}>
                                <Input
                                  label="Extension."
                                  placeholder="Extn."
                                  type="input"
                                  id="extension"
                                  defaultValue={values.extension}
                                  onChange={(event) => {
                                    handleChange(event);
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                  isInvalid={
                                    errors.extension && touched.extension
                                  }
                                  helperText={
                                    errors.extension &&
                                    touched.extension &&
                                    errors.extension
                                  }
                                />
                              </Col>
                              <Col sm={6}>
                                <Input
                                  label="Phone Number(Optional)"
                                  placeholder="Phone Number"
                                  type="input"
                                  id="phone"
                                  defaultValue={values.phone}
                                  onChange={(event) => {
                                    handleChange(event);
                                  }}
                                  onBlur={(event) => {
                                    handleBlur(event);
                                  }}
                                  isInvalid={errors.phone && touched.phone}
                                  helperText={
                                    errors.phone &&
                                    touched.phone &&
                                    errors.phone
                                  }
                                />
                              </Col>
                            </Row>
                            <Check
                              label="Category Details"
                              id="category"
                              data={CATEGORY_DETAILS_OPTIONS}
                              type="radio"
                              inline={true}
                              containerStyle="mb-0"
                              // defaultValue={values.category}
                              onChange={(value, event, _dataArr) => {
                                handleCategoryChange(value, _dataArr);
                              }}
                              isInvalid={errors.category && touched.category}
                              helperText={
                                errors.category &&
                                touched.category &&
                                errors.category
                              }
                            />
                            {values.category === "Web Link" ? (
                              <Input
                                placeholder="Enter Link"
                                type="input"
                                id="weblink"
                                defaultValue={values.weblink}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                                onBlur={(event) => {
                                  handleBlur(event);
                                }}
                                isInvalid={errors.weblink && touched.weblink}
                                helperText={
                                  errors.weblink &&
                                  touched.weblink &&
                                  errors.weblink
                                }
                              />
                            ) : (
                              <InputFile
                                placeholder="Attachment"
                                id="attachment"
                                caption="Accepted formats: .pdf"
                                accept="application/pdf"
                                defaultValue={values.attachment}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                                onBlur={(event) => {
                                  handleBlur(event);
                                }}
                              />
                            )}
                            <Input
                              label="Email Address (Optional)"
                              placeholder="Email Address (Optional)"
                              type="input"
                              id="email"
                              defaultValue={values.email}
                              onChange={(event) => {
                                handleChange(event);
                              }}
                              onBlur={(event) => {
                                handleBlur(event);
                              }}
                              isInvalid={errors.email && touched.email}
                              helperText={
                                errors.email && touched.email && errors.email
                              }
                            />
                            <Input
                              label="Description (Optional)"
                              placeholder="Description (Optional)"
                              type="input"
                              showLength={true}
                              maxLength={100}
                              as="textarea"
                              id="description"
                              rows={4}
                              onChange={(event) => {
                                handleChange(event);
                              }}
                              onBlur={(event) => {
                                handleBlur(event);
                              }}
                              isInvalid={
                                errors.description && touched.description
                              }
                              helperText={
                                errors.description &&
                                touched.description &&
                                errors.description
                              }
                            />
                          </Col>
                          <Col lg={6} sm={12}>
                            <Check
                              label="Event Duration"
                              id="events_durations"
                              data={EVENTS_DURATIONS_OPTIONS}
                              containerStyle="mb-0"
                              onChange={(value, _dataArr) => {
                                handleDurationChange(value, _dataArr);
                              }}
                              type="radio"
                              inline={true}
                              isInvalid={
                                errors.events_durations &&
                                touched.events_durations
                              }
                              helperText={
                                errors.events_durations &&
                                touched.events_durations &&
                                errors.events_durations
                              }
                            />
                            {values.events_durations === "Single Day" ? (
                              <InputDate
                                label="Event Date"
                                id="events_date"
                                placeholder="Select Date"
                                dateFormat="YYYY/MM/DD"
                                includeTime={false}
                                onChange={(event) => {
                                  setFieldValueFunction("events_date", event);
                                }}
                                isInvalid={
                                  errors.events_date && touched.events_date
                                }
                                helperText={
                                  errors.events_date &&
                                  touched.events_date &&
                                  errors.events_date
                                }
                                // disableDay={(date) => date.toDate() < new Date()}
                              />
                            ) : (
                              <InputDateRange
                                label="Event Date Range"
                                id="events_range_date"
                                placeholder="Select Date"
                                dateFormat="YYYY/MM/DD"
                                includeTime={false}
                                showToday={true}
                                // initialStartDate={new Date()}
                                // initialEndDate={new Date()}
                                onChange={(start, end) => {
                                  setFieldValueFunction("events_range_date", [
                                    start,
                                    end,
                                  ]);
                                }}
                                isInvalid={
                                  errors.events_range_date &&
                                  touched.events_range_date
                                }
                                helperText={
                                  errors.events_range_date &&
                                  touched.events_range_date &&
                                  errors.events_range_date
                                }
                                // disableDay={(date) => date.toDate() < new Date()}
                              />
                            )}

                            <div className=" form-group">
                              <Form.Label>
                                Social Networks (Optional)
                              </Form.Label>
                              <Input
                                label="Facebook :"
                                placeholder="Url"
                                id="facebook"
                                type="input"
                                containerStyle="form-group-input-inline"
                                defaultValue={values.facebook}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                                onBlur={(event) => {
                                  handleBlur(event);
                                }}
                                isInvalid={errors.facebook && touched.facebook}
                                helperText={
                                  errors.facebook &&
                                  touched.facebook &&
                                  errors.facebook
                                }
                              />
                              <Input
                                label="Instagram :"
                                placeholder="Url"
                                id="instagram"
                                type="input"
                                containerStyle="form-group-input-inline"
                                defaultValue={values.instagram}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                                onBlur={(event) => {
                                  handleBlur(event);
                                }}
                                isInvalid={
                                  errors.instagram && touched.instagram
                                }
                                helperText={
                                  errors.instagram &&
                                  touched.instagram &&
                                  errors.instagram
                                }
                              />
                              <Input
                                label="Linkedin :"
                                placeholder="Url"
                                id="linkdein"
                                type="input"
                                containerStyle="form-group-input-inline"
                                defaultValue={values.linkdein}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                                onBlur={(event) => {
                                  handleBlur(event);
                                }}
                                isInvalid={errors.linkdein && touched.linkdein}
                                helperText={
                                  errors.linkdein &&
                                  touched.linkdein &&
                                  errors.linkdein
                                }
                              />
                              <Input
                                label="Twitter :"
                                placeholder="Url"
                                id="twitter"
                                type="input"
                                containerStyle="form-group-input-inline"
                                defaultValue={values.twitter}
                                onChange={(event) => {
                                  handleChange(event);
                                }}
                                onBlur={(event) => {
                                  handleBlur(event);
                                }}
                                isInvalid={errors.twitter && touched.twitter}
                                helperText={
                                  errors.twitter &&
                                  touched.twitter &&
                                  errors.twitter
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                    <div className="screen-view-row">
                      <Row>
                        <Col lg={12} sm={12}>
                          <div className="btn-wrap d-flex justify-content-end">
                            <Btn
                              variant="secondary"
                              size={"lg"}
                              label="Cancel"
                              onClick={() => {}}
                            />
                            <Btn
                              variant="primary"
                              size={"lg"}
                              label="Submit"
                              onClick={() => {
                                handleSubmit();
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </React.Fragment>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
        <Dialog
          title={"Success"}
          description={<p>"Form submitted successfully"</p>}
          centered={true}
          show={modalFormSuccess}
          closeButton={false}
          close={(val) => {
            setModalFormSuccess(val);
          }}
          actions={
            <div className="btn-wrap d-flex justify-content-end">
              <Btn
                variant="primary"
                label="Okay"
                onClick={() => {
                  setModalFormSuccess(false);
                }}
              />
            </div>
          }
        />
      </Container>
    </div>
  );
};

export default Components;
