import React, { useState } from "react";
import { useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Label } from "reactstrap";
import { Field, reduxForm, SubmissionError } from "redux-form";
import {
  Btn,
  Check,
  Editor,
  Input,
  InputFile,
  // UploadCoverPicture,
} from "../../../components";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ErrorStore from "../../../utils/ErrorStore";
import { CATEGORY_DETAILS_OPTIONS } from "../../../utils/service";
import { required } from "../../../utils/Validator";
import * as dispatcher from "../store/dispatchers";

let ServicesFormContent = ({
  navigation,
  name,
  resetState,
  cancelBtnUrl,
  handleSubmit,
  getDetails,
  uploadFile,
  attachmentFileUuid,
  createContentsDispatch,
  updateContentsDispatch,
  getPostContentDataSuccess,
  getUpdateContentDataSuccess,
  // uploadCoverPhoto,
  bannerImageUuid,
  removeCoverPhoto,
  removeCoverPhotoSuccess,
  fileUploadInProgress,
}) => {
  const [Name, setName] = useState(getDetails ? getDetails.title.en : "");
  const [Link, setLink] = useState(getDetails ? getDetails.url : "");
  // const [bannerImage, setBannerImage] = useState(
  //   getDetails ? getDetails.imageUrl : ""
  // );
  const [Description, setDescription] = useState(
    getDetails ? getDetails.description?.en : ""
  );
  const [imageUuid, setImageUuid] = useState(
    getDetails ? getDetails.imageUuid : null
  );
  const [fileUuid, setFileUuid] = useState(
    getDetails ? getDetails.fileUuid : ""
  );
  const [selectedFileName, setSelectedFileName] = useState(
    getDetails && getDetails.fileDetails
      ? getDetails.fileDetails.originalName
      : ""
  );
  const [categoryDefaultValue, setCategoryDefaultValue] = useState(
    getDetails
      ? getDetails.mediaType === "attachment"
        ? "attachment"
        : "Web Link"
      : "Web Link"
  );
  const [categoryOptions] = useState(
    getUpdatedAttachmentOption(CATEGORY_DETAILS_OPTIONS)
  );
  // Defined this state to get the status of removeButton
  const [imageStatus, setImageStatus] = useState(false);

  // regex Input validation states
  const [validateLink, setValidateLink] = useState(false);

  //Validation Error for Attachment
  const [attachmentValidationError, setAttachmentValidationError] =
    useState("");

  //loader state
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    let linkRegex = /[-a-zA-Z\/\:]{2,256}\.[a-z]+\.[a-z]{2,3}/;

    if (linkRegex.test(Link) || Link === "" || Link === undefined || fileUuid) {
      setValidateLink(true);
    } else {
      setValidateLink(false);
    }
  }, [Link, fileUuid]);

  useEffect(() => {
    if (!Link && !fileUuid) {
      setCategoryDefaultValue("Web Link");
    }
    if (Link) {
      setCategoryDefaultValue("Web Link");
    }
    if (fileUuid) {
      setCategoryDefaultValue("Attachment");
    }
  }, []);

  const location = useLocation();
  let pathname = location.pathname.split("/");

  let editId = parseInt(pathname[5]);
  let serviceType = pathname[4];
  serviceType = serviceType
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function ($1) {
      return $1.toLowerCase();
    });

  function getUpdatedAttachmentOption(options) {
    return options.map((data) => {
      if (data.value === categoryDefaultValue) {
        data.isSelected = true;
      } else {
        data.isSelected = false;
      }
      return data;
    });
  }

  const handleLink = (data) => {
    setLink(data.target.value);
    setFileUuid(null);
    setSelectedFileName(null);
  };

  const handleAttachmentFile = (data) => {
    // if (data.target.files) {
    //   setSelectedFileName(data.target.files[0].name);
    //   setLink(null);
    // }
    if (data.target.files) {
      if (data.target.files[0].size > 10000000) {
        //  before we used 11354650
        setAttachmentValidationError(
          "The File size should not be greater than 10MB"
        );
      } else {
        // setSelectedFileName(data.target.files[0].name);
        setLink(null);
      }
    }
  };

  function _validate() {
    const errorInstance = ErrorStore();
    errorInstance.add("name", Name, [required]);
    return errorInstance.get();
  }

  function _handleSubmit(details) {
    let errors = null;
    let bannerFormError = _validate(details);
    if (bannerFormError) {
      errors = { ...(errors || {}), ...bannerFormError };
    }
    if (errors) {
      throw new SubmissionError({ ...errors });
    } else {
      let _data = {
        title: Name.length > 0 ? Name[0].toUpperCase() + Name.substring(1) : "",
        mediaType: categoryDefaultValue === "Web Link" ? "link" : "attachment",
      };
      if (Description) {
        _data.description = `<!DOCTYPE HTML> <html><head><meta charset="utf-8" /><title></title></head> <body>${Description.trim(
          <br />
        )}</body> </html>`;
      }
      if (Link) {
        _data.url = Link;
        _data.mediaType = "link";
      }
      if (fileUuid) {
        _data.fileUuid = fileUuid;
        _data.mediaType = "attachment";
      }
      if (!Link && !fileUuid) {
        _data.mediaType = null;
      }
      if (imageUuid) {
        _data.imageUuid = imageStatus ? null : imageUuid;
      }
      if (!getDetails && attachmentValidationError === "" && validateLink) {
        if(categoryDefaultValue === "Attachment") {
          if(selectedFileName && selectedFileName.size <= 10000000) {
            createContentsDispatch(_data, serviceType);
          }else{
            setAttachmentValidationError("The File size should not be greater than 10MB");
          }
        }else{
          createContentsDispatch(_data, serviceType);
        }  
      } else {
        // if (imageStatus) {
        //   removeCoverPhoto(imageUuid);
        // }
        // if (removeCoverPhotoSuccess) {
        //   updateContentsDispatch(_data, serviceType, editId);
        // }
        if (getDetails && attachmentValidationError === "" && validateLink) {
          updateContentsDispatch(_data, serviceType, editId);
        }
      }
    }
  }

  useEffect(() => {
    if (getPostContentDataSuccess || getUpdateContentDataSuccess) {
      navigation(cancelBtnUrl);
      resetState();
    }
  }, [
    cancelBtnUrl,
    resetState,
    getPostContentDataSuccess,
    getUpdateContentDataSuccess,
    navigation,
  ]);

  useEffect(() => {
    if (attachmentFileUuid) {
      setFileUuid(attachmentFileUuid);
    }
  }, [attachmentFileUuid]);

  useEffect(() => {
    if (bannerImageUuid) {
      setImageUuid(bannerImageUuid);
    }
  }, [bannerImageUuid]);

  useEffect(() => {
    if (fileUploadInProgress === false) {
      setLoader(false);
    }
  }, [fileUploadInProgress]);
  // function removeImage() {
  //   return removeCoverPhoto(imageUuid);
  // }

  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    if (
      Name ||
      Link ||
      Description ||
      imageUuid ||
      fileUuid ||
      selectedFileName
    ) {
      setIsDisable(false);
    }
  }, [Description, Link, Name, fileUuid, imageUuid, selectedFileName]);

  const clickDisable = () => {
    setTimeout(() => {
      setIsDisable(true);
    }, 10);
  };

  useEffect(() =>{
    if(categoryDefaultValue === "Web Link"){
       setAttachmentValidationError("");
    }
  },[categoryDefaultValue])

  // console.log(selectedFileName)
  return (
    <div className="banner-form-container">
      <div className="screen-view-row">
        <Row>
          <Col lg={6} sm={12} className="d-flex">
            <div className="screen-title-wrap d-flex align-self-center">
              <div className="text-wrap">
                <p className="subtitle1">{name}</p>
              </div>
            </div>
          </Col>
          <Col lg={6} sm={12}>
            <div className="btn-wrap d-flex justify-content-end">
              <Btn
                variant="secondary"
                size={"lg"}
                label="Go Back"
                onClick={() => navigation(cancelBtnUrl)}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="screen-view-row">
        <Form
          className="form-wrap"
          onSubmit={handleSubmit(_handleSubmit)}
          id="services-form-content"
          name="ServicesFormContent"
        >
          <Row>
            <Col lg={6} sm={12}>
              {/* <Label>
                Name <span className="error-msg">*</span>
              </Label> */}
              <Row>
                <Col lg="8" md="9" sm="9">
                  <Label className="label-name" for="banner_name">
                    Title
                    <span className="error-msg"> * </span>
                  </Label>
                </Col>
                <Col lg="4" md="3" sm="3">
                  <p className="title">
                    {100 - Name.length} out of 100 characters left
                  </p>
                </Col>
              </Row>
              <Field
                name="name"
                component={Input}
                defaultValue={Name}
                placeholder="Enter Name"
                type="input"
                id="title"
                maxLength={100}
                showLength={true}
                onchange={(e) => setName(e.target.value)}
              ></Field>

              <Check
                label="Attachment (Optional)"
                id="category_details"
                data={categoryOptions}
                defaultValue={categoryDefaultValue}
                type="radio"
                inline={true}
                onChange={(val) => {
                  setCategoryDefaultValue(val);
                }}
              />
              {categoryDefaultValue === "Web Link" ? (
                <Field
                  name="webLink"
                  component={Input}
                  validateLink={validateLink}
                  defaultValue={Link}
                  placeholder="Enter Link"
                  type="input"
                  id="website"
                  containerStyle="file-attachment"
                  onchange={(e) => handleLink(e)}
                ></Field>
              ) : loader ? (
                <LoadingSpinner />
              ) : (
                <InputFile
                  placeholder="Attachment"
                  id="attachment"
                  defaultValue={ getDetails && getDetails.fileDetails ? getDetails.fileDetails.originalName : selectedFileName?.name}
                  caption="Accepted formats: .pdf"
                  accept="application/pdf"
                  containerStyle="file-attachment"
                  onUploadFile={uploadFile}
                  onChange={(data) => handleAttachmentFile(data)}
                  setAttachmentValidationError={setAttachmentValidationError}
                  setSelectedFileName={setSelectedFileName}
                  setLoader={setLoader}
                />
              )}
              {attachmentValidationError !== "" ? (
                <p className="AttachmentErrorMsg text-danger ms-5">
                  {attachmentValidationError}
                </p>
              ) : (
                ""
              )}
            </Col>
            <Col sm={12} md={12} lg={6}>
              <Field
                name="Description"
                id="description"
                label="Description (Optional)"
                component={Editor}
                defaultValue={Description}
                placeholder="Start Writing..."
                onBlur={(data) => {
                  setDescription(data);
                }}
                getDescriptionValue={setDescription}
              ></Field>
              {/* <Editor
                label="Description (Optional)"
                defaultValue={Description}
                placeholder="Start Writing..."
                onBlur={(e) => setDescription(e)}
              /> */}
            </Col>
            {/* <Col sm={12} md={12} lg={6}>
              <UploadCoverPicture
                selectedImage={bannerImage}
                label="Cover Photo (Optional)"
                caption="Accepted Formats: PNG, JPG. Max 10 mb"
                id="upload_cover_pic"
                onUploadCoverPhoto={uploadCoverPhoto}
                cropConfig={{ aspect: 16 / 9 }}
                onChange={(val) =>
                  setBannerImage(
                    val === null ? null : bannerImageUuid ? bannerImageUuid : ""
                  )
                }
                // _removeImage={(_) => removeImage()}
                _removeImage={setImageStatus}
              />
            </Col> */}
          </Row>

          <div className="screen-view-row">
            <Row>
              <Col lg={12} sm={12}>
                <div className="btn-wrap d-flex justify-content-end">
                  <Btn
                    variant="secondary"
                    size={"lg"}
                    label="Cancel"
                    onClick={() => navigation(cancelBtnUrl)}
                  />
                  <Btn
                    variant="primary"
                    size={"lg"}
                    onClick={clickDisable}
                    label="Submit"
                    type="submit"
                    disabled={fileUploadInProgress
                      // isDisable === true
                    }
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </div>
  );
};

ServicesFormContent = reduxForm({
  form: "ServicesFormContent",
  enableReinitialize: true,
})(ServicesFormContent);

const mapStateToProps = ({ program }) => {
  return {
    bannerImageUuid: program.coverPhotoUuidData,
    attachmentFileUuid: program.fileUploadUuid,
    getUpdateContentData: program.getUpdateContentData,
    getPostContentDataSuccess: program.getPostContentDataSuccess,
    getUpdateContentDataSuccess: program.getUpdateContentDataSuccess,
    removeCoverPhotoSuccess: program.removeCoverPhotoSuccess,
    fileUploadInProgress: program.fileUploadInProgress,
  };
};

export default connect(mapStateToProps, dispatcher)(ServicesFormContent);
