/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// framework
import { Container, Row, Col, Form, Table, Nav } from "react-bootstrap";

// components
import { Btn, BreadCrumb, Input } from "../../../components";

// utils
import { SETTING_LIST } from "../../../utils/service";

const SettingEdit = () => {
  const location = useLocation();

  const paramVar = location.param;
  const [param, setParam] = useState(paramVar);
  const [settingTab, setSettingTab] = useState(param.settingTab);

  const breadcrumb = [
    {
      label: "Settings",
      href: "/settings/list",
      active: false,
    },
    {
      label: "Edit",
      href: "/settings/edit",
      active: true,
    },
  ];

  // navigation
  const history = useHistory();
  const navigation = (href, params) => {
    history.push({
      pathname: href,
      param: params,
    });
  };

  return (
    <React.Fragment>
      {/* <NavHeader /> */}
      <div className="screen-wrap">
        <Container>
          <BreadCrumb data={breadcrumb}></BreadCrumb>
          <div className="screen-view-wrap">
            <div className="screen-view-row">
              <Row>
                <Col lg={6} sm={12} className="d-flex">
                  <div className="screen-title-wrap d-flex align-self-center">
                    <div className="text-wrap">
                      <p className="subtitle1">Edit Settings</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Go Back"
                      onClick={() => navigation("/settings/list", "")}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="screen-view-row no-border">
              <div className="tabs-wrap">
                <Nav
                  variant="tabs"
                  className="text-uppercase"
                  defaultActiveKey={settingTab}
                  onSelect={(eventKey) => {
                    setSettingTab(eventKey);
                  }}
                >
                  <Nav.Item>
                    <Nav.Link eventKey="settingEditTab1">
                      Mobile Home Section
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="settingEditTab2">
                      TECHNICAL CONTACT DETAIL
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
            <div
              className={`screen-view-row ${
                settingTab === "settingEditTab1" ? "d-block" : "d-none"
              }`}
            >
              <Row>
                <Col sm={12}>
                  <div className="table-wrap">
                    <Table striped bordered>
                      <thead>
                        <tr>
                          <th>Feature Name</th>
                          <th>Label</th>
                          <th>
                            <Form.Group
                              className="form-group"
                              controlId="enabled"
                            >
                              <Form.Check type="checkbox" label="Enabled" />
                            </Form.Group>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {SETTING_LIST.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.feature}</td>
                              <td>
                                <div className="w-75">
                                  <Input
                                    placeholder="Enter Label"
                                    type="text"
                                    id={"label" + index}
                                    defaultValue={item.label}
                                    onChange={(data) => {
                                      // console.log(
                                      //   data.target.value,
                                      //   "--- input"
                                      // );
                                    }}
                                  />
                                </div>
                              </td>
                              <td width={"160px"}>
                                <Form.Group
                                  className="form-group"
                                  controlId={"delete" + index}
                                >
                                  <Form.Check
                                    type="checkbox"
                                    label=""
                                    defaultChecked={item.enabled}
                                  />
                                </Form.Group>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </div>
            <div
              className={`screen-view-row ${
                settingTab === "settingEditTab2" ? "d-block" : "d-none"
              }`}
            >
              <Row>
                <Col lg={6} sm={12}>
                  <Input
                    label="Email Address"
                    placeholder="Enter Email Address"
                    type="email"
                    id={"email"}
                    onChange={(data) => {
                      //console.log(data.target.value, "--- input");
                    }}
                  />
                </Col>
              </Row>
            </div>
            <div className="screen-view-row">
              <Row>
                <Col lg={12} sm={12}>
                  <div className="btn-wrap d-flex justify-content-end">
                    <Btn
                      variant="secondary"
                      size={"lg"}
                      label="Cancel"
                      onClick={() => {
                        navigation("/settings/list", "");
                      }}
                    />
                    <Btn
                      variant="primary"
                      size={"lg"}
                      label="Submit"
                      onClick={() => {}}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SettingEdit;
