import React from "react";
import PropTypes from "prop-types";

// framework
import { Card, Row, Col } from "react-bootstrap";

// styles
import "../feature-card.scss";

const FeatureContentCard = (props) => {
  return (
    <div className="feature-content-card-wrap">
      <Card className="no-shadow no-border">
        <Card.Body className="p-0">
          <Row className="FullFeatureContentLayout mt-5">
            <Col sm={props.actions == null ? 12 : 9}>
              <div className="FetureContentBox">
                <div className="feature-content-title-wrap">
                  <div className="text-wrap">
                    <p className="subtitle1">{props.title}</p>
                    {props.subTitle !== "" && (
                      <p className="subtitle2 text-truncate">
                        {props.subTitle}
                      </p>
                    )}
                  </div>
                </div>
                {props.description && (
                  <div className="feature-content-details-wrap">
                    {props.description}
                  </div>
                )}
              </div>
            </Col>
            {props.actions && (
              <Col sm={3}>
                <div className="btn-wrap">{props.actions}</div>
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

/**
	title: Set title of card
	subTitle: Set subTitle of card
	description: Set description as render HTML element
	actions: handlers from firing regardless of the rendered element.
*/

FeatureContentCard.propTypes = {
  title: PropTypes.object,
  subTitle: PropTypes.string,
  description: PropTypes.object,
  actions: PropTypes.object,
};

FeatureContentCard.defaultProps = {
  title: "",
  subTitle: "",
  description: null,
  actions: null,
};

export default FeatureContentCard;
