import React, { useState } from "react";
import { Form, Label } from "reactstrap";
import { Row, Col } from "react-bootstrap";
import ErrorStore from "../../utils/ErrorStore";
import { required } from "../../utils/Validator";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import {
  Btn,
  Input,
  UploadCoverPicture,
  InputFile,
  Dialog,
  Check,
} from "../../components";
import { CATEGORY_DETAILS_OPTIONS } from "../../utils/service";
import { useEffect } from "react";
import * as dispatcher from "./store/dispatchers";
import LoadingSpinner from "../../components/LoadingSpinner";

let BannerForm = ({
  onSubmit,
  details,
  formTitle,
  navigation,
  handleSubmit,
  bannerImageUuid,
  attachmentFileUuid,
  removeBannerImage,
  postCoverPicture,
  postAttchmentFileData,
  coverPhotoDataProgress,
  removeBannerImageSuccess,
  AttachmentFileUuidDetailsInProgress,
  bannerAddSuccess,
}) => {
  const [bannerName, setBannerName] = useState(details ? details.title.en : "");
  const [bannerLink, setBannerLink] = useState(details ? details.url : "");
  const [bannerImage, setBannerImage] = useState(
    details ? details.imageUrl : ""
  );
  const [imageUuid, setImageUuid] = useState(
    details ? details.imageUuid : null
  );
  const [fileUuid, setFileUuid] = useState(details ? details.fileUuid : "");
  const [selectedFileName, setSelectedFileName] = useState(
    details && details.fileDetails ? details.fileDetails.originalName : ""
  );
  const [modalLocateMap, setModalLocateMap] = useState(false);
  const [categoryDefaultValue, setCategoryDefaultValue] = useState(
    details
      ? details.mediaType === "attachment"
        ? "attachment"
        : "Web Link"
      : "Web Link"
  );
  const [categoryOptions] = useState(
    getUpdatedAttachmentOption(CATEGORY_DETAILS_OPTIONS)
  );

  // Defined this state to get the status of removeButton
  const [imageStatus, setImageStatus] = useState(false);

  //loader state
  const [loader, setLoader] = useState(false);

  //Validation Error for Attachment
  const [attachmentValidationError, setAttachmentValidationError] =
    useState("");

  //validation Error for image size
  const [imageValidationError, setImageValidationError] = useState("");

  useEffect(() => {
    if (!bannerLink && !fileUuid) {
      setCategoryDefaultValue("Web Link");
    }
    if (bannerLink) {
      setCategoryDefaultValue("Web Link");
    }
    if (fileUuid) {
      setCategoryDefaultValue("Attachment");
    }
  }, []);

  function _validate() {
    const errorInstance = ErrorStore();
    errorInstance.add("bannerName", bannerName, [required]);
    return errorInstance.get();
  }

  // regex Input validation states
  const [validateLink, setValidateLink] = useState(false);

  function _handleSubmit(details) {
    let errors = null;
    let bannerFormError = _validate(details);
    if (bannerFormError) {
      errors = { ...(errors || {}), ...bannerFormError };
    }
    if (errors) {
      throw new SubmissionError({ ...errors });
    } else {
      const _details = {
        title:
          bannerName.length > 0
            ? bannerName[0].toUpperCase() + bannerName.substring(1)
            : "",
        // mediaType: categoryDefaultValue === "Web Link" ? "link" : "attachment",
      };

      // if (categoryDefaultValue === "Web Link") {
      //   _details.mediaType = "link";
      // }
      // if (categoryDefaultValue === "attachment") {
      //   _details.mediaType = "attachment";
      // }

      if (bannerLink) {
        _details.url = bannerLink;
        _details.mediaType = "link";
      }
      if (!bannerLink && !fileUuid) {
        _details.mediaType = null;
      }

      if (imageUuid) {
        _details.imageUuid = imageStatus ? null : imageUuid;
      }

      if (fileUuid) {
        _details.fileUuid = fileUuid;
        _details.mediaType = "attachment";
      }

      if (imageStatus && imageUuid) {
        removeBannerImage(imageUuid);
      }
      if (removeBannerImageSuccess) {
        onSubmit(_details);
      }
      if (
        // attachmentValidationError === "" &&
        imageValidationError === "" &&
        validateLink
      ) {
        if(categoryDefaultValue === "Attachment") {
          if(selectedFileName && selectedFileName.size <= 10000000) {
            onSubmit(_details);
          }else{
            setAttachmentValidationError("The File size should not be greater than 10MB")
        }
        }else{
          onSubmit(_details);
        }
      }
    }
  }

  const handleBannerLink = (data) => {
    setBannerLink(data.target.value);
    setFileUuid(null);
    setSelectedFileName(null);
  };

  const handleAttachmentFile = (data) => {
    // if (data.target.files) {
    //   setSelectedFileName(data.target.files[0].name);
    //   setBannerLink(null);
    // }
    if (data.target.files) {
      if (data.target.files[0].size >= 10000000) {
        setAttachmentValidationError(
          "The File size should not be greater than 10MB"
        );
      } else {
        // setSelectedFileName(data.target.files[0].name);
        setBannerLink(null);
      }
    }
  };

  useEffect(() => {
    if (bannerImageUuid) {
      setImageUuid(bannerImageUuid);
    }
  }, [bannerImageUuid]);

  useEffect(() => {
    if (attachmentFileUuid) {
      setFileUuid(attachmentFileUuid);
    }
  }, [attachmentFileUuid]);

  useEffect(() => {
    if (removeBannerImageSuccess) {
      setBannerImage(null);
      setImageUuid(null);
    }
  }, [removeBannerImageSuccess]);

  function getUpdatedAttachmentOption(options) {
    return options.map((data) => {
      if (data.value === categoryDefaultValue) {
        data.isSelected = true;
      } else {
        data.isSelected = false;
      }
      return data;
    });
  }

  useEffect(() => {
    let linkRegex = /[-a-zA-Z\/\:]{2,256}\.[a-z]+\.[a-z]{2,3}/;

    if (
      bannerLink === "" ||
      bannerLink === undefined ||
      linkRegex.test(bannerLink) ||
      fileUuid
    ) {
      setValidateLink(true);
    } else {
      setValidateLink(false);
    }
  }, [bannerLink, fileUuid]);

  function removeImage() {
    if (imageUuid) {
      removeBannerImage(imageUuid);
    }
  }

  useEffect(() => {
    if (AttachmentFileUuidDetailsInProgress === false) {
      setLoader(false);
    }
  }, [AttachmentFileUuidDetailsInProgress]);
  // const imageUrl = `http://leecounty-hvs-reach.infojiniconsulting.com/api/v1/files/${bannerImageUuid}`;

  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    if (bannerName || bannerLink || bannerImage || imageUuid || fileUuid) {
      setIsDisable(false);
    }
  }, [bannerImage, bannerLink, bannerName, fileUuid, imageUuid]);

  // if (bannerName) {
  //   setTimeout(() => {
  //     setIsDisable(false);
  //   }, 10);
  // }

  const clickDisable = () => {
    setTimeout(() => {
      setIsDisable(true);
    }, 10);
  };

  useEffect(() =>{
    if(categoryDefaultValue === "Web Link") {
      setAttachmentValidationError("");
    }
  },[categoryDefaultValue])
 
  return (
    <div className="banner-form-container">
      <div className="screen-view-row">
        <Row>
          <Col lg={6} sm={12} className="d-flex">
            <div className="screen-title-wrap d-flex align-self-center">
              <div className="text-wrap">
                <p className="subtitle1">{formTitle} Banner</p>
              </div>
            </div>
          </Col>
          <Col lg={6} sm={12}>
            <div className="btn-wrap d-flex justify-content-end">
              <Btn
                size={"lg"}
                label="Go Back"
                variant="secondary"
                onClick={() => navigation("/banners/list", "")}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Form
        id="banner-form"
        name="bannerForm"
        className="form-wrap"
        onSubmit={handleSubmit(_handleSubmit)}
      >
        <Row>
          <Col lg={6} sm={12}>
            <Row>
              <Col lg="8" md="9" sm="9">
                <Label className="label-name" for="banner_name">
                  Banner Title
                  <span className="error-msg"> * </span>
                </Label>
              </Col>
              <Col lg="4" md="3" sm="3">
                <p className="title">
                  {100 - bannerName.length} out of 100 characters left
                </p>
              </Col>
            </Row>
            <Field
              name="bannerName"
              component={Input}
              defaultValue={bannerName}
              type="text"
              maxLength={100}
              id="banner_name"
              showLength={true}
              placeholder="Enter Banner Name"
              onchange={(e) => setBannerName(e.target.value)}
            />
            <Check
              label="Attachment (Optional)"
              id="category_details"
              data={categoryOptions}
              defaultValue={categoryDefaultValue}
              type="radio"
              inline={true}
              onChange={(val) => {
                setCategoryDefaultValue(val);
              }}
            />
            {categoryDefaultValue === "Web Link" ? (
              <Row>
                <Field
                  name="bannerLink"
                  component={Input}
                  validateLink={validateLink}
                  defaultValue={bannerLink}
                  placeholder="Enter Link"
                  type="text"
                  id="website"
                  containerStyle="file-attachment"
                  onchange={(e) => handleBannerLink(e)}
                />
              </Row>
            ) : loader ? (
              <LoadingSpinner />
            ) : (
              <InputFile
                placeholder="Attachment"
                id="attachment"
                defaultValue={details && details.fileDetails ? details.fileDetails.originalName : selectedFileName?.name}
                caption="Accepted formats: .pdf"
                accept="application/pdf"
                containerStyle="file-attachment"
                onUploadFile={postAttchmentFileData}
                onChange={(data) => handleAttachmentFile(data)}
                setAttachmentValidationError={setAttachmentValidationError}
                setSelectedFileName={setSelectedFileName}
                setLoader={setLoader}
              />
            )}
            {attachmentValidationError !== "" ? (
              <p className="text-danger ms-5">{attachmentValidationError}</p>
            ) : (
              ""
            )}
          </Col>
          <Col lg={6} sm={12}>
            <UploadCoverPicture
              selectedImage={bannerImage}
              label="Cover Photo (Optional)"
              caption="Accepted Formats: PNG, JPG. Max 10 mb"
              id="upload_cover_pic"
              onUploadCoverPhoto={postCoverPicture}
              removeImgSuccess={removeBannerImageSuccess}
              // cropConfig={{ aspect: 16 / 9 }}
              onChange={(val) =>
                setBannerImage(
                  val === null ? null : bannerImageUuid ? bannerImageUuid : ""
                )
              }
              _removeImage={setImageStatus}
              removeImageOnClick={() => removeImage()}
              formTitle={formTitle}
              setImageValidationError={setImageValidationError}
            />
            {imageValidationError !== "" ? (
              <p className="ErrorMsg">{imageValidationError}</p>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={12} sm={12}>
            <div className="btn-wrap d-flex justify-content-end">
              <Btn
                variant="secondary"
                size={"lg"}
                label="Cancel"
                onClick={() => {
                  navigation("/banners/list", "");
                }}
              />
              <Btn
                variant="primary"
                size={"lg"}
                label="Submit"
                type="submit"
                onClick={clickDisable}
                disabled={
                  coverPhotoDataProgress ||
                  AttachmentFileUuidDetailsInProgress 
                  // isDisable === true
                }
              />
            </div>
          </Col>
        </Row>
      </Form>
      <Dialog
        title={"Map"}
        description={
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.618692267389!2d72.8351312153137!3d19.12437675541878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9d8e30e817f%3A0xa53c0adc9279315d!2sDamacloid%20technologies%20private%20limited!5e0!3m2!1sen!2sin!4v1645602980322!5m2!1sen!2sin"
            allowfullscreen=""
            loading="lazy"
            title="map"
            className="w-100"
            style={{ height: "350px" }}
          ></iframe>
        }
        centered={true}
        show={modalLocateMap}
        size={"lg"}
        close={(val) => {
          setModalLocateMap(val);
        }}
        actions={
          <div className="btn-wrap d-flex justify-content-end">
            <Btn
              variant="primary"
              label="Ok"
              onClick={() => {
                setModalLocateMap(false);
              }}
            />
          </div>
        }
      />
    </div>
  );
};

BannerForm = reduxForm({
  form: "bannerForm",
  enableReinitialize: true,
})(BannerForm);

const mapStatetoProps = ({ banner }) => {
  return {
    bannerImageUuid: banner.coverPhotoUuidData,
    coverPhotoDataProgress: banner.coverPhotoDataProgress,
    attachmentFileUuid: banner.AttachmentFileUuidDetails,
    removeBannerImageSuccess: banner.removeBannerImageSuccess,
    AttachmentFileUuidDetailsInProgress:
      banner.AttachmentFileUuidDetailsInProgress,
    bannerAddSuccess: banner.bannerAddSuccess,
  };
};
export default connect(mapStatetoProps, dispatcher)(BannerForm);
