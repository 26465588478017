import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import Components from "./screens/components";
import Login from "./screens/auth/login";
import Home from "./screens/home";
import PushNotificationList from "./screens/push-notifications/push-notification-list";
import PushNotificationAdd from "./screens/push-notifications/push-notification-add";
import PushNotificationDetails from "./screens/push-notifications/push-notification-details";
import PushNotificationEdit from "./screens/push-notifications/PushNorificationEdit";

import BannerList from "./screens/banner/banner-list";
import BannerEdit from "./screens/banner/banner-edit";
import BannerDetail from "./screens/banner/banner-detail";
import BannerContentSequence from "./screens/banner/banner-content-sequence";

import ProgramsList from "./screens/programs/programs-list";
import ProgramsCategory from "./screens/programs/programs-category";
import ProgramsSubcategoryDetails from "./screens/programs/programs-subcategory-details";
import ProgramsCategoryAddContent from "./screens/programs/programs-subcategory-add-contents";
import ProgramsCategoryAddItems from "./screens/programs/programs-subcategory-add-items";
import ProgramsCategoryEditItems from "./screens/programs/programs-subcategory-edit-items";
import ProgramsCategoryEditContent from "./screens/programs/programs-subcategory-edit-content";

import ServicesAndGuidelineList from "./screens/services-and-guideline/services-and-guideline-list";
import ServicesAndGuidelineSubcategoryList from "./screens/services-and-guideline/services-and-guideline-subcategory-list";
import ServiceAndGuidelineDetails from "./screens/services-and-guideline/services-and-guideline-details";
import ServiceAndGuidelineSubcategoryEdit from "./screens/services-and-guideline/services-and-guideline-subcategory-edit";
import ServiceAndGuidelineSubcategoryAdd from "./screens/services-and-guideline/services-and-guideline-subcategory-add";

import SurveyList from "./screens/survey/survey-list";
import SurveyEdit from "./screens/survey/survey-edit";
import SurveyAdd from "./screens/survey/survey-add";
import SurveyDetails from "./screens/survey/survey-details";
import SurveyUserQuestions from "./screens/survey/survey-user-questions";

import NewsletterList from "./screens/newsletter/newsletter-list";
import NewsletterEdit from "./screens/newsletter/newsletter-edit";
import NewsletterAdd from "./screens/newsletter/newsletter-add";

import DirectoryDetails from "./screens/directory/directory-details";
import DirectoryEdit from "./screens/directory/directory-edit";

import ContactDetails from "./screens/contact/contact-details";
import ContactEdit from "./screens/contact/contact-edit";

import EventsCalendarView from "./screens/events/events-calendar-view";
import EventsDetails from "./screens/events/events-details";
import EventsAdd from "./screens/events/events-add";
import EventsEdit from "./screens/events/events-edit";

import UserList from "./screens/user/user-list";
import UserAdd from "./screens/user/user-add";
import UserEdit from "./screens/user/user-edit";

import SettingList from "./screens/settings/settings-list";
import SettingEdit from "./screens/settings/settings-edit";

import AboutUsDetails from "./screens/about-us/about-us-details";
import AboutUsEdit from "./screens/about-us/about-us-edit";

import ChangePassword from "./screens/change-password";
import ProgramsCategoryItem from "./screens/programs/programs-category-item";
import ProgramsCategoryContent from "./screens/programs/programs-category-content";
import BannerAdd from "./screens/banner/banner-add";
import "./assets/styles/index.scss";

function Routes() {
  const [isAuthenticated] = useState(true);
  return (
    <Switch>
      <Route
        exact
        path="/components"
        isAuthenticated={isAuthenticated}
        component={Components}
      />
      <Route
        exact
        path="/"
        isAuthenticated={isAuthenticated}
        component={Login}
      />
      <Route
        exact
        path="/home"
        isAuthenticated={isAuthenticated}
        component={Home}
      />
      <Route
        exact
        path="/notifications/list"
        isAuthenticated={isAuthenticated}
        component={PushNotificationList}
      />
      <Route
        exact
        path="/push-notification/add"
        isAuthenticated={isAuthenticated}
        component={PushNotificationAdd}
      />
      <Route
        exact
        path="/push-notification/edit/:id"
        isAuthenticated={isAuthenticated}
        component={PushNotificationEdit}
      />
      <Route
        exact
        path="/notifications/details/:id"
        isAuthenticated={isAuthenticated}
        component={PushNotificationDetails}
      />

      <Route
        exact
        path="/banners/list"
        isAuthenticated={isAuthenticated}
        component={BannerList}
      />
      <Route
        exact
        path="/banner/edit/:id"
        isAuthenticated={isAuthenticated}
        component={BannerEdit}
      />
      <Route
        exact
        path="/banner/detail/:id"
        isAuthenticated={isAuthenticated}
        component={BannerDetail}
      />
      <Route
        exact
        path="/banner/add"
        isAuthenticated={isAuthenticated}
        component={BannerAdd}
      />
      <Route
        exact
        path="/banner/content-sequence"
        isAuthenticated={isAuthenticated}
        component={BannerContentSequence}
      />
      <Route
        exact
        path="/services/list"
        isAuthenticated={isAuthenticated}
        component={ProgramsList}
      />
      <Route
        exact
        path="/services/:categoryName/category"
        isAuthenticated={isAuthenticated}
        component={ProgramsCategory}
      />
      <Route
        exact
        path="/services/:categoryName/subcategory"
        isAuthenticated={isAuthenticated}
        component={ProgramsSubcategoryDetails}
      />
      <Route
        exact
        path="/services/:categoryName/subcategory/items/:type"
        isAuthenticated={isAuthenticated}
        component={ProgramsCategoryItem}
      />
      <Route
        exact
        path="/services/:categoryName/subcategory/contents/:type"
        isAuthenticated={isAuthenticated}
        component={ProgramsCategoryContent}
      />
      <Route
        exact
        path="/services/:categoryName/subcategory/:categoryType/:id/edit/item"
        isAuthenticated={isAuthenticated}
        component={ProgramsCategoryEditItems}
      />
      <Route
        exact
        path="/services/:categoryName/subcategory/:categoryType/:id/edit/content"
        isAuthenticated={isAuthenticated}
        component={ProgramsCategoryEditContent}
      />
      <Route
        exact
        path="/services/:categoryName/subcategory/:categoryType/add/content"
        isAuthenticated={isAuthenticated}
        component={ProgramsCategoryAddContent}
      />
      <Route
        exact
        path="/services/:categoryName/subcategory/:categoryType/add/item"
        isAuthenticated={isAuthenticated}
        component={ProgramsCategoryAddItems}
      />
      <Route
        exact
        path="/serviceguidelines/list"
        isAuthenticated={isAuthenticated}
        component={ServicesAndGuidelineList}
      />
      <Route
        exact
        path="/serviceguidelines/category"
        isAuthenticated={isAuthenticated}
        component={ServicesAndGuidelineSubcategoryList}
      />
      <Route
        exact
        path="/serviceguidelines/details"
        isAuthenticated={isAuthenticated}
        component={ServiceAndGuidelineDetails}
      />
      <Route
        exact
        path="/serviceguidelines/subcategory/edit"
        isAuthenticated={isAuthenticated}
        component={ServiceAndGuidelineSubcategoryEdit}
      />
      <Route
        exact
        path="/serviceguidelines/subcategory/add"
        isAuthenticated={isAuthenticated}
        component={ServiceAndGuidelineSubcategoryAdd}
      />
      <Route
        exact
        path="/survey/list"
        isAuthenticated={isAuthenticated}
        component={SurveyList}
      />
      <Route
        exact
        path="/survey/add"
        isAuthenticated={isAuthenticated}
        component={SurveyAdd}
      />
      <Route
        exact
        path="/survey/edit"
        isAuthenticated={isAuthenticated}
        component={SurveyEdit}
      />
      <Route
        exact
        path="/survey/details"
        isAuthenticated={isAuthenticated}
        component={SurveyDetails}
      />
      <Route
        exact
        path="/survey/questions/edit"
        isAuthenticated={isAuthenticated}
        component={SurveyUserQuestions}
      />
      <Route
        exact
        path="/newsletter/list"
        isAuthenticated={isAuthenticated}
        component={NewsletterList}
      />
      <Route
        exact
        path="/newsletter/edit"
        isAuthenticated={isAuthenticated}
        component={NewsletterEdit}
      />
      <Route
        exact
        path="/newsletter/add"
        isAuthenticated={isAuthenticated}
        component={NewsletterAdd}
      />
      <Route
        exact
        path="/directory/details"
        isAuthenticated={isAuthenticated}
        component={DirectoryDetails}
      />
      <Route
        exact
        path="/directory/edit"
        isAuthenticated={isAuthenticated}
        component={DirectoryEdit}
      />
      <Route
        exact
        path="/contact/details"
        isAuthenticated={isAuthenticated}
        component={ContactDetails}
      />
      <Route
        exact
        path="/contact/edit"
        isAuthenticated={isAuthenticated}
        component={ContactEdit}
      />
      <Route
        exact
        path="/events/list"
        isAuthenticated={isAuthenticated}
        component={EventsCalendarView}
      />
      <Route
        exact
        path="/events/details/:id"
        isAuthenticated={isAuthenticated}
        component={EventsDetails}
      />
      <Route
        exact
        path="/events/add"
        isAuthenticated={isAuthenticated}
        component={EventsAdd}
      />
      <Route
        exact
        path="/events/edit/:id"
        isAuthenticated={isAuthenticated}
        component={EventsEdit}
      />
      <Route
        exact
        path="/user/list"
        isAuthenticated={isAuthenticated}
        component={UserList}
      />
      <Route
        exact
        path="/user/edit"
        isAuthenticated={isAuthenticated}
        component={UserEdit}
      />
      <Route
        exact
        path="/user/add"
        isAuthenticated={isAuthenticated}
        component={UserAdd}
      />
      <Route
        exact
        path="/settings/list"
        isAuthenticated={isAuthenticated}
        component={SettingList}
      />
      <Route
        exact
        path="/settings/edit"
        isAuthenticated={isAuthenticated}
        component={SettingEdit}
      />
      <Route
        exact
        path="/aboutUs/details"
        isAuthenticated={isAuthenticated}
        component={AboutUsDetails}
      />
      <Route
        exact
        path="/aboutUs/edit"
        isAuthenticated={isAuthenticated}
        component={AboutUsEdit}
      />
      <Route
        exact
        path="/change-password"
        isAuthenticated={isAuthenticated}
        component={ChangePassword}
      />
    </Switch>
  );
}

// function PrivateRoute({ component: Component, isAuthenticated, ...rest }) {
//   return (
//     <Route
//       {...rest}
//       render={
//         props => isAuthenticated && <Component {...props} />
//       }
//     />
//   );
// }

// PrivateRoute.propTypes = {
//   component: PropTypes.func,
//   isAuthenticated: PropTypes.bool.isRequired,
//   payload: PropTypes.object,
// };

export default Routes;
